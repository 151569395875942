import axios from 'axios';
import React, { act, createContext, useCallback, useState, useEffect } from 'react';

const BetterStandsContext = createContext();

export const BetterStandsProvider = ({ children }) => {
    const [stands, setStands] = useState([]);
    const [salons, setSalons] = useState([]);
    const [uncategorizedPhotos, setUncategorizedPhotos] = useState([]);

    const [loading, setLoading] = useState(true);
    const [totalPages, setTotalPages] = useState(1);
    const [filteredPhotos, setFilteredPhotos] = useState([]);
    const [activeFairId, setActiveFairId] = useState(null);
    const [authToken, setAuthToken] = useState(localStorage.getItem("token"));
    const [fuarZamani, setFuarZamani] = useState(null);
    const [categories] = useState([
        { id: 11, key: "ana_fotograf", tr: "Ana Fotoğraf", en: "Main Photo" },
        { id: 1, key: "stant_yapisi_ve_duvarlar", tr: "Stand Yapısı ve Duvarlar", en: "Stand Structure and Walls" },
        { id: 2, key: "platform", tr: "Platform", en: "Platform" },
        { id: 3, key: "mobilya_ve_techizat", tr: "Mobilya ve Teçhizat", en: "Furniture and Equipment" },
        { id: 4, key: "aydinlatma", tr: "Aydınlatma", en: "Lighting" },
        { id: 5, key: "alinlik_ve_bas_ustu", tr: "Alınlık ve Baş Üstü", en: "Fascia and Overhead Signage" },
        { id: 7, key: "tavan", tr: "Tavan", en: "Ceiling" },
        { id: 8, key: "teshir_bilesenleri", tr: "Teşhir Bileşenleri", en: "Display facilities" },
        { id: 9, key: "zemin", tr: "Zemin", en: "Floor" },
        { id: 10, key: "grafikler_dekoratifler", tr: "Grafikler ve Dekoratifler", en: "Graphics and Decorations" },
    ]);

    const apiurl = "https://e.sakagrup.com";
    const apiport = "";

    const apiurll = "https://gw.sakagrup.com:8443/api/v1";
    const authHeader = 'Basic ' + btoa('fds_mobile:Rk3%${Vx]x');

    useEffect(() => {
        const storedToken = localStorage.getItem("token");
        if (storedToken !== authToken) {
            setAuthToken(storedToken);
        }
    }, []);


    const saveCategoryNote = async (noteData) => {
        try {
            const response = await axios.post(`${apiurl}:${apiport}/api/betterstands/save-category-note`, noteData);
            return response.data;
        } catch (error) {
            console.error("Kategori notu kaydedilirken hata oluştu:", error);
            throw error;
        }
    };

    const fetchCategoryNotes = async (standId) => {
        try {
            const response = await axios.get(`${apiurl}:${apiport}/api/betterstands/get-category-notes`, {
                params: { stand_id: standId },
            });
            console.log('Gelen yanıt:', response.data); // Gelen veriyi kontrol edin
            return response.data;
        } catch (error) {
            console.error("Kategori notları alınırken hata oluştu:", error);
            return [];
        }
    };


    const fetchActiveFairId = useCallback(async (userId) => {
        try {
            const response = await axios.get(
                `${apiurl}:${apiport}/api/betterstands/activeFair`,
                {
                    params: { userId },
                    headers: {
                        Authorization: authHeader,
                        "X-token": authToken,
                        "X-user-id": localStorage.getItem("userId"),
                    },
                }
            );

            if (response.data && response.data.activeFairId) {
                setActiveFairId(response.data.activeFairId);
                if (response.data.fairTime) {
                    setFuarZamani(response.data.fairTime === 2 ? "fuar_oncesi" : "fuar_sonrasi");
                }
            } else {
                console.error("Fuar ID bilgisi alınamadı.");
            }
        } catch (error) {
            console.error("Aktif fuar bilgisi alınırken hata oluştu:", error);
        }
    }, [authHeader, authToken]);

    const fetchSalons = useCallback(async () => {
        if (!activeFairId) {
            console.error("Aktif fuar ID'si bulunamadı. Salonlar getirilemiyor.");
            return;
        }

        try {
            const response = await axios.get(
                `${apiurll}/betterStands/salonsByFuarId/${activeFairId}`, // Aktif fuar ID'ye göre salonları getiren endpoint
                {
                    headers: {
                        Authorization: authHeader,
                        "X-token": authToken,
                        "X-user-id": localStorage.getItem("userId"),
                    },
                }
            );

            // Yanıt içindeki "salons" dizisini doğru şekilde işliyoruz
            if (response.data && response.data.salons) {
                const salonsData = response.data.salons.map((salon) => ({
                    salon_id: salon.salon_id, // API yanıtında "salon_id" olduğundan emin olun
                    salon_name: salon.salon_name, // API yanıtında "salon_name" olduğundan emin olun
                }));

                setSalons(salonsData);
                console.log("Salonlar başarıyla yüklendi:", salonsData);
            } else {
                console.error("Yanıt içinde salon bilgisi bulunamadı:", response.data);
                setSalons([]);
            }
        } catch (error) {
            console.error("Salonlar alınırken hata oluştu:", error);
        }
    }, [activeFairId, authToken, authHeader]);

    const assignPhotoToStand = async (photoId, standId, categoryId) => {
        try {
            await axios.post(`${apiurl}:${apiport}/api/betterstands/assign-photo-to-stand`, {
                photoId,
                standId,
                categoryId,
            });
            console.log("Fotoğraf başarıyla eşlendi.");
        } catch (error) {
            console.error("Fotoğraf eşlenirken hata oluştu:", error);
        }
    };

    const fetchStands = async (userId) => {
        try {
            const response = await axios.get(`${apiurl}:${apiport}/api/betterstands/uncategorized-photos`, {
                params: { userId },
            });

            if (response.data && response.data.data) {
                setUncategorizedPhotos(response.data.data); // Eşlenmemiş fotoğrafları güncelle
            } else {
                console.warn("Eşlenmemiş fotoğraf bulunamadı, boş bir liste ayarlandı.");
                setUncategorizedPhotos([]);
            }
        } catch (error) {
            console.error("Eşlenmemiş fotoğraflar alınırken hata oluştu:", error);
            setUncategorizedPhotos([]);
        }
    };

    const fetchActiveStands = useCallback(async (page = 1, limit = 10, salonFilter = null, searchQuery = '') => {
        if (!authToken) {
            console.error("authToken eksik, API çağrısı yapılmadı.");
            return;
        }

        setLoading(true);

        if (activeFairId) {
            try {
                const response = await axios.get(
                    `${apiurll}/betterStands/standsByFuarIdFull/${activeFairId}`,
                    {
                        params: { page, limit, salonId: salonFilter, search: searchQuery }, // search parametresini ekledik
                        headers: {
                            Authorization: authHeader,
                            "X-token": authToken,
                            "X-user-id": localStorage.getItem("userId"),
                        },
                    }
                );

                const standsData = response.data.fullStandInfoDTOS.content.map((stand) => ({
                    stand_id: stand.standId,
                    stand_name: stand.standNumber,
                    hall: stand.hallName,
                    name: stand.companyName,
                    type: stand.standType,
                }));

                setStands(standsData);
                setTotalPages(response.data.fullStandInfoDTOS.totalPages);
            } catch (error) {
                console.error("Stant verileri alınırken hata oluştu:", error);
            } finally {
                setLoading(false);
            }
        }
    }, [activeFairId, authToken]); // authToken'ı bağımlılık olarak ekleyin

    const saveReport = useCallback(async (reportData) => {
        try {
            const response = await axios.post(`${apiurl}:${apiport}/api/betterstands/report`, reportData);
            console.log(response.data.message);
        } catch (error) {
            console.error('Rapor kaydedilirken hata oluştu:', error);
        }
    }, []);

    const fetchReport = useCallback(async (standId) => {
        try {
            const response = await axios.get(`${apiurl}:${apiport}/api/betterstands/report`, {
                params: { stand_id: standId, fuar_id: activeFairId }
            });

            const reportData = response.data || {};
            console.log("Alınan rapor verisi:", reportData);
            return reportData;
        } catch (error) {
            console.error('Rapor alınırken hata oluştu:', error);
            return {};
        }
    }, [activeFairId]);

    const uploadPhoto = async (formData) => {
        try {
            const response = await axios.post(`${apiurl}:${apiport}/api/betterstands/upload-photo`, formData, {
                headers: { "Content-Type": "multipart/form-data" },
            });
            console.log("Fotoğraf Yükleme Yanıtı:", response.data); // Yanıtın doğru olup olmadığını kontrol edin
            return response;
        } catch (error) {
            console.error("Fotoğraf yüklenirken hata oluştu:", error);
            throw new Error("Fotoğraf yüklenirken bir hata oluştu.");
        }
    };

    const fetchUncategorizedPhotos = useCallback(async (userId) => {
        try {
            const response = await axios.get(`${apiurl}:${apiport}/api/betterstands/uncategorized-photos`, {
                params: { userId }
            });

            if (response.data && response.data.data) {
                setUncategorizedPhotos(response.data.data);
                setFilteredPhotos(response.data.data); // Filtreleme için başlangıç değeri
            }
        } catch (error) {
            console.error("Eşlenmemiş görseller alınırken hata oluştu:", error);
            setUncategorizedPhotos([]);
        }
    }, []);

    const fetchAllUncategorizedPhotos = useCallback(async () => {
        try {
            const response = await axios.get(`${apiurl}:${apiport}/api/betterstands/all-uncategorized-photos`);
            if (response.data && response.data.data) {
                setUncategorizedPhotos(response.data.data);
                setFilteredPhotos(response.data.data);
            }
        } catch (error) {
            console.error("Tüm eşlenmemiş görseller alınırken hata oluştu:", error);
            setUncategorizedPhotos([]);
        }
    }, []);


    const uploadAreaPhoto = async (formData) => {
        try {
            const response = await axios.post(`${apiurl}:${apiport}/api/betterstands/upload-areaphoto`, formData, {
                headers: { "Content-Type": "multipart/form-data" },
            });
            console.log("Fotoğraf Yükleme Yanıtı:", response.data); // Yanıtın doğru olup olmadığını kontrol edin
            return response;
        } catch (error) {
            console.error("Fotoğraf yüklenirken hata oluştu:", error);
            throw new Error("Fotoğraf yüklenirken bir hata oluştu.");
        }
    };

    const fetchCategoryPhotos = async (standId) => {
        try {
            const response = await axios.get(`${apiurl}:${apiport}/api/betterstands/photos-by-stand`, {
                params: { stand_id: standId },
            });

            return response.data; // Fotoğraf listesi
        } catch (error) {
            console.error("Stant fotoğrafları alınırken hata oluştu:", error);
            return [];
        }
    };

    const updatePhotoCategory = async (photoId, categoryId) => {
        try {
            await axios.post(`${apiurl}:${apiport}/api/betterstands/update-photo-category`, {
                photoId,
                categoryId,
            });
        } catch (error) {
            console.error("Fotoğraf kategorisi güncellenirken hata oluştu:", error);
        }
    };

    const handleRemoveCategory = async (photoId) => {
        try {
            await axios.post(`${apiurl}:${apiport}/api/betterstands/remove-photo-category`, {
                photoId,
            });

        } catch (error) {
            console.error("Fotoğraf kaldırılırken hata oluştu:", error);
            alert("Fotoğraf kaldırılırken hata oluştu.");
        }
    };

    const handleRemovePhotoo = async (photoId, isUncategorized = false) => {
        try {
            // Eğer görsel eşlenmemişse (uncategorized), stand_id null yapılır
            if (isUncategorized) {
                await axios.post(`${apiurl}:${apiport}/api/betterstands/remove-photo-stand`, { photoId });
            } else {
                // Kategorideyse, sadece kategoriden kaldırılır
                await axios.post(`${apiurl}:${apiport}/api/betterstands/remove-photo-category`, { photoId });
            }
        } catch (error) {
            console.error("Fotoğraf kaldırılırken hata oluştu:", error);
            alert("Fotoğraf kaldırılırken hata oluştu.");
        }
    };

    const downloadPhotos = async (stands) => {
        try {
            const response = await axios.post(`${apiurl}:${apiport}/api/betterstands/download-photos`, { stands }, {
                responseType: "blob", // Zip dosyasını almak için
            });

            const blob = new Blob([response.data], { type: "application/zip" });
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.download = "stand_photos.zip";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error("Görseller indirilirken hata oluştu:", error);
            alert("Görseller indirilirken bir hata oluştu.");
        }
    };


    return (
        <BetterStandsContext.Provider value={{
            stands,
            salons,
            activeFairId,
            fetchActiveStands,
            fetchAllUncategorizedPhotos,
            fetchSalons,
            assignPhotoToStand,
            saveReport,
            fetchReport,
            uploadPhoto,
            fetchUncategorizedPhotos,
            fetchAllUncategorizedPhotos,
            filteredPhotos,
            loading,
            fetchStands,
            totalPages,
            fetchCategoryPhotos,
            updatePhotoCategory,
            handleRemoveCategory,
            handleRemovePhotoo,
            saveCategoryNote,
            uploadAreaPhoto,
            uncategorizedPhotos,
            fetchCategoryNotes,
            downloadPhotos,
            fetchActiveFairId,
            fuarZamani,
            setFuarZamani
        }}>
            {children}
        </BetterStandsContext.Provider>
    );
};

export default BetterStandsContext;
