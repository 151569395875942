import React, { useContext, useEffect, useState } from "react";
import { Container, Table, Button, Badge, Row, Col, Input, Modal, ModalHeader, ModalBody, ModalFooter, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Pagination, PaginationItem, PaginationLink, Card } from "reactstrap";
import BreadCrumb from "../../../../../Components/Common/BreadCrumb";
import FileTypeContext from "../../../../../Components/Contexts/Management/FileTypeContext";
import Select from 'react-select';

const FileTypes = () => {
    document.title = "Dosya Türleri | FDS";

    const { getFileTypes, addFileType, updateFileType, deleteFileType } = useContext(FileTypeContext);
    const [fileTypes, setFileTypes] = useState([]);
    const [formattedOptions, setFormattedOptions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [editingId, setEditingId] = useState(null);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [search, setSearch] = useState("");
    const [modalOpen, setModalOpen] = useState(false);
    const [newFileType, setNewFileType] = useState({ nameTR: '', nameEN: '', parentId: 0 });
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deleteId, setDeleteId] = useState(null);

    const toggleDropdown = () => setDropdownOpen(prevState => !prevState);

    const getFullCategoryPath = (categories, fileType) => {
        let path = fileType.file_type_name;
        let current = fileType;
        while (current.parent_id !== 0) {
            const parent = categories.find(cat => cat.id === current.parent_id);
            if (parent) {
                path = `${parent.file_type_name} -> ${path}`;
                current = parent;
            } else {
                break;
            }
        }
        return path;
    };

    const getFullCategoryPathForTable = (categories, fileType) => {
        let path = '';
        let current = fileType;

        if (current.parent_id === 0) {
            return "Ana Kategori";
        }

        while (current.parent_id !== 0) {
            const parent = categories.find(cat => cat.id === current.parent_id);
            if (parent) {
                path = path ? `${parent.file_type_name} -> ${path}` : parent.file_type_name;
                current = parent;
            } else {
                break;
            }
        }

        return path;
    };

    const fetchAllCategories = async () => {
        try {
            setLoading(true);
            const data = await getFileTypes(1, 0, search);
            console.log("Fetched All Categories:", data.data);

            // Kategorileri hiyerarşik olarak formatla
            const formattedCategories = data.data.map(fileType => ({
                value: fileType.id,
                label: getFullCategoryPath(data.data, fileType)
            }));

            formattedCategories.unshift({ value: 0, label: "ANA KATEGORİ" });

            setFormattedOptions(formattedCategories);
        } catch (error) {
            console.error("Error fetching all categories:", error);
        } finally {
            setLoading(false);
        }
    };

    const fetchData = async (page = 1, limit = 10, search = '') => {
        try {
            setLoading(true);
            const data = await getFileTypes(page, limit, search);
            console.log("Fetched Data:", data.data);

            setFileTypes(data.data.map(fileType => ({
                ...fileType,
                categoryPath: getFullCategoryPathForTable(data.data, fileType)
            })));

            setTotalPages(data.totalPages);
        } catch (error) {
            console.error("Error fetching file types:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData(page, limit, search);
    }, [getFileTypes, page, limit, search]);

    const toggleModal = async () => {
        if (!modalOpen) {
            await fetchAllCategories(); 
        } else {
            setEditingId(null);
            setNewFileType({ nameTR: '', nameEN: '', parentId: 0 });
        }
        setModalOpen(!modalOpen);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewFileType(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSelectChange = (selectedOption) => {
        setNewFileType(prevState => ({
            ...prevState,
            parentId: selectedOption ? selectedOption.value : 0,
        }));
    };

    const handleAddFileType = async () => {
        try {
            await addFileType(newFileType);
            setModalOpen(false);
            setPage(1);
        } catch (error) {
            console.error("Error adding file type:", error);
        }
    };

    const handleEdit = (fileType) => {
        const selectedOption = formattedOptions.find(option => option.value === fileType.parent_id) || { value: 0, label: "ANA KATEGORİ" };

        setNewFileType({
            nameTR: fileType.nameTR,
            nameEN: fileType.nameEN, 
            parentId: fileType.parent_id
        });

        handleSelectChange(selectedOption);

        setEditingId(fileType.id);
        setModalOpen(true);
    };

    const handleSave = async () => {
        if (editingId) {
            try {
                await updateFileType(editingId, newFileType); 
                setModalOpen(false);
                setEditingId(null);
                setPage(1);
                await fetchData();
            } catch (error) {
                console.error("Error updating file type:", error);
            }
        } else {
            await handleAddFileType();
        }
    };

    const handleDelete = (id) => {
        setDeleteId(id);
        setDeleteModalOpen(true);
    };

    const confirmDelete = async () => {
        if (deleteId) {
            try {
                await deleteFileType(deleteId);
                setDeleteModalOpen(false);
                setPage(1);
                await fetchData();
            } catch (error) {
                console.error("Error deleting file type:", error);
            }
        }
    };

    const renderPagination = () => {
        const paginationItems = [];
        const maxPagesToShow = 5;

        for (let i = 1; i <= totalPages; i++) {
            if (totalPages > maxPagesToShow) {
                if (i === 1 || i === totalPages || (i >= page - 1 && i <= page + 1)) {
                    paginationItems.push(
                        <PaginationItem key={i} active={i === page}>
                            <PaginationLink onClick={() => setPage(i)}>{i}</PaginationLink>
                        </PaginationItem>
                    );
                } else if (i === 2 || i === totalPages - 1) {
                    paginationItems.push(
                        <PaginationItem key={i} disabled>
                            <PaginationLink>...</PaginationLink>
                        </PaginationItem>
                    );
                }
            } else {
                paginationItems.push(
                    <PaginationItem key={i} active={i === page}>
                        <PaginationLink onClick={() => setPage(i)}>{i}</PaginationLink>
                    </PaginationItem>
                );
            }
        }
        return paginationItems;
    };

    return (
        <div className="page-content">
            <Container fluid>
                <BreadCrumb title="Dosya Türleri" pageTitle="FDS Yapılandırması" />
                <Card className="p-3">
                    <Row>
                        <Col md="1">
                            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                                <DropdownToggle caret>
                                    Gösterim: {limit}
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={() => setLimit(10)}>10</DropdownItem>
                                    <DropdownItem onClick={() => setLimit(25)}>25</DropdownItem>
                                    <DropdownItem onClick={() => setLimit(50)}>50</DropdownItem>
                                    <DropdownItem onClick={() => setLimit(100)}>100</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </Col>
                        <Col md="3">
                            <Input
                                type="text"
                                placeholder="Arama..."
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </Col>
                        <Col md="8" className="text-end">
                            <Button color="secondary" onClick={toggleModal}>Yeni Ekle</Button>
                        </Col>
                    </Row>
                </Card>
                {loading ? (
                    <div>Yükleniyor...</div>
                ) : (
                    <>
                        <Card className="pt-3 pe-3 ps-3">
                            <Table responsive bordered striped>
                                <thead>
                                    <tr>
                                        <th>Tür</th>
                                        <th>Kategori</th>
                                        <th>Dil Durumu</th>
                                        <th>Eylem</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {fileTypes.map((fileType, index) => (
                                        <tr key={fileType.id}>
                                            <td>{fileType.file_type_name}</td>
                                            <td>
                                                <Badge color="primary">
                                                    {fileType.categoryPath ? fileType.categoryPath : "ANA KATEGORİ"}
                                                </Badge>
                                            </td>
                                            <td>
                                                <Badge color={fileType.status === "Tamamlanmış" ? "secondary" : "warning"}>
                                                    {fileType.status}
                                                </Badge>
                                            </td>
                                            <td>
                                                <Button color="warning" className="me-2" onClick={() => handleEdit(fileType)}>Düzenle</Button>
                                                <Button color="primary" onClick={() => handleDelete(fileType.id)}>Sil</Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Card>
                        <Pagination className="pagination justify-content-center">
                            {renderPagination()}
                        </Pagination>
                    </>
                )}

                <Modal isOpen={modalOpen} toggle={toggleModal}>
                    <ModalHeader toggle={toggleModal}>{editingId ? "Dosya Türünü Düzenle" : "Yeni Dosya Türü Ekle"}</ModalHeader>
                    <ModalBody>
                        <Row className="mb-3">
                            <Col md="12">
                                <Select
                                    options={formattedOptions}
                                    onChange={handleSelectChange}
                                    isClearable
                                    placeholder="Kategori Seç..."
                                    value={formattedOptions.find(option => option.value === newFileType.parentId) || null}
                                />
                                {newFileType.parentId === null && (
                                    <div className="text-primary mt-2">Lütfen bir kategori seçin.</div>
                                )}
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md="12">
                                <Input
                                    type="text"
                                    name="nameTR"
                                    placeholder="Türkçe"
                                    value={newFileType.nameTR}
                                    onChange={handleInputChange}
                                />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md="12">
                                <Input
                                    type="text"
                                    name="nameEN"
                                    placeholder="İngilizce"
                                    value={newFileType.nameEN}
                                    onChange={handleInputChange}
                                />
                            </Col>
                        </Row>
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            color="primary"
                            onClick={editingId ? handleSave : handleAddFileType}
                            disabled={newFileType.parentId === null}
                        >
                            {editingId ? "Güncelle" : "Ekle"}
                        </Button>
                        <Button color="secondary" onClick={toggleModal}>İptal</Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={deleteModalOpen} toggle={() => setDeleteModalOpen(false)}>
                    <ModalHeader toggle={() => setDeleteModalOpen(false)}>Dosya Türünü Sil</ModalHeader>
                    <ModalBody>Bu dosya türünü silmek istediğinizden emin misiniz?</ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={confirmDelete}>Sil</Button>
                        <Button color="secondary" onClick={() => setDeleteModalOpen(false)}>İptal</Button>
                    </ModalFooter>
                </Modal>
            </Container>
        </div>
    );
};

export default FileTypes;
