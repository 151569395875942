import React, { createContext, useCallback,useEffect, useState } from 'react';
import axios from 'axios';

const MainContext = createContext();

export const MainProvider = ({ children }) => {
    const [activeFairId, setActiveFairId] = useState(null);
    const [fuarZamani, setFuarZamani] = useState(null);
    const apiurl = "gw.sakagrup.com";
    const apiport = "8443";

    const api = {
        Login: `https://${apiurl}:${apiport}/api/v1/login/request`,
        User: `https://${apiurl}:${apiport}/api/v1/users/`,
        Group: `https://${apiurl}:${apiport}/api/v1/users/user/`,
        groups: {
            1: {
                visibleItems: ['Dashboard', 'Moduller', 'Users', 'Fairs'],
                roles: [1],
            },
            2: {
                visibleItems: ['Dashboard', 'Moduller', 'Users', 'Fairs'],
                roles: [2],
            },
            3: {
                visibleItems: ['Dashboard', 'Moduller', 'Users', 'Fairs'],
                roles: [3],
            },
            4: {
                visibleItems: ['Dashboard', 'Moduller', 'Users', 'Fairs'],
                roles: [4],
            },
            5: {
                visibleItems: ['Dashboard', 'Moduller', 'Users', 'Fairs'],
                roles: [5],
            },
            6: {
                visibleItems: ['Dashboard', 'Moduller', 'Users', 'Fairs'],
                roles: [6],
            },
            7: {
                visibleItems: ['Dashboard', 'Moduller', 'Users', 'Fairs'],
                roles: [7],
            },
            8: {
                visibleItems: ['Dashboard', 'Moduller', 'Users', 'Fairs'],
                roles: [8],
            },
            9: {
                visibleItems: ['Dashboard', 'Moduller', 'Users', 'Fairs'],
                roles: [9],
            },
            10: {
                visibleItems: ['Dashboard', 'Moduller', 'Users', 'Fairs'],
                roles: [10],
            },
            11: {
                visibleItems: ['Dashboard', 'Moduller', 'Users', 'Fairs'],
                roles: [11],
            },
            12: {
                visibleItems: ['Dashboard', 'Moduller', 'Users', 'Fairs'],
                roles: [12],
            },
            13: {
                visibleItems: ['Dashboard', 'Moduller', 'Users', 'Fairs'],
                roles: [13],
            },
            14: {
                visibleItems: ['Dashboard', 'Moduller', 'Users', 'Fairs'],
                roles: [14],
            },
            15: {
                visibleItems: ['Dashboard', 'Moduller', 'Users', 'Fairs'],
                roles: [15],
            },
            16: {
                visibleItems: ['Dashboard', 'Moduller', 'Users', 'Fairs'],
                roles: [16],
            },
            17: {
                visibleItems: ['Dashboard', 'Moduller', 'Users', 'Fairs'],
                roles: [17],
            },
            18: {
                visibleItems: ['Dashboard', 'Moduller', 'Users', 'Fairs'],
                roles: [18],
            },
            19: {
                visibleItems: ['Dashboard', 'Moduller', 'Users', 'Fairs'],
                roles: [19],
            },
            default: {
                visibleItems: ['Dashboard'],
                roles: []
            }
        }        
    };


    const fetchActiveFairId = useCallback(async (userId) => {
        const authHeader = 'Basic ' + btoa('fds_mobile:Rk3%${Vx]x');
        const token = localStorage.getItem('token');

        if (!token) {
            console.error("Kullanıcı oturumu bulunamadı.");
            return;
        }

        try {
            const response = await axios.get(
                `https://e.sakagrup.com/api/betterstands/activeFair`,
                {
                    params: { userId },
                    headers: {
                        Authorization: authHeader,
                        "X-token": token,
                        "X-user-id": userId,
                    },
                }
            );

            if (response.data && response.data.activeFairId) {
                setActiveFairId(response.data.activeFairId);
                if (response.data.fairTime) {
                    setFuarZamani(response.data.fairTime === 2 ? "fuar_oncesi" : "fuar_sonrasi");
                }
                console.log("Aktif Fuar ID'si güncellendi:", response.data.activeFairId);
            } else {
                console.error("Fuar ID bilgisi alınamadı.");
            }
        } catch (error) {
            console.error("Aktif fuar bilgisi alınırken hata oluştu:", error);
        }
    }, [apiurl, apiport]);

    useEffect(() => {
        const userId = localStorage.getItem('userId');
        if (userId) {
            fetchActiveFairId(userId);
        }
    }, [fetchActiveFairId]);

    const handleLoginContext = useCallback(async (username, password) => {
        const user = 'fds_mobile';
        const pass = 'Rk3%${Vx]x';
        const authHeader = 'Basic ' + btoa(user + ':' + pass);
    
        try {
            const formData = new FormData();
            formData.append('user_login_name', username);
            formData.append('user_login_password', password);
    
            const response = await axios.post(
                api.Login,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': authHeader
                    }
                }
            );
    
            if (response.data.result) {
                localStorage.setItem('userId', response.data.user_id);
                localStorage.setItem('token', response.data.token);
    
                const userId = response.data.user_id;
    
                const userResponse = await axios.get(
                    `${api.User}${userId}`,
                    {
                        headers: {
                            'Authorization': authHeader
                        }
                    }
                );
                localStorage.setItem('user', JSON.stringify(userResponse.data));
    
                const groupResponse = await axios.get(
                    `${api.Group}${userId}/group`,
                    {
                        headers: {
                            'Authorization': authHeader
                        }
                    }
                );
                localStorage.setItem('groupInfo', JSON.stringify(groupResponse.data));
    
                // Sayfayı yenile
                return true;
            } else {
                throw new Error('Login failed');
            }
        } catch (error) {
            console.error('Login error:', error);
            throw error;
        }
    }, [api.Login, api.User, api.Group]);
    

    const getUserDataContext = useCallback(async () => {
        const userId = localStorage.getItem('userId');
        const token = localStorage.getItem('token');
        const authHeader = 'Basic ' + btoa('fds_mobile:Rk3%${Vx]x');

        if (userId && token) {
            try {
                const userResponse = await axios.get(`${api.User}${userId}`, {
                    headers: {
                        Authorization: authHeader,
                    },
                });
                const groupResponse = await axios.get(`${api.Group}${userId}/group`, {
                    headers: {
                        Authorization: authHeader,
                    },
                });
                return { userInfo: userResponse.data, groupInfo: groupResponse.data };
            } catch (error) {
                console.error('Error fetching user info:', error);
                throw error;
            }
        } else {
            throw new Error('User is not logged in');
        }
    }, [api.User, api.Group]);

    const getUserGroupContext = useCallback(async (userId) => {
        const authHeader = 'Basic ' + btoa('fds_mobile:Rk3%${Vx]x');
        try {
            const response = await axios.get(`${api.Group}${userId}/group`, {
                headers: {
                    Authorization: authHeader
                }
            });
            return response.data;
        } catch (error) {
            console.error('Error fetching user group:', error);
            throw error;
        }
    }, [api.Group]);

    return (
        <MainContext.Provider value={{ handleLoginContext, getUserDataContext, getUserGroupContext, api,activeFairId,fuarZamani,fetchActiveFairId, }}>
            {children}
        </MainContext.Provider>
    );
};

export default MainContext;
