import React, { useState, useEffect, useContext } from 'react';
import { Navigate, useLocation } from "react-router-dom";
import MainContext from '../Components/Contexts/MainContext';
import { authProtectedRoutes } from './allRoutes';
import axios from 'axios';

const AuthProtected = ({ children }) => {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const token = localStorage.getItem("token");

  const { fetchGroupInfo } = useContext(MainContext);

  const apiurll = "https://gw.sakagrup.com:8443/api/v1";
  const authHeader = 'Basic ' + btoa('fds_mobile:Rk3%${Vx]x');

  const fetchSalons = async () => {
    try {
      const response = await axios.get(
        `${apiurll}/betterStands/salonsByFuarId/269`,
        {
          headers: {
            Authorization: authHeader,
            "X-token": token,
            "X-user-id": localStorage.getItem("userId"),
          },
        }
      );

      if (response.data && response.data.salons) {
        return true; // Token geçerli
      } else {
        console.error("Salon bilgisi alınamadı:", response.data);
        return false; // Token geçersiz
      }
    } catch (error) {
      console.error("Salonlar alınırken hata oluştu veya token geçersiz:", error);
      return false; // Token geçersiz
    }
  };

  useEffect(() => {
    const checkAuthorization = async () => {
      try {
        const user = JSON.parse(localStorage.getItem('user'));
        let groupInfo = JSON.parse(localStorage.getItem('groupInfo'));

        if (user && user.id && token) {
          if (!groupInfo) {
            groupInfo = await fetchGroupInfo(user.id);
            localStorage.setItem('groupInfo', JSON.stringify(groupInfo));
          }

          const tokenValid = await fetchSalons();
          if (!tokenValid) {
            setIsAuthorized(false);
          } else {
            const currentRoute = authProtectedRoutes.find(route => route.path.toLowerCase() === location.pathname.toLowerCase());
            if (currentRoute && currentRoute.roles) {
              const userGroupId = groupInfo.id;
              setIsAuthorized(currentRoute.roles.includes(userGroupId));
            } else {
              setIsAuthorized(true);
            }
          }
        }
      } catch (error) {
        console.error("Authorization error:", error);
      } finally {
        setLoading(false);
      }
    };

    checkAuthorization();
  }, [token, location.pathname, fetchGroupInfo]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return isAuthorized ? children : <Navigate to="/login" />;
};

export { AuthProtected };
