import React, { createContext, useCallback } from 'react';
import axios from 'axios';

const FairContext = createContext();

export const FairProvider = ({ children }) => {
    const apiurl = "https://e.sakagrup.com";
    const apiport = "";

    const api = {
        fairs: `${apiurl}:${apiport}/api/fairs`,
        activateFair: `${apiurl}:${apiport}/api/fairs/activate`,
    };

    const getFairs = useCallback(async (page = 1, limit = 10, search = '', userId) => {
        try {
            const response = await axios.get(api.fairs, {
                params: { page, limit, search, userId },
            });
            return response.data;
        } catch (error) {
            console.error('Error fetching fairs:', error);
            throw error;
        }
    }, [api.fairs]);

    const addFair = useCallback(async (newFair) => {
        try {
            const response = await axios.post(api.fairs, newFair);
            return response.data;
        } catch (error) {
            console.error('Error adding fair:', error);
            throw error;
        }
    }, [api.fairs]);

    const updateFair = useCallback(async (id, updatedFair) => {
        try {
            const response = await axios.put(`${api.fairs}/${id}`, updatedFair);
            return response.data;
        } catch (error) {
            console.error('Error updating fair:', error);
            throw error;
        }
    }, [api.fairs]);

    const deleteFair = useCallback(async (id) => {
        try {
            await axios.delete(`${api.fairs}/${id}`);
        } catch (error) {
            console.error('Error deleting fair:', error);
            throw error;
        }
    }, [api.fairs]);

    const activateFair = useCallback(async (userId, fairId) => {
        try {
            await axios.post(api.activateFair, { userId, fairId });
        } catch (error) {
            console.error('Error activating fair:', error);
            throw error;
        }
    }, [api.activateFair]);

    return (
        <FairContext.Provider value={{ getFairs, addFair, updateFair, deleteFair, activateFair }}>
            {children}
        </FairContext.Provider>
    );
};

export default FairContext;
