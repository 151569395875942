import Webcam from "react-webcam";
import YokImg from "../../../../../assets/images/Yok.png";
import EvetImg from "../../../../../assets/images/Evet.png";
import HayirImg from "../../../../../assets/images/Hayır.png";
import React, { useContext, useEffect, useState, useRef } from "react";
import BelirtilmediImg from "../../../../../assets/images/Belirtilmedi.png";
import { LanguageContext } from "../../../../../Components/Contexts/LanguageContext";
import BetterStandsContext from "../../../../../Components/Contexts/Management/BetterStandsContext";
import { Container, Table, Pagination, PaginationItem, PaginationLink, Input, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Row, Col, Card, Button, Form, FormGroup, Label, Modal, ModalBody, ModalHeader } from "reactstrap"

const Area = () => {
    document.title = "BTS Saha | FDS";
    const categories = [
        { id: 11, key: "ana_fotograf", tr: "Ana Fotoğraf", en: "Main Photo" },
        { id: 1, key: "stant_yapisi_ve_duvarlar", tr: "Stand Yapısı ve Duvarlar", en: "Stand Structure and Walls" },
        { id: 2, key: "platform", tr: "Platform", en: "Platform" },
        { id: 3, key: "mobilya_ve_techizat", tr: "Mobilya ve Teçhizat", en: "Furniture and Equipment" },
        { id: 4, key: "aydinlatma", tr: "Aydınlatma", en: "Lighting" },
        { id: 5, key: "alinlik_ve_bas_ustu", tr: "Alınlık ve Baş Üstü", en: "Fascia and Overhead Signage" },
        { id: 7, key: "tavan", tr: "Tavan", en: "Ceiling" },
        { id: 8, key: "teshir_bilesenleri", tr: "Teşhir Bileşenleri", en: "Display facilities" },
        { id: 9, key: "zemin", tr: "Zemin", en: "Floor" },
        { id: 10, key: "grafikler_dekoratifler", tr: "Grafikler ve Dekoratifler", en: "Graphics and Decorations" },
    ];

    const categoriess = [
        { id: 11, key: "ana_fotograf", tr: "Ana Fotoğraf", en: "Main Photo" },
        { id: 1, key: "stant_yapisi_ve_duvarlar", tr: "Stand Yapısı", en: "Stand Structure" },
        { id: 2, key: "platform", tr: "Platform", en: "Platform" },
        { id: 3, key: "mobilya_ve_techizat", tr: "Mobilya", en: "Furniture" },
        { id: 4, key: "aydinlatma", tr: "Aydınlatma", en: "Lighting" },
        { id: 5, key: "alinlik_ve_bas_ustu", tr: "Alınlık", en: "Overhead" },
        { id: 7, key: "tavan", tr: "Tavan", en: "Ceiling" },
        { id: 8, key: "teshir_bilesenleri", tr: "Teşhir", en: "Display" },
        { id: 9, key: "zemin", tr: "Zemin", en: "Floor" },
        { id: 10, key: "grafikler_dekoratifler", tr: "Grafikler", en: "Graphics" },
    ];

    const webcamRef = useRef(null);
    const [page, setPage] = useState(1);
    const containerRef = useRef(null);
    const [zoom, setZoom] = useState(1);
    const [limit, setLimit] = useState(10);
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    const [photo, setPhoto] = useState(null);
    const [search, setSearch] = useState('');
    const [rotation, setRotation] = useState(0);
    const userId = localStorage.getItem('userId');
    const [showIcons, setShowIcons] = useState({});
    const { selectedLang } = useContext(LanguageContext);
    const [photoToEdit, setPhotoToEdit] = useState(null);
    const [salonFilter, setSalonFilter] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [isPhotoTaken, setIsPhotoTaken] = useState(false);
    const [selectedStand, setSelectedStand] = useState(null);
    const [categoryPhotos, setCategoryPhotos] = useState({});
    const [categoryNotes, setCategoryNotes] = useState({});

    const [showZoomButtons, setShowZoomButtons] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedPhotoUrl, setSelectedPhotoUrl] = useState(null);
    const [sortConfig, setSortConfig] = useState({ key: "stand_name", direction: "asc" });
    const [salonDropdownOpen, setSalonDropdownOpen] = useState(false);
    const [standsWithPhotoCounts, setStandsWithPhotoCounts] = useState([]);
    const [authToken, setAuthToken] = useState(() => localStorage.getItem("token"));
    const [reportData, setReportData] = useState(categories.reduce((acc, category) => ({ ...acc, [category.key]: '' }), {}));
    const [openSelections, setOpenSelections] = useState(categories.reduce((acc, category) => ({ ...acc, [category.key]: false }), {}));
    const [areCategoryButtonsVisible, setAreCategoryButtonsVisible] = useState(JSON.parse(localStorage.getItem("areCategoryButtonsVisible")) ?? true);
    const { handleRemovePhotoo, fetchCategoryNotes, saveCategoryNote, stands, fuarZamani, salons, fetchActiveFairId, fetchActiveStands, fetchSalons, fetchReport, saveReport, loading, totalPages, activeFairId, uploadAreaPhoto, fetchCategoryPhotos, updatePhotoCategory } = useContext(BetterStandsContext);

    const videoConstraints = {
        width: { ideal: 1920 },
        height: { ideal: 1080 },
        facingMode: "environment",
        zoom: zoom
    };

    const apiurl = "https://e.sakagrup.com";
    const apiport = "";
    const baseApiUrl = `${apiurl}:${apiport}`;

    const toggleModal = () => setIsModalOpen(!isModalOpen);
    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
    const toggleSalonDropdown = () => setSalonDropdownOpen(!salonDropdownOpen);
    const handlePageChange = (newPage) => setPage(newPage);

    const handleSort = (key) => {
        setSortConfig((prevState) => ({
            key,
            direction: prevState.key === key && prevState.direction === "asc" ? "desc" : "asc",
        }));
    };

    const sortedStands = [...standsWithPhotoCounts].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
    });

    const renderSortIcon = (key) => {
        if (sortConfig.key !== key) return null;
        return sortConfig.direction === "asc" ? "▲" : "▼"; // Basit bir ikon (değiştirilebilir)
    };

    useEffect(() => {
        if (selectedStand) {
            // Seçilen stant için kategori notlarını çek
            fetchCategoryNotes(selectedStand.stand_id).then((notes) => {
                const notesMap = notes.reduce((acc, note) => {
                    acc[note.category_id] = note.note_text; // Her kategoriye ait notu map'e ekle
                    return acc;
                }, {});
                setCategoryNotes(notesMap); // Notları state'e kaydet
            });
        } else {
            // Eğer stant seçimi kaldırılırsa notları temizle
            setCategoryNotes({});
        }
    }, [selectedStand, fetchCategoryNotes]);


    useEffect(() => {
        const shouldReload = localStorage.getItem("shouldReload");

        if (!shouldReload) {
            localStorage.setItem("shouldReload", "true");
            const userId = localStorage.getItem("userId");
            if (userId) {
                fetchActiveFairId(userId);
                fetchActiveStands();
                fetchSalons();
            }
            window.location.reload();
        } else {
            localStorage.removeItem("shouldReload");
        }
    }, []);

    useEffect(() => {
        if (authToken) {
            console.log("authToken yüklendi, gerekli veriler çekiliyor...");
            const userId = localStorage.getItem("userId");
            if (userId) {
                fetchActiveFairId(userId);
                fetchActiveStands();
                fetchSalons();
            }
        } else {
            console.warn("authToken mevcut değil, API çağrıları yapılmadı.");
        }
    }, [authToken]);


    useEffect(() => {
        const storedToken = localStorage.getItem("token");
        if (storedToken !== authToken) {
            setAuthToken(storedToken);
        }
    }, []);

    useEffect(() => {
        if (userId) {
            fetchActiveFairId(userId).then(() => {
                // Eğer activeFairId ile beraber fuar_zamani bilgisi alınırsa, state otomatik güncellenir
                if (activeFairId && fuarZamani) {
                    console.log("Fuar Zamanı Güncellendi:", fuarZamani);
                }
            });
        }
    }, [userId, fetchActiveFairId, activeFairId, fuarZamani]);

    useEffect(() => {
        if (userId && activeFairId) {
            fetchSalons(); // fetchSalons sadece activeFairId mevcutsa çağrılır
        }
    }, [userId, activeFairId, fetchSalons]);


    // Input değişiminde fetchActiveStands'i tetikleyen useEffect
    useEffect(() => {
        if (userId) {
            fetchActiveStands(page - 1, limit, salonFilter, search); // search parametresini ekledik
        }
    }, [userId, page, limit, salonFilter, search, fetchActiveStands]);



    useEffect(() => {
        localStorage.setItem("areCategoryButtonsVisible", JSON.stringify(areCategoryButtonsVisible));
    }, [areCategoryButtonsVisible]);

    useEffect(() => {
        if (selectedCategory !== null) {
            fetchPhotosForCategory(selectedCategory);
        }
    }, [selectedCategory, selectedStand]);

    useEffect(() => {
        if (stands.length > 0) {
            fetchStandsPhotoCounts();
        }
    }, [stands]);

    const fetchStandsPhotoCounts = async () => {
        try {
            const updatedStands = await Promise.all(
                stands.map(async (stand) => {
                    // Fotoğrafları kategorilere göre al
                    const photos = await fetchCategoryPhotos(stand.stand_id);

                    // Fotoğrafları kategorilere ayır ve say
                    const categoryPhotoCounts = categories.reduce((acc, category) => {
                        acc[category.key] = {
                            beforeFair: photos.filter(
                                (photo) => photo.stand_report_file_category === String(category.id) && photo.fuar_zamani === "fuar_oncesi"
                            ).length,
                            afterFair: photos.filter(
                                (photo) => photo.stand_report_file_category === String(category.id) && photo.fuar_zamani === "fuar_sonrasi"
                            ).length,
                        };
                        return acc;
                    }, {});

                    // Stant için rapor verilerini çek
                    const reportData = await fetchReport(stand.stand_id);

                    return {
                        ...stand,
                        photoCounts: categoryPhotoCounts, // Kategorilere göre görsel sayısı
                        reportData: reportData || {}, // Rapor verileri
                    };
                })
            );

            // Güncellenmiş stantları state'e aktar
            setStandsWithPhotoCounts(updatedStands);
            console.log("Stant görselleri ve rapor bilgileri yüklendi:", updatedStands);
        } catch (error) {
            console.error("Stant görselleri veya rapor bilgileri alınırken hata oluştu:", error);
        }
    };


    const handleAssignPhotoToCategory = async (photo, categoryId) => {
        try {
            await updatePhotoCategory(photo.stand_report_file_id, categoryId);

            // Görseli yeni kategoriye taşı
            const categoryKey = categories.find((cat) => cat.id === categoryId).key;

            setCategoryPhotos((prev) => {
                const updatedUncategorized = prev["diğer"].filter(
                    (p) => p.stand_report_file_id !== photo.stand_report_file_id
                );

                return {
                    ...prev,
                    [categoryKey]: [...(prev[categoryKey] || []), photo],
                    diğer: updatedUncategorized,
                };
            });

        } catch (error) {
            console.error("Fotoğraf kategoriye atanırken hata oluştu:", error);
            alert("Fotoğraf kategoriye atanırken hata oluştu.");
        }
    };


    const handleSelectIcon = (category) => {
        setOpenSelections((prevState) => ({
            ...prevState,
            [category]: !prevState[category], // İlgili kategoriyi aç/kapat
        }));
    };

    const handlePhotoModal = (photo) => {
        const photoLink = photo.stand_report_file_link.startsWith("http")
            ? photo.stand_report_file_link
            : `${baseApiUrl}${photo.stand_report_file_link}`;

        setSelectedPhotoUrl(photoLink);
        setIsModalOpen(true);
    };

    const handleSelectStand = async (stand) => {
        try {
            setSelectedStand(stand);

            // API'den fotoğrafları al
            const photos = await fetchCategoryPhotos(stand.stand_id);

            const categorizedPhotos = photos.reduce((acc, photo) => {
                const category = categories.find((cat) => String(cat.id) === photo.stand_report_file_category);

                if (category) {
                    acc[category.key] = acc[category.key] || [];
                    acc[category.key].push(photo);
                } else {
                    acc["diğer"] = acc["diğer"] || [];
                    acc["diğer"].push(photo);
                }

                return acc;
            }, {});

            setCategoryPhotos(categorizedPhotos);

            // Rapor verilerini al
            const report = await fetchReport(stand.stand_id);
            setReportData(report);
        } catch (error) {
            console.error("Stand seçilirken hata oluştu:", error);
        }
    };

    const handleSaveReport = async (category, value) => {
        const updatedReportData = { ...reportData, [category]: value };
        setReportData(updatedReportData);

        const reportPayload = { stand_id: selectedStand.stand_id, fuar_id: activeFairId, ...updatedReportData };

        try {
            await saveReport(reportPayload);
            console.log("Rapor başarıyla kaydedildi:", updatedReportData);
        } catch (error) {
            console.error("Rapor kaydedilirken hata oluştu:", error);
        }
        setOpenSelections(prevState => ({ ...prevState, [category]: false }));
    };

    const handleBack = () => {
        setSelectedStand(null); // Seçili standı sıfırla

        // Gerekli parametrelerle fetchActiveStands çağrısı
        fetchActiveStands(page - 1, limit, salonFilter, search);
    };

    useEffect(() => {
        const updateDimensions = () => {
            if (containerRef.current) {
                const { clientWidth, clientHeight } = containerRef.current;
                setDimensions({ width: clientWidth, height: clientHeight });
            }
        };

        updateDimensions(); // İlk render sonrası boyutları al
        window.addEventListener("resize", updateDimensions); // Pencere yeniden boyutlandırıldığında güncelle

        return () => window.removeEventListener("resize", updateDimensions);
    }, []);


    const handleTakePhoto = () => {
        if (webcamRef.current) {
            const screenshot = webcamRef.current.getScreenshot({
                width: 1080,
                height: 1920,
            });
            setPhoto(screenshot); // Fotoğraf state'ini ayarla
            setIsPhotoTaken(true); // Çekildiğini belirt
        } else {
            console.error("Webcam çalışmıyor.");
        }
    };

    const handleUploadPhoto = async () => {
        if (!photo) {
            alert("Lütfen önce bir fotoğraf çekin.");
            return;
        }

        try {
            const formData = new FormData();
            const blob = dataURLtoBlob(photo);

            formData.append("photo", blob);
            formData.append("category_id", selectedCategory || null);
            formData.append("stand_id", selectedStand ? selectedStand.stand_id : null);
            formData.append("user_id", localStorage.getItem("userId"));
            formData.append("fuar_zamani", fuarZamani || null);

            // Backend'e görseli yükle
            const response = await uploadAreaPhoto(formData);

            // Eğer görsel başarıyla yüklendiyse
            if (response && response.data) {

                // Seçili stantı yeniden yükle (çık gir yapmış gibi davranır)
                if (selectedStand) {
                    await handleSelectStand(selectedStand); // Yeniden stantı seç
                }

                setPhoto(null); // Fotoğraf state'ini sıfırla
                setIsPhotoTaken(false); // Çekilen durumu sıfırla
                setSelectedCategory(null); // Kategori sıfırla
            }
        } catch (error) {
            console.error("Fotoğraf yüklenirken hata oluştu:", error);
            alert("Fotoğraf yüklenirken bir hata oluştu.");
        }
    };

    const handleCancelPhoto = () => {
        setPhoto(null);
        setIsPhotoTaken(false);
        setSelectedCategory(null);
    };

    const handleRemovePhoto = async (photo, categoryKey) => {
        if (!photo || !photo.stand_report_file_id) return;

        try {
            // Backend'de görseli kaldır
            const isUncategorized = categoryKey === "diğer";
            if (isUncategorized) {
                await handleRemovePhotoo(photo.stand_report_file_id, true);
            } else {
                await handleRemovePhotoo(photo.stand_report_file_id, false);
            }

            // UI güncellemesi
            setCategoryPhotos((prev) => {
                const updatedCategoryPhotos = { ...prev };

                // Kategori listesinden kaldır
                if (updatedCategoryPhotos[categoryKey]) {
                    updatedCategoryPhotos[categoryKey] = updatedCategoryPhotos[categoryKey].filter(
                        (item) => item.stand_report_file_id !== photo.stand_report_file_id
                    );
                }

                // Eşlenmemiş görseller kategorisine ekle
                if (categoryKey !== "diğer") {
                    updatedCategoryPhotos["diğer"] = [
                        ...(updatedCategoryPhotos["diğer"] || []),
                        { ...photo, stand_report_file_category: null }, // Kategori bilgisini null yap
                    ];
                }

                return updatedCategoryPhotos;
            });

        } catch (error) {
            console.error("Fotoğraf kaldırılırken hata oluştu:", error);
            alert("Fotoğraf kaldırılırken bir hata oluştu.");
        }
    };


    const handleCategorySelection = (categoryId) => {
        setSelectedCategory(categoryId);
        handleTakePhoto();
    };


    const fetchPhotosForCategory = async (categoryId) => {
        if (!selectedStand) return;

        const photos = await fetchCategoryPhotos(selectedStand.stand_id, categoryId);

        setCategoryPhotos((prev) => ({
            ...prev,
            [categoryId]: photos.map((photo) => ({
                ...photo,
                stand_report_file_link: constructPhotoLink(photo.stand_report_file_link),
            })),
        }));
    };


    const renderStoreIcon = (status) => {
        const badgeColors = {
            gray: "#6c757d", // Hiç görsel yok
            red: "#dc3545", // Eksik işaretleme var
            yellow: "#ffc107", // Eksik görseller var
            green: "#28a745", // En az 5 kategoride görsel var
            blue: "#007bff", // Tüm kategorilerde görsel var
        };

        return (
            <span
                style={{
                    backgroundColor: badgeColors[status],
                    color: "white",
                    borderRadius: "12px",
                    padding: "6px 10px",
                    display: "inline-flex",
                    alignItems: "center",
                }}
            >
                <i className="bx bx-store"></i>
            </span>
        );
    };

    const renderPagination = () => {
        const paginationItems = [];
        const maxPagesToShow = 5;

        for (let i = 1; i <= totalPages; i++) {
            if (totalPages > maxPagesToShow) {
                if (i === 1 || i === totalPages || (i >= page - 1 && i <= page + 1)) {
                    paginationItems.push(
                        <PaginationItem key={i} active={i === page}>
                            <PaginationLink onClick={() => handlePageChange(i)}>{i}</PaginationLink>
                        </PaginationItem>
                    );
                } else if (i === 2 || i === totalPages - 1) {
                    paginationItems.push(
                        <PaginationItem key={i} disabled>
                            <PaginationLink>...</PaginationLink>
                        </PaginationItem>
                    );
                }
            } else {
                paginationItems.push(
                    <PaginationItem key={i} active={i === page}>
                        <PaginationLink onClick={() => handlePageChange(i)}>{i}</PaginationLink>
                    </PaginationItem>
                );
            }
        }
        return paginationItems;
    };

    const constructPhotoLink = (link) => {
        if (!link) return ""; // Eğer link yoksa boş döner
        return link.startsWith("/") ? `${baseApiUrl}${link}` : link; // Tam veya bağıl yol kontrolü
    };


    const renderCategoryPhotos = (categoryKey) => {
        const photos = categoryPhotos[categoryKey] || [];
        const isMainPhoto = categoryKey === "ana_fotograf";

        // Eğer kategori "ana_fotograf" ise, fuar öncesi ve sonrası ayrımı yapılmadan gösterilecek
        if (isMainPhoto) {
            return (
                <div
                    className="mt-3"
                    style={{
                        display: "flex",
                        gap: "8px", // Görseller arası boşluk
                        overflowX: "auto", // Yatay kaydırma
                        paddingBottom: "10px", // Scroll bar için alt boşluk
                        maxWidth: "100%",
                    }}
                >
                    {photos.map((photo, index) => (
                        <div key={index} style={{ position: "relative", flexShrink: 0 }}>
                            <img
                                src={constructPhotoLink(photo.stand_report_file_link)}
                                alt={`Fotoğraf ${index + 1}`}
                                style={{
                                    width: "150px",
                                    height: "150px",
                                    objectFit: "cover",
                                    borderRadius: "7px",
                                    boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                                    cursor: "pointer",
                                }}
                                onClick={() => handlePhotoModal(photo)}
                            />
                            <span
                                style={{
                                    position: "absolute",
                                    top: "4px",
                                    right: "4px",
                                    backgroundColor: "rgba(255, 0, 0, 0.8)",
                                    color: "#fff",
                                    borderRadius: "50%",
                                    width: "20px",
                                    height: "20px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    fontSize: "12px",
                                    cursor: "pointer",
                                }}
                                onClick={() => handleRemovePhoto(photo, categoryKey)}
                            >
                                ×
                            </span>
                        </div>
                    ))}
                </div>
            );
        }

        // Diğer kategoriler için fuar öncesi ve sonrası ayrımı yapılır
        const beforeFairPhotos = photos.filter((photo) => photo.fuar_zamani === "fuar_oncesi");
        const afterFairPhotos = photos.filter((photo) => photo.fuar_zamani === "fuar_sonrasi");

        return (
            <div
                className="mt-3"
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px", // Görsel grupları arası boşluk
                    overflow: "hidden", // Yatay taşmaları engeller
                    maxWidth: "100%", // Konteynerin genişliğini sınırlar
                }}
            >
                {/* Fuar Öncesi Görseller */}
                {beforeFairPhotos.length > 0 && (
                    <div>
                        <h6 style={{ textAlign: "left", marginBottom: "10px" }}>
                            {selectedLang === "tr" ? "Fuar" : "Fair"}
                        </h6>
                        <div
                            style={{
                                display: "flex",
                                gap: "8px", // Görseller arası boşluk
                                overflowX: "auto", // Yatay kaydırmayı etkinleştirir
                                paddingBottom: "10px", // Scroll bar için alt boşluk
                            }}
                        >
                            {beforeFairPhotos.map((photo, index) => (
                                <div key={index} style={{ position: "relative", flexShrink: 0 }}>
                                    <img
                                        src={constructPhotoLink(photo.stand_report_file_link)}
                                        alt={`Fotoğraf ${index + 1}`}
                                        style={{
                                            width: "100px",
                                            height: "100px",
                                            objectFit: "cover",
                                            borderRadius: "7px",
                                            boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => handlePhotoModal(photo)}
                                    />
                                    <span
                                        style={{
                                            position: "absolute",
                                            top: "4px",
                                            right: "4px",
                                            backgroundColor: "rgba(255, 0, 0, 0.8)",
                                            color: "#fff",
                                            borderRadius: "50%",
                                            width: "20px",
                                            height: "20px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            fontSize: "12px",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => handleRemovePhoto(photo, categoryKey)}
                                    >
                                        ×
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                {/* Fuar Sonrası Görseller */}
                {afterFairPhotos.length > 0 && (
                    <div className="mt-4">
                        <h6 style={{ textAlign: "left", marginBottom: "10px" }}>
                            {selectedLang === "tr" ? "Söküm" : "Breakdown"}
                        </h6>
                        <div
                            style={{
                                display: "flex",
                                gap: "8px", // Görseller arası boşluk
                                overflowX: "auto", // Yatay kaydırmayı etkinleştirir
                                paddingBottom: "10px", // Scroll bar için alt boşluk
                            }}
                        >
                            {afterFairPhotos.map((photo, index) => (
                                <div key={index} style={{ position: "relative", flexShrink: 0 }}>
                                    <img
                                        src={constructPhotoLink(photo.stand_report_file_link)}
                                        alt={`Fotoğraf ${index + 1}`}
                                        style={{
                                            width: "100px",
                                            height: "100px",
                                            objectFit: "cover",
                                            borderRadius: "7px",
                                            boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => handlePhotoModal(photo)}
                                    />
                                    <span
                                        style={{
                                            position: "absolute",
                                            top: "4px",
                                            right: "4px",
                                            backgroundColor: "rgba(255, 0, 0, 0.8)",
                                            color: "#fff",
                                            borderRadius: "50%",
                                            width: "20px",
                                            height: "20px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            fontSize: "12px",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => handleRemovePhoto(photo, categoryKey)}
                                    >
                                        ×
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        );
    };




    const handleEditPhoto = (photo) => {
        setPhotoToEdit(photo); // Düzenlenecek fotoğrafı state'e ekle
        setSelectedPhotoUrl(constructPhotoLink(photo.stand_report_file_link)); // Doğru görsel URL'sini ayarla
        setIsEditModalOpen(true); // Modalı aç
    };


    const closeEditModal = () => {
        setPhotoToEdit(null);
        setSelectedPhotoUrl(null); // Görsel URL'sini sıfırla
        setIsEditModalOpen(false);
        setSelectedCategory(null); // Kategori de sıfırlanır
    };


    const handleSaveCategoryForPhoto = async (categoryId) => {
        try {
            await handleAssignPhotoToCategory(photoToEdit, categoryId); // Fotoğrafı kategoriye ata
            closeEditModal(); // Modalı kapat
        } catch (error) {
            console.error("Kategoriye atama hatası:", error);
            alert(selectedLang === "tr" ? "Kategoriye atama sırasında bir hata oluştu." : "An error occurred while assigning the category.");
        }
    };

    const renderEditModal = () => (
        <Modal isOpen={isEditModalOpen} toggle={closeEditModal} centered size="md">
            <ModalHeader toggle={closeEditModal}>
                {selectedLang === "tr" ? "Fotoğraf Düzenle" : "Edit Photo"}
            </ModalHeader>
            <ModalBody className="text-center">
                {photoToEdit && (
                    <>
                        {/* Fotoğraf Önizlemesi */}
                        <img
                            src={selectedPhotoUrl}
                            alt="Düzenlenecek Görsel"
                            style={{
                                maxWidth: "100%",
                                maxHeight: "50vh",
                                borderRadius: "8px",
                                marginBottom: "20px",
                            }}
                        />
                        <h6>{selectedLang === "tr" ? "Bir kategori seçin" : "Select a category"}</h6>
                        <div
                            style={{
                                display: "grid",
                                gridTemplateColumns: "repeat(3, 1fr)", // 3 kolon
                                gap: "10px",
                                marginTop: "20px",
                            }}
                        >
                            {/* "Ana Fotoğraf" Tam Genişlikte */}
                            <Button
                                key="ana_fotograf"
                                color="primary"
                                style={{
                                    gridColumn: "1 / -1", // Grid'i tam genişlet
                                }}
                                onClick={() => handleSaveCategoryForPhoto(11)}
                            >
                                {selectedLang === "tr" ? "Ana Fotoğraf" : "Main Photo"}
                            </Button>

                            {/* Diğer Kategoriler Üçerli Sıralanır */}
                            {categories
                                .filter((category) => category.id !== 11) // Ana Fotoğraf dışındaki kategoriler
                                .map((category) => (
                                    <Button
                                        key={category.id}
                                        color="primary"
                                        onClick={() => handleSaveCategoryForPhoto(category.id)}
                                    >
                                        {selectedLang === "tr" ? category.tr : category.en}
                                    </Button>
                                ))}
                        </div>
                    </>
                )}
            </ModalBody>
        </Modal>
    );

    const renderUncategorizedPhotos = () => {
        const uncategorizedPhotos = categoryPhotos["diğer"] || [];

        return (
            <div
                className="mt-3"
                style={{
                    display: "flex",
                    gap: "8px",
                    overflowX: uncategorizedPhotos.length > 3 ? "auto" : "hidden",
                    justifyContent: uncategorizedPhotos.length <= 3 ? "center" : "flex-start",
                }}
            >
                {uncategorizedPhotos.map((photo, index) => (
                    <div key={index} style={{ position: "relative", flexShrink: 0 }}>
                        <img
                            src={constructPhotoLink(photo.stand_report_file_link)}
                            alt={`Eşlenmemiş Fotoğraf ${index + 1}`}
                            style={{
                                width: "100px",
                                height: "100px",
                                objectFit: "cover",
                                borderRadius: "7px",
                                boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                                cursor: "pointer",
                            }}
                            onClick={() => handleEditPhoto(photo)}
                        />
                        <span
                            style={{
                                position: "absolute",
                                top: "4px",
                                right: "4px",
                                backgroundColor: "rgba(255, 0, 0, 0.8)",
                                color: "#fff",
                                borderRadius: "50%",
                                width: "20px",
                                height: "20px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                fontSize: "12px",
                                cursor: "pointer",
                            }}
                            onClick={() => handleRemovePhoto(photo, "diğer")}
                        >
                            ×
                        </span>
                    </div>
                ))}
            </div>
        );
    };

    const renderPhotoModal = () => (
        <Modal isOpen={isModalOpen} toggle={toggleModal} centered size="lg">
            <ModalHeader toggle={toggleModal}>
                {selectedLang === 'tr' ? 'Görsel Önizleme' : 'Image Preview'}
            </ModalHeader>
            <ModalBody className="text-center">
                {selectedPhotoUrl && (
                    <div style={{ position: "relative" }}>
                        {/* Görsel */}
                        <img
                            src={selectedPhotoUrl}
                            alt="Büyük Görsel"
                            style={{
                                maxWidth: "100%",
                                maxHeight: "80vh",
                                borderRadius: "8px",
                                transform: `rotate(${rotation}deg)`, // Görseli döndür
                                transition: "transform 0.3s ease", // Döndürme animasyonu
                            }}
                        />

                        {/* Döndürme Butonları */}
                        <div style={{ marginTop: "10px", display: "flex", justifyContent: "center", gap: "20px" }}>
                            {/* Sola Döndür */}
                            <Button
                                color="secondary"
                                onClick={() => setRotation((prev) => prev - 90)} // 90 derece sola
                                style={{
                                    borderRadius: "50%",
                                    width: "50px",
                                    height: "50px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <i className="bx bx-rotate-left" style={{ fontSize: "20px" }}></i>
                            </Button>

                            {/* Sağa Döndür */}
                            <Button
                                color="secondary"
                                onClick={() => setRotation((prev) => prev + 90)} // 90 derece sağa
                                style={{
                                    borderRadius: "50%",
                                    width: "50px",
                                    height: "50px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <i className="bx bx-rotate-right" style={{ fontSize: "20px" }}></i>
                            </Button>
                        </div>
                    </div>
                )}
            </ModalBody>
        </Modal>
    );

    const getIcon = (category) => {
        if (category === "ana_fotograf") return null;
        switch (reportData[category]) {
            case 'Evet': return EvetImg;
            case 'Hayır': return HayirImg;
            case 'Yok': return YokImg;
            default: return BelirtilmediImg;
        }
    };

    const calculateBadgeStatus = (photoCounts, reportData, mode) => {
        const categories = Object.keys(photoCounts).filter(
            (category) =>
                category !== "ana_fotograf" && category !== "donatilmis_yapi" // Ana Fotoğraf ve Donatılmış Yapıyı işaretleme kontrolünden çıkar
        );

        // Rapor yoksa (tüm işaretlemeler null veya rapor boş)
        if (!reportData || Object.keys(reportData).length === 0) {
            return "gray";
        }

        // Eksik işaretleme kontrolü (donatılmış_yapı hariç diğer kategoriler için)
        const hasIncompleteMarks = categories.some(
            (category) =>
                reportData[category] !== "Evet" &&
                reportData[category] !== "Hayır" &&
                reportData[category] !== "Yok"
        );

        // Ana Fotoğrafta en az bir görsel bulunmalı (fuar zamanı fark etmeksizin)
        const hasMainPhoto =
            photoCounts["ana_fotograf"]?.beforeFair > 0 ||
            photoCounts["ana_fotograf"]?.afterFair > 0;

        if (hasIncompleteMarks || !hasMainPhoto) {
            return "red"; // Eksik işaretleme veya ana fotoğraf yoksa kırmızı
        }

        // Görsellerin kategori bazında sayılması (donatılmış_yapı hariç)
        const categoriesWithPhotos = categories.filter(
            (category) => photoCounts[category][mode] > 0
        ).length;

        const totalCategories = categories.length;

        if (categoriesWithPhotos === 0) {
            return "yellow"; // Hiç görsel yok
        }

        if (categoriesWithPhotos < totalCategories) {
            if (categoriesWithPhotos >= 5) {
                return "green"; // En az 5 kategoride görsel var
            }
            return "yellow"; // Bazı kategorilerde görsel eksik
        }

        return "blue"; // Tüm kategorilerde görsel var
    };


    const getSelectedSalonName = () => {
        if (salonFilter === null) {
            return selectedLang === 'tr' ? "Tümü" : "All";
        }
        const selectedSalon = salons.find(salon => salon.salon_id === salonFilter);
        return selectedSalon ? selectedSalon.salon_name : '';
    };

    const dataURLtoBlob = (dataURL) => {
        const arr = dataURL.split(",");
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
    };

    return (
        <div className="page-content">
            <Container fluid>
                <div style={{ position: "relative", width: "100%", height: "100%", overflow: "hidden" }}>
                    <Webcam
                        audio={false}
                        videoConstraints={videoConstraints}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        screenshotQuality={1} // Kaliteyi artırmak için 1 (maksimum kalite)
                        style={{
                            width: "100%",
                            height: "100%",
                            opacity: photo ? 0.5 : 1,
                        }}
                    />
                    {photo && (
                        <img
                            src={photo}
                            alt="Çekilen Fotoğraf"
                            style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                zIndex: 1,
                            }}
                        />
                    )}

                    <div
                        style={{
                            position: "absolute",
                            top: 10,
                            right: 10,
                            display: "flex",
                            alignItems: "center",
                            zIndex: 2,
                        }}
                    >
                        <Button
                            style={{
                                backgroundColor: "rgba(0, 0, 0, 0.6)",
                                color: "white",
                                border: "none",
                                borderRadius: "50%",
                                width: "40px",
                                height: "40px",
                                fontSize: "18px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                            onClick={() => setShowZoomButtons(!showZoomButtons)}
                        >
                            <i className="bx bx-dots-horizontal-rounded"></i>
                        </Button>
                    </div>
                    {showZoomButtons && (
                        <div
                            style={{
                                position: "absolute",
                                top: 60,
                                right: 10,
                                backgroundColor: "rgba(0, 0, 0, 0.6)",
                                padding: "10px",
                                borderRadius: "8px",
                                display: "flex",
                                flexDirection: "column",
                                zIndex: 2,
                            }}
                        >
                            {[1, 2, 5].map((level) => (
                                <Button
                                    key={level}
                                    style={{
                                        backgroundColor: "rgba(0, 0, 0, 0.6)",
                                        color: "white",
                                        border: "none",
                                        margin: "5px 0",
                                        borderRadius: "50%",
                                        width: "50px",
                                        height: "50px",
                                    }}
                                    onClick={() => setZoom(level)}
                                >
                                    {level}x
                                </Button>
                            ))}
                        </div>
                    )}

                    {selectedStand && (<h4 style={{ color: '#fff', position: 'absolute', top: 0, left: "48%" }}>{selectedStand.stand_name}</h4>)}


                    {selectedStand && (
                        <div
                            style={{
                                position: "absolute",
                                bottom: 100,
                                left: "50%",
                                transform: "translateX(-50%)",
                                zIndex: 2,
                                display: areCategoryButtonsVisible ? "grid" : "none",
                                gridTemplateColumns: "repeat(3, 1fr)",
                                gap: "25px",
                            }}
                        >
                            {categoriess.map((cat) => {
                                // Kategoriye ait görselleri filtrele
                                const photosForCategory = categoryPhotos[cat.key] || [];
                                const beforeFairCount = photosForCategory.filter(
                                    (photo) => photo.fuar_zamani === "fuar_oncesi"
                                ).length;
                                const afterFairCount = photosForCategory.filter(
                                    (photo) => photo.fuar_zamani === "fuar_sonrasi"
                                ).length;

                                return (
                                    <Button
                                        key={cat.id}
                                        onClick={() => handleCategorySelection(cat.id)}
                                        style={{
                                            gridColumn: cat.id === 11 ? "span 3" : "auto",
                                            backgroundColor:
                                                selectedCategory === cat.id
                                                    ? "rgba(3, 131, 115, 0.8)"
                                                    : "rgba(0, 0, 0, 0.6)",
                                            color: "white",
                                            border: "none",
                                            borderRadius: "6px",
                                            padding: "8px 10px",
                                            fontSize: "14px",
                                            textAlign: "center",
                                            position: "relative", // Sayaç konumlandırma için gerekli
                                        }}
                                    >
                                        {selectedLang === "tr" ? cat.tr : cat.en}

                                        {/* Fuar Öncesi Sayaç */}
                                        {beforeFairCount > 0 && (
                                            <span
                                                style={{
                                                    position: "absolute",
                                                    top: "-5px",
                                                    left: "-10px",
                                                    backgroundColor: "blue",
                                                    color: "white",
                                                    borderRadius: "50%",
                                                    width: "20px",
                                                    height: "20px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    fontSize: "12px",
                                                }}
                                            >
                                                {beforeFairCount}
                                            </span>
                                        )}

                                        {/* Fuar Sonrası Sayaç */}
                                        {afterFairCount > 0 && (
                                            <span
                                                style={{
                                                    position: "absolute",
                                                    bottom: "-5px",
                                                    right: "-10px",
                                                    backgroundColor: "red",
                                                    color: "white",
                                                    borderRadius: "50%",
                                                    width: "20px",
                                                    height: "20px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    fontSize: "12px",
                                                }}
                                            >
                                                {afterFairCount}
                                            </span>
                                        )}
                                    </Button>
                                );
                            })}
                        </div>
                    )}

                    <div
                        style={{
                            position: "absolute",
                            bottom: 30,
                            right: 30,
                            zIndex: 3,
                        }}
                    >
                        <Button
                            color="secondary"
                            onClick={() => setAreCategoryButtonsVisible((prev) => !prev)}
                            style={{
                                backgroundColor: "rgba(0, 0, 0, 0.6)",
                                color: "white",
                                border: "none",
                                borderRadius: "50%",
                                width: "40px",
                                height: "40px",
                            }}
                        >
                            <i className={areCategoryButtonsVisible ? "bx bx-hide" : "bx bx-show"}></i>
                        </Button>
                    </div>

                    <div
                        style={{
                            position: "absolute",
                            bottom: 30,
                            left: "50%",
                            transform: "translateX(-50%)",
                            zIndex: 2,
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        {photo && (
                            <Button
                                color="primary"
                                onClick={handleCancelPhoto}
                                style={{
                                    marginRight: "10px",
                                    borderRadius: "50%",
                                    width: "50px",
                                    height: "50px",
                                }}
                            >
                                <i className="bx bx-x"></i>
                            </Button>
                        )}
                        <Button
                            onClick={handleTakePhoto}
                            style={{
                                backgroundColor: "rgba(0, 0, 0, 0.6)",
                                color: "white",
                                border: "none",
                                borderRadius: "50%",
                                width: "60px",
                                height: "60px",
                            }}
                        >
                            <i className="bx bx-camera"></i>
                        </Button>
                        {photo && (
                            <Button
                                color="secondary"
                                onClick={handleUploadPhoto}
                                style={{
                                    marginLeft: "10px",
                                    borderRadius: "50%",
                                    width: "50px",
                                    height: "50px",
                                }}
                            >
                                <i className="bx bx-check"></i>
                            </Button>
                        )}
                    </div>
                </div>

                {selectedStand ? (
                    <Card className="p-4">
                        <Button color="secondary" onClick={handleBack}>
                            {selectedLang === 'tr' ? 'Geri Dön' : 'Back'}
                        </Button>
                        <Form className="mt-4">
                            <div style={{ borderBottom: "3px solid red", marginBottom: "15px" }}></div>
                            <h5 style={{ textAlign: "center" }}>
                                {selectedLang === 'tr' ? 'Ana Fotoğraf' : 'Main Photo'}
                            </h5>
                            {renderCategoryPhotos("ana_fotograf")}
                            {categories.map((category) => (
                                <Card key={category.key} className="pt-3 pe-3 ps-3 mb-3">
                                    <FormGroup key={category.key} className="text-center mb-4">
                                        <Label className="mb-2">
                                            {selectedLang === "tr"
                                                ? category.key === "ana_fotograf"
                                                    ? `${selectedStand?.name || ""} - ${selectedStand?.stand_name || ""}`
                                                    : category.tr
                                                : category.key === "ana_fotograf"
                                                    ? `${selectedStand?.name || ""} - ${selectedStand?.stand_name || ""}`
                                                    : category.en}
                                        </Label>
                                        {category.key === "ana_fotograf" && (
                                            <>
                                                <div style={{ borderTop: "3px solid red", marginTop: "15px" }}></div>
                                                <h5 style={{ textAlign: "center" }}>
                                                    {selectedLang === 'tr' ? 'Eşlenmemiş Görseller' : 'Unmatched Images'}
                                                </h5>
                                                {renderUncategorizedPhotos()}
                                                <div style={{ borderTop: "3px solid red", marginTop: "15px" }}></div>
                                            </>
                                        )}
                                        {category.key !== "ana_fotograf" && (
                                            <div className="d-flex flex-column align-items-center">
                                                <div className="d-flex flex-column align-items-center" style={{ position: "relative" }}>
                                                    <img
                                                        src={getIcon(category.key)}
                                                        alt={category.key}
                                                        style={{
                                                            width: '60px',
                                                            height: '60px',
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => handleSelectIcon(category.key)}
                                                    />

                                                    {/* Fuar Öncesi Sayaç */}
                                                    {categoryPhotos[category.key]?.filter(photo => photo.fuar_zamani === 'fuar_oncesi').length > 0 && (
                                                        <span
                                                            style={{
                                                                position: "absolute",
                                                                top: "50%",
                                                                left: "-20px",
                                                                transform: "translateY(-50%)", // Dikey hizalama
                                                                backgroundColor: "blue",
                                                                color: "white",
                                                                borderRadius: "50%",
                                                                width: "20px",
                                                                height: "20px",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                fontSize: "12px",
                                                            }}
                                                        >
                                                            {categoryPhotos[category.key]?.filter(photo => photo.fuar_zamani === 'fuar_oncesi').length}
                                                        </span>
                                                    )}

                                                    {/* Fuar Sonrası Sayaç */}
                                                    {categoryPhotos[category.key]?.filter(photo => photo.fuar_zamani === 'fuar_sonrasi').length > 0 && (
                                                        <span
                                                            style={{
                                                                position: "absolute",
                                                                top: "50%",
                                                                right: "-20px",
                                                                transform: "translateY(-50%)", // Dikey hizalama
                                                                backgroundColor: "red",
                                                                color: "white",
                                                                borderRadius: "50%",
                                                                width: "20px",
                                                                height: "20px",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                fontSize: "12px",
                                                            }}
                                                        >
                                                            {categoryPhotos[category.key]?.filter(photo => photo.fuar_zamani === 'fuar_sonrasi').length}
                                                        </span>
                                                    )}
                                                </div>

                                                {openSelections[category.key] && ( // Eğer kategori açık durumdaysa seçim ikonlarını göster
                                                    <div className="mt-2 d-flex justify-content-center gap-3 align-items-center">
                                                        <div className="text-center">
                                                            <img
                                                                src={EvetImg}
                                                                alt="Evet"
                                                                style={{ width: '40px', height: '40px', cursor: 'pointer' }}
                                                                onClick={() => handleSaveReport(category.key, 'Evet')}
                                                            />
                                                            <div style={{ marginTop: '5px', fontSize: '12px' }}>
                                                                {selectedLang === "tr" ? "Uygun" : "Yes"}
                                                            </div>
                                                        </div>
                                                        <div className="text-center">
                                                            <img
                                                                src={HayirImg}
                                                                alt="Hayır"
                                                                style={{ width: '40px', height: '40px', cursor: 'pointer' }}
                                                                onClick={() => handleSaveReport(category.key, 'Hayır')}
                                                            />
                                                            <div style={{ marginTop: '5px', fontSize: '12px' }}>
                                                                {selectedLang === "tr" ? "Uygun Değil" : "No"}
                                                            </div>
                                                        </div>
                                                        <div className="text-center">
                                                            <img
                                                                src={YokImg}
                                                                alt="Yok"
                                                                style={{ width: '40px', height: '40px', cursor: 'pointer' }}
                                                                onClick={() => handleSaveReport(category.key, 'Yok')}
                                                            />
                                                            <div style={{ marginTop: '5px', fontSize: '12px' }}>
                                                                {selectedLang === "tr" ? "Yok" : "N/A"}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {openSelections[category.key] && (
                                                    <Input
                                                        type="textarea"
                                                        value={categoryNotes[category.id] || ""}
                                                        placeholder={
                                                            selectedLang === "tr"
                                                                ? "Bu kategori için bir not yazın..."
                                                                : "Write a note for this category..."
                                                        }
                                                        onChange={(e) => {
                                                            const newNotes = { ...categoryNotes, [category.id]: e.target.value };
                                                            setCategoryNotes(newNotes); // State'i anlık olarak güncelle
                                                        }}
                                                        onBlur={(e) => saveCategoryNote({
                                                            user_id: localStorage.getItem('userId'),
                                                            stand_id: selectedStand.stand_id,
                                                            category_id: category.id,
                                                            note_text: e.target.value,
                                                        })}
                                                    />
                                                )}
                                                {openSelections[category.key] && renderCategoryPhotos(category.key)}
                                            </div>
                                        )}
                                    </FormGroup>
                                </Card>
                            ))}
                        </Form>
                    </Card>
                ) : (
                    <>
                        <Card className="p-3 mb-3 mt-1">
                            <Row className="align-items-center">
                                <Col xs="12" md="3" className="mb-2 mb-md-0">
                                    <Input
                                        type="text"
                                        placeholder={selectedLang === 'tr' ? 'Ara...' : 'Search...'}
                                        value={search}
                                        onChange={(e) => setSearch(e.target.value)} // search state'i güncelleniyor
                                    />
                                </Col>

                                <Col xs="6" md="1" className="mb-2 mb-md-0">
                                    <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                                        <DropdownToggle color="primary" caret>
                                            {selectedLang === 'tr' ? `Gösterim : ${limit}` : `Records: ${limit}`}
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            {[10, 25, 50, 100, 500, 1000, 2000].map((num) => (
                                                <DropdownItem key={num} onClick={() => setLimit(num)}>
                                                    {num} {selectedLang === 'tr' ? "Gösterim" : "Records"}
                                                </DropdownItem>
                                            ))}
                                        </DropdownMenu>
                                    </Dropdown>
                                </Col>

                                <Col xs="6" md="1" className="mb-2 mb-md-0">
                                    <Dropdown isOpen={salonDropdownOpen} toggle={toggleSalonDropdown}>
                                        <DropdownToggle color="primary" caret>
                                            {selectedLang === 'tr' ? `Salon: ${getSelectedSalonName()}` : `Hall: ${getSelectedSalonName()}`}
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem key="all" onClick={() => setSalonFilter(null)}>
                                                {selectedLang === 'tr' ? "Tümü" : "All"}
                                            </DropdownItem>
                                            {salons && salons.map((salon) => (
                                                <DropdownItem key={salon.salon_id} onClick={() => setSalonFilter(salon.salon_id)}>
                                                    {salon.salon_name}
                                                </DropdownItem>
                                            ))}
                                        </DropdownMenu>

                                    </Dropdown>
                                </Col>
                            </Row>
                        </Card>

                        <Card className="pt-3 pe-3 ps-3">
                            <Table striped bordered hover responsive>
                                <thead>
                                    <tr>
                                        <th onClick={() => handleSort("stand_name")} style={{ cursor: "pointer" }}>
                                            {selectedLang === 'tr' ? 'Numara' : 'Number'} {renderSortIcon("stand_name")}
                                        </th>
                                        <th onClick={() => handleSort("hall")} style={{ cursor: "pointer" }}>
                                            {selectedLang === 'tr' ? 'Salon' : 'Hall'} {renderSortIcon("hall")}
                                        </th>
                                        <th onClick={() => handleSort("name")} style={{ cursor: "pointer" }}>
                                            {selectedLang === 'tr' ? 'İsim' : 'Name'} {renderSortIcon("name")}
                                        </th>
                                        <th>{selectedLang === 'tr' ? 'Fuar' : 'Fair'}</th>
                                        <th>{selectedLang === 'tr' ? 'Söküm' : 'Breakdown'}</th>
                                        <th onClick={() => handleSort("type")} style={{ cursor: "pointer" }}>
                                            {selectedLang === 'tr' ? 'Tür' : 'Type'} {renderSortIcon("type")}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loading ? (
                                        <tr>
                                            <td colSpan="6" className="text-center">
                                                {selectedLang === "tr" ? "Yükleniyor..." : "Loading..."}
                                            </td>
                                        </tr>
                                    ) : sortedStands.length > 0 ? (
                                        sortedStands.map((stand) => {
                                            const beforeFairStatus = calculateBadgeStatus(
                                                stand.photoCounts,
                                                stand.reportData,
                                                "beforeFair"
                                            );
                                            const afterFairStatus = calculateBadgeStatus(
                                                stand.photoCounts,
                                                stand.reportData,
                                                "afterFair"
                                            );

                                            return (
                                                <tr key={stand.stand_id} onClick={() => handleSelectStand(stand)}>
                                                    <td>{stand.stand_name}</td>
                                                    <td>{stand.hall}</td>
                                                    <td>{stand.name}</td>
                                                    <td>{renderStoreIcon(beforeFairStatus)}</td>
                                                    <td>{renderStoreIcon(afterFairStatus)}</td>
                                                    <td>{stand.type || ""}</td>
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <tr>
                                            <td colSpan="6" className="text-center">
                                                {selectedLang === "tr" ? "Kayıt bulunamadı" : "No records found"}
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>


                        </Card>

                        <Pagination className="pagination justify-content-center mt-3">
                            {renderPagination()}
                        </Pagination>

                    </>
                )}

                {renderEditModal()}
                {renderPhotoModal()}

                {selectedStand && (
                    <div style={{
                        position: "fixed",
                        bottom: 30,
                        right: 30,
                        zIndex: 999, // Buton her şeyin üstünde gözükecek
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center"
                    }}>
                        {/* Sayfa üstüne çıkma butonu */}
                        <Button
                            color="primary"
                            style={{
                                borderRadius: "50%",
                                width: "40px",
                                height: "40px",
                                marginBottom: "10px", // Çarpı butonuyla boşluk
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                        >
                            <i className="bx bx-up-arrow-alt" style={{ fontSize: "24px" }}></i>
                        </Button>

                        {/* Geri dönme işlemi için çarpı butonu */}
                        <Button
                            color="primary"
                            style={{
                                borderRadius: "50%",
                                width: "40px",
                                height: "40px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                            onClick={handleBack}
                        >
                            <i className="bx bx-x" style={{ fontSize: "24px" }}></i>
                        </Button>
                    </div>
                )}
            </Container>
        </div>
    );
};

export default Area;