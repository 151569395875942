import React from "react";
import { Navigate } from "react-router-dom";
import Dashboard from "../pages/Home/Dashboard/index";
import Login from "../pages/Home/Login";
import BTSArea from "../pages/Management/Modules/BetterStands/Area";
import BTSDocs from "../pages/Management/Modules/BetterStands/Docs";
import BTSReports from "../pages/Management/Modules/BetterStands/Reports";
import FileTypes from "../pages/Management/Modules/Configurations/FileTypes";
import Jobs from "../pages/Management/Modules/Configurations/Jobs";
import EmailConfigs from "../pages/Management/Modules/Emails/Configs";
import EmailLogs from "../pages/Management/Modules/Emails/Logs";
import EmailTemplates from "../pages/Management/Modules/Emails/Templates";
import Users from "../pages/Management/Users";
import Fairs from "../pages/Management/Fairs";

const authProtectedRoutes = [
  { path: "/Dashboard", component: <Dashboard />, roles: [1,2,3,4,5,6,7,8,9,10,11,12,12,14,15,16,17,18,19] },
  { path: "/BTSArea", component: <BTSArea />, roles: [1,2,3,4,5,6,7,8,9,10,11] },
  { path: "/BTSDocs", component: <BTSDocs />, roles: [1,2,3,4,5,6,7,8,9,10,11] },
  { path: "/BTSReports", component: <BTSReports />, roles: [1,2,3,4,5,6,7,8,9,10,11] },
  { path: "/Users", component: <Users />, roles: [1,2,3,4,5,6,7,8,9,10,11] },
  { path: "/FileTypes", component: <FileTypes />, roles: [1,2,3] },
  { path: "/Jobs", component: <Jobs />, roles: [1,2,3] },
  { path: "/Fairs", component: <Fairs />, roles: [1,2,3,4,5,6,7,8,9,10,11,12,12,14,15,16,17,18,19] },
  { path: "/EmailTemplates", component: <EmailTemplates />, roles: [1,2,3] },
  { path: "/EmailLogs", component: <EmailLogs />, roles: [1,2,3] },
  { path: "/EmailConfigs", component: <EmailConfigs />, roles: [1,2,3] },
  { path: "/", exact: true, component: <Navigate to="/Fairs" /> },
  { path: "*", component: <Navigate to="/Fairs" /> },
];

const publicRoutes = [
  { path: "/Login", component: <Login /> },
];

export { authProtectedRoutes, publicRoutes };
