import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

// Public klasöründeki bayraklara giden yolları belirliyoruz
const languages = {
  tr: { flag: './tr.png', label: 'Türkçe' },
  en: { flag: './us.png', label: 'English' }
};

const LanguageDropdown = ({ selectedLang, setSelectedLang }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const changeLanguageAction = (langKey) => {
    setSelectedLang(langKey);
  };

  const get = (object, path) => path.split('.').reduce((o, p) => o ? o[p] : '', object);

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
      <DropdownToggle className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle" tag="button">
        <img
          src={get(languages, `${selectedLang}.flag`)}
          alt="Header Language"
          height="20"
          className="rounded"
        />
      </DropdownToggle>
      <DropdownMenu className="notify-item language py-2">
        {Object.keys(languages).map(key => (
          <DropdownItem
            key={key}
            onClick={() => changeLanguageAction(key)}
            className={`notify-item ${selectedLang === key ? "active" : "none"}`}
          >
            <img
              src={get(languages, `${key}.flag`)}
              alt={languages[key].label}
              className="me-2 rounded"
              height="18"
            />
            <span className="align-middle">
              {languages[key].label}
            </span>
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default LanguageDropdown;
