import React, { useContext, useEffect, useState } from "react";
import { Container, Table, Button, Badge, Row, Col, Input, Modal, ModalHeader, ModalBody, ModalFooter, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Pagination, PaginationItem, PaginationLink, Card } from "reactstrap";
import BreadCrumb from "../../../../../Components/Common/BreadCrumb";
import JobContext from "../../../../../Components/Contexts/Management/JobContext";

const Jobs = () => {
    document.title = "Meslekler | FDS";

    const { getJobs, addJob, updateJob, deleteJob } = useContext(JobContext);
    const [jobs, setJobs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [editingId, setEditingId] = useState(null);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [search, setSearch] = useState("");
    const [modalOpen, setModalOpen] = useState(false);
    const [newJob, setNewJob] = useState({ meslek_value: '', nameTR: '', nameEN: '' });
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deleteId, setDeleteId] = useState(null);

    const toggleDropdown = () => setDropdownOpen(prevState => !prevState);

    const fetchData = async () => {
        try {
            setLoading(true);
            const data = await getJobs(page, limit, search);
            setJobs(data.data);
            setTotalPages(data.totalPages);
        } catch (error) {
            console.error("Error fetching jobs:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [getJobs, page, limit, search]);

    const toggleModal = () => {
        setModalOpen(!modalOpen);
        setNewJob({ meslek_value: '', nameTR: '', nameEN: '' });
        setEditingId(null);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewJob(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleAddJob = async () => {
        try {
            const newJobWithValue = {
                ...newJob,
                meslek_value: newJob.nameTR
            };
            await addJob(newJobWithValue);
            setModalOpen(false);
            setPage(1);
        } catch (error) {
            console.error("Error adding job:", error);
        }
    };

    const handleEdit = (job) => {
        setNewJob({
            meslek_value: job.meslek_value,
            nameTR: job.nameTR,
            nameEN: job.nameEN,
        });
        setEditingId(job.meslek_id);
        setModalOpen(true);
    };

    const handleSave = async () => {
        if (editingId) {
            try {
                const updatedJobWithValue = {
                    ...newJob,
                    meslek_value: newJob.nameTR
                };
                await updateJob(editingId, updatedJobWithValue);
                setModalOpen(false);
                setEditingId(null);
                setPage(1);
                await fetchData();
            } catch (error) {
                console.error("Error updating job:", error);
            }
        } else {
            await handleAddJob();
        }
    };

    const handleDelete = (id) => {
        setDeleteId(id);
        setDeleteModalOpen(true);
    };

    const confirmDelete = async () => {
        if (deleteId) {
            try {
                await deleteJob(deleteId);
                setDeleteModalOpen(false);
                fetchData();
            } catch (error) {
                console.error("Error deleting job:", error);
            }
        }
    };

    const renderPagination = () => {
        const paginationItems = [];
        const maxPagesToShow = 5;

        for (let i = 1; i <= totalPages; i++) {
            if (totalPages > maxPagesToShow) {
                if (i === 1 || i === totalPages || (i >= page - 1 && i <= page + 1)) {
                    paginationItems.push(
                        <PaginationItem key={i} active={i === page}>
                            <PaginationLink onClick={() => setPage(i)}>{i}</PaginationLink>
                        </PaginationItem>
                    );
                } else if (i === 2 || i === totalPages - 1) {
                    paginationItems.push(
                        <PaginationItem key={i} disabled>
                            <PaginationLink>...</PaginationLink>
                        </PaginationItem>
                    );
                }
            } else {
                paginationItems.push(
                    <PaginationItem key={i} active={i === page}>
                        <PaginationLink onClick={() => setPage(i)}>{i}</PaginationLink>
                    </PaginationItem>
                );
            }
        }
        return paginationItems;
    };

    return (
        <div className="page-content">
            <Container fluid>
                <BreadCrumb title="Meslekler" pageTitle="FDS Yapılandırması" />
                <Card className="p-3">
                    <Row>
                        <Col md="1">
                            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                                <DropdownToggle caret>
                                    Gösterim: {limit}
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={() => setLimit(10)}>10</DropdownItem>
                                    <DropdownItem onClick={() => setLimit(25)}>25</DropdownItem>
                                    <DropdownItem onClick={() => setLimit(50)}>50</DropdownItem>
                                    <DropdownItem onClick={() => setLimit(100)}>100</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </Col>
                        <Col md="3">
                            <Input
                                type="text"
                                placeholder="Arama..."
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </Col>
                        <Col md="8" className="text-end">
                            <Button color="secondary" onClick={toggleModal}>Yeni Ekle</Button>
                        </Col>
                    </Row>
                </Card>
                {loading ? (
                    <div>Yükleniyor...</div>
                ) : (
                    <>
                        <Card className="pt-3 pe-3 ps-3">
                            <Table responsive bordered striped>
                                <thead>
                                    <tr>
                                        <th>Meslek</th>
                                        <th>Dil Durumu</th>
                                        <th>Eylem</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {jobs.map((job, index) => (
                                        <tr key={job.meslek_id}>
                                            <td>{job.meslek_value}</td>
                                            <td>
                                                <Badge color={job.nameTR && job.nameEN ? "secondary" : "warning"}>
                                                    {job.nameTR && job.nameEN ? "Tamamlanmış" : "Eksik"}
                                                </Badge>
                                            </td>
                                            <td>
                                                <Button color="warning" className="me-2" onClick={() => handleEdit(job)}>Düzenle</Button>
                                                <Button color="primary" onClick={() => handleDelete(job.meslek_id)}>Sil</Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Card>
                        <Pagination className="pagination justify-content-center">
                            {renderPagination()}
                        </Pagination>
                    </>
                )}

                <Modal isOpen={modalOpen} toggle={toggleModal}>
                    <ModalHeader toggle={toggleModal}>{editingId ? "Mesleği Düzenle" : "Yeni Meslek Ekle"}</ModalHeader>
                    <ModalBody>
                        <Row className="mb-3">
                            <Col md="12">
                                <Input
                                    type="text"
                                    name="nameTR"
                                    placeholder="Türkçe"
                                    value={newJob.nameTR}
                                    onChange={handleInputChange}
                                />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md="12">
                                <Input
                                    type="text"
                                    name="nameEN"
                                    placeholder="İngilizce"
                                    value={newJob.nameEN}
                                    onChange={handleInputChange}
                                />
                            </Col>
                        </Row>
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            color="primary"
                            onClick={editingId ? handleSave : handleAddJob}
                        >
                            {editingId ? "Güncelle" : "Ekle"}
                        </Button>
                        <Button color="secondary" onClick={toggleModal}>İptal</Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={deleteModalOpen} toggle={() => setDeleteModalOpen(false)}>
                    <ModalHeader toggle={() => setDeleteModalOpen(false)}>Mesleği Sil</ModalHeader>
                    <ModalBody>Bu mesleği silmek istediğinizden emin misiniz?</ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={confirmDelete}>Sil</Button>
                        <Button color="secondary" onClick={() => setDeleteModalOpen(false)}>İptal</Button>
                    </ModalFooter>
                </Modal>
            </Container>
        </div>
    );
};

export default Jobs;
