import React, { createContext, useCallback } from 'react';
import axios from 'axios';

const JobContext = createContext();

export const JobProvider = ({ children }) => {
    const apiurl = "https://e.sakagrup.com";
    const apiport = "";

    const api = {
        jobs: `${apiurl}:${apiport}/api/jobs`,
    };

    const getJobs = useCallback(async (page = 1, limit = 10, search = '') => {
        try {
            const response = await axios.get(api.jobs, {
                params: {
                    page,
                    limit,
                    search
                }
            });
            return response.data;
        } catch (error) {
            console.error('Error fetching jobs:', error);
            throw error;
        }
    }, [api.jobs]);

    const addJob = useCallback(async (newJob) => {
        try {
            const response = await axios.post(api.jobs, newJob);
            return response.data;
        } catch (error) {
            console.error('Error adding job:', error);
            throw error;
        }
    }, [api.jobs]);

    const updateJob = useCallback(async (id, updatedJob) => {
        try {
            const response = await axios.put(`${api.jobs}/${id}`, updatedJob);
            return response.data;
        } catch (error) {
            console.error('Error updating job:', error);
            throw error;
        }
    }, [api.jobs]);

    const deleteJob = useCallback(async (id) => {
        try {
            await axios.delete(`${api.jobs}/${id}`);
        } catch (error) {
            console.error('Error deleting job:', error);
            throw error;
        }
    }, [api.jobs]);

    return (
        <JobContext.Provider value={{ 
            getJobs, addJob, updateJob, deleteJob 
        }}>
            {children}
        </JobContext.Provider>
    );
};

export default JobContext;
