import React, { useContext, useEffect, useState } from "react";
import { Container, Card, Table, Button, Badge, Row, Col, Input, Modal, ModalHeader, ModalBody, ModalFooter, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Pagination, PaginationItem, PaginationLink } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import FairContext from "../../../Components/Contexts/Management/FairContext";
import { LanguageContext } from "../../../Components/Contexts/LanguageContext";

const Fairs = () => {
    document.title = "Fuarlar | FDS";

    const { getFairs, addFair, updateFair, activateFair } = useContext(FairContext);
    const { selectedLang } = useContext(LanguageContext); // Seçili dil alınır
    const [fairs, setFairs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [editingId, setEditingId] = useState(null);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [search, setSearch] = useState("");
    const [modalOpen, setModalOpen] = useState(false);
    const [newFair, setNewFair] = useState({ fair_name: "", start_date: "", end_date: "", logo: "", area: "", active: 1 });
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1); // Mevcut sayfa
    const [totalRecords, setTotalRecords] = useState(0); // Toplam kayıt sayısı
    const [viewMode, setViewMode] = useState("grid"); // Başlangıç modu: grid

    const user = JSON.parse(localStorage.getItem("user"));
    const userId = user?.id; // Kullanıcı ID
    const activeFairId = user?.fuarId; // Aktif fuar ID

    const toggleDropdown = () => setDropdownOpen(prevState => !prevState);

    const handleLimitChange = (newLimit) => {
        setLimit(newLimit); // Yeni limiti ayarla
        fetchData(1, newLimit); // Yeni limit ile ilk sayfadan verileri yükle
    };

    const handleSearchChange = (e) => {
        const newSearch = e.target.value;
        setSearch(newSearch); // Arama metnini güncelle
        fetchData(1, limit, newSearch); // İlk sayfadan yeni arama sonuçlarını getir
    };


    const fetchData = async (page = 1, newLimit = limit, newSearch = search) => {
        try {
            setLoading(true);
            const data = await getFairs(page, newLimit, newSearch, userId); // Search backend'e gönderiliyor
            setFairs(data.data);
            setTotalPages(data.totalPages);
            setTotalRecords(data.totalRecords);
            setCurrentPage(page);
        } catch (error) {
            console.error("Error fetching fairs:", error);
        } finally {
            setLoading(false);
        }
    };


    const handleActivateFair = async (fairId) => {
        try {
            await activateFair(userId, fairId); // Backend'de fuarı aktif yap
            const updatedUser = { ...user, fuarId: fairId }; // Yeni fuarId ile user objesini güncelle
            localStorage.setItem("user", JSON.stringify(updatedUser)); // localStorage'ı güncelle
            fetchData(); // Verileri yeniden yükle
            window.location.reload(); // Sayfayı bir kez yenile
        } catch (error) {
            console.error("Error activating fair:", error);
        }
    };    

    const formatFairDate = (startDate, endDate) => {
        const formatter = new Intl.DateTimeFormat(selectedLang === "tr" ? "tr-TR" : "en-US", {
            day: "numeric",
            month: "long",
            year: "numeric",
        });
        return `${formatter.format(new Date(startDate))} - ${formatter.format(new Date(endDate))}`;
    };

    useEffect(() => {
        fetchData(currentPage, limit);
    }, [currentPage, limit]); // currentPage ve limit değiştiğinde verileri yeniden yükle

    const toggleModal = () => {
        setModalOpen(!modalOpen);
        setNewFair({ fair_name: "", start_date: "", end_date: "", logo: "", area: "", active: 1 });
        setEditingId(null);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewFair(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleAddFair = async () => {
        try {
            await addFair(newFair);
            setModalOpen(false);
            setPage(1);
        } catch (error) {
            console.error("Error adding fair:", error);
        }
    };

    const handleEdit = (fair) => {
        setNewFair({
            fair_name: fair.nameTR,
            start_date: fair.start_date,
            end_date: fair.end_date,
            logo: fair.logo,
            area: fair.areaId,
            active: fair.isActive ? 1 : 0,
            fuar_zamani: fair.fuar_zamani || 1, // Varsayılan: Kurulum
        });
        setEditingId(fair.id);
        setModalOpen(true);
    };

    const handleSave = async () => {
        if (editingId) {
            try {
                // Tarih formatını dönüştürüyoruz
                const updatedFair = {
                    ...newFair,
                    start_date: new Date(newFair.start_date).toISOString().split("T")[0], // YYYY-MM-DD
                    end_date: new Date(newFair.end_date).toISOString().split("T")[0],   // YYYY-MM-DD
                };
                await updateFair(editingId, updatedFair);
                setModalOpen(false);
                setEditingId(null);
                setPage(1);
                await fetchData();
            } catch (error) {
                console.error("Error updating fair:", error);
            }
        } else {
            await handleAddFair();
        }
    };
    

    const renderPagination = () => {
        const paginationItems = [];
        const maxPagesToShow = 5; // Maksimum sayfa sayısı

        for (let i = 1; i <= totalPages; i++) {
            if (totalPages > maxPagesToShow) {
                if (i === 1 || i === totalPages || (i >= currentPage - 1 && i <= currentPage + 1)) {
                    paginationItems.push(
                        <PaginationItem key={i} active={i === currentPage}>
                            <PaginationLink onClick={() => fetchData(i)}>{i}</PaginationLink>
                        </PaginationItem>
                    );
                } else if (i === 2 || i === totalPages - 1) {
                    // İlk `...` için kontrol
                    const alreadyHasDots = paginationItems.some((item) => item.props.disabled && item.props.children === "...");
                    if (!alreadyHasDots) {
                        paginationItems.push(
                            <PaginationItem key={`dots-${i}`} disabled>
                                <PaginationLink>...</PaginationLink>
                            </PaginationItem>
                        );
                    }
                }
            } else {
                paginationItems.push(
                    <PaginationItem key={i} active={i === currentPage}>
                        <PaginationLink onClick={() => fetchData(i)}>{i}</PaginationLink>
                    </PaginationItem>
                );
            }
        }

        return (
            <Pagination className="pagination justify-content-center mt-3">
                {paginationItems}
            </Pagination>
        );
    };



    return (
        <div className="page-content">
            <Container fluid>
                <BreadCrumb title="Fuarlar" />
                <Card className="p-3">
                    <Row>
                        <Col md="1">
                            <Dropdown isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)}>
                                <DropdownToggle caret color="primary">
                                    Gösterim: {limit}
                                </DropdownToggle>
                                <DropdownMenu>
                                    {[10, 25, 50, 100].map((option) => (
                                        <DropdownItem key={option} onClick={() => handleLimitChange(option)}>
                                            {option} Gösterim
                                        </DropdownItem>
                                    ))}
                                </DropdownMenu>
                            </Dropdown>
                        </Col>
                        <Col md="3">
                            <Input
                                type="text"
                                placeholder={selectedLang === "tr" ? "Ara..." : "Search..."}
                                value={search}
                                onChange={handleSearchChange} // Arama metni değiştiğinde çağrılır
                            />
                        </Col>
                        <Col md="8" className="text-end">
                            <Button
                                color="primary"
                                onClick={() => setViewMode(viewMode === "grid" ? "list" : "grid")}
                            >
                                <i
                                    className={` bx ${viewMode === "grid" ? "bx-list-ul" : "bx-grid-alt"
                                        }`}
                                ></i>
                                {viewMode === "grid" ? "Liste Modu" : "Grid Modu"}
                            </Button>
                            <Button color="secondary" className="ms-2" onClick={toggleModal}>Yeni Ekle</Button>
                        </Col>
                    </Row>
                </Card>
                {loading ? (
                    <div>Yükleniyor...</div>
                ) : (
                    <>
                        {viewMode === "grid" ? (
                            <Card className="p-3">
                                <Row>
                                    {fairs.map((fair) => (
                                        <Col md="4" className="mb-4" key={fair.id}>
                                            <div className="fair-card position-relative shadow-sm">
                                                <Card
                                                    className="text-center border-0 ribbon-box"
                                                    style={{ overflow: "hidden", borderRadius: "10px" }}
                                                >
                                                    <div
                                                        className={`ribbon ${fair.isActive ? "ribbon-primary" : "ribbon-secondary"
                                                            } round-shape`}
                                                    >
                                                        {fair.isActive ? "Aktif" : "Pasif"}
                                                    </div>
                                                    <div
                                                        className="p-3 bg-light"
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            borderBottom: "1px solid #ddd",
                                                            height: "150px",
                                                        }}
                                                    >
                                                        <img
                                                            src={`https://fuardenetimi.sakagrup.com/repo/fuarlogos/${fair.logo}`}
                                                            alt="Fair Logo"
                                                            style={{
                                                                maxHeight: "100px",
                                                                maxWidth: "100%",
                                                                cursor: "pointer",
                                                            }}
                                                            onClick={() => handleActivateFair(fair.id)}
                                                        />
                                                    </div>
                                                    <div className="p-3">
                                                        <h5>
                                                            <Button
                                                                color="link"
                                                                className="text-decoration-none"
                                                                onClick={() => handleActivateFair(fair.id)}
                                                            >
                                                                {selectedLang === "tr" ? fair.nameTR : fair.nameEN}
                                                            </Button>
                                                        </h5>
                                                        <p className="text-muted">
                                                            {formatFairDate(fair.start_date, fair.end_date)}
                                                        </p>
                                                        <p>{fair.areaName}</p>
                                                    </div>
                                                </Card>
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                            </Card>
                        ) : (
                            <Card className="p-3">
                                {fairs.map((fair) => (
                                    <Card
                                        key={fair.id}
                                        className="mb-3 p-3 ribbon-box position-relative"
                                        style={{
                                            borderRadius: "10px",
                                        }}
                                    >
                                        <div
                                            className={`ribbon ${fair.isActive ? "ribbon-primary" : "ribbon-secondary"
                                                } round-shape`}
                                        >
                                            {fair.isActive ? "Aktif" : "Pasif"}
                                        </div>
                                        <Row className="align-items-center">
                                            {/* Logo */}
                                            <Col md="2" className="text-center">
                                                <img
                                                    src={`https://fuardenetimi.sakagrup.com/repo/fuarlogos/${fair.logo}`}
                                                    alt="Fair Logo"
                                                    style={{
                                                        maxHeight: "80px",
                                                        maxWidth: "100%",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() => handleActivateFair(fair.id)}
                                                />
                                            </Col>

                                            {/* Fair Info */}
                                            <Col md="7">
                                                <h5 style={{ fontWeight: "bold" }}>
                                                    <Button
                                                        color="link"
                                                        className="text-decoration-none p-0"
                                                        onClick={() => handleActivateFair(fair.id)}
                                                        style={{ fontSize: "18px" }}
                                                    >
                                                        {selectedLang === "tr" ? fair.nameTR : fair.nameEN}
                                                    </Button>
                                                </h5>
                                                <p className="text-muted" style={{ marginBottom: "5px" }}>
                                                    {formatFairDate(fair.start_date, fair.end_date)}
                                                </p>
                                                <p>{fair.areaName}</p>
                                            </Col>

                                            {/* Actions */}
                                            <Col md="3" className="text-end">
                                                <Button
                                                    color="primary"
                                                    onClick={() => handleEdit(fair)}
                                                    className="me-2"
                                                >
                                                    Düzenle
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Card>
                                ))}
                            </Card>
                        )}


                        <Pagination className="pagination justify-content-center">
                            {renderPagination()}
                        </Pagination>
                    </>
                )}

                <Modal isOpen={modalOpen} toggle={toggleModal}>
                    <ModalHeader toggle={toggleModal}>{editingId ? "Fuarı Düzenle" : "Yeni Fuar Ekle"}</ModalHeader>
                    <ModalBody>
                        <Row className="mb-3">
                            <Col md="12">
                                <Input
                                    type="text"
                                    name="fair_name"
                                    placeholder="Fuar Adı"
                                    value={newFair.fair_name}
                                    onChange={handleInputChange}
                                />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md="6">
                                <Input
                                    type="date"
                                    name="start_date"
                                    value={newFair.start_date}
                                    onChange={handleInputChange}
                                />
                            </Col>
                            <Col md="6">
                                <Input
                                    type="date"
                                    name="end_date"
                                    value={newFair.end_date}
                                    onChange={handleInputChange}
                                />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md="12">
                                <Input
                                    type="text"
                                    name="logo"
                                    placeholder="Logo URL"
                                    value={newFair.logo}
                                    onChange={handleInputChange}
                                />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md="12">
                                <Input
                                    type="text"
                                    name="area"
                                    placeholder="Fuar Alanı"
                                    value={newFair.area}
                                    onChange={handleInputChange}
                                />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md="12">
                                <Input
                                    type="select"
                                    name="fuar_zamani"
                                    value={newFair.fuar_zamani}
                                    onChange={handleInputChange}
                                >
                                    <option value={1}>Kurulum</option>
                                    <option value={2}>Fuar</option>
                                    <option value={3}>Söküm</option>
                                </Input>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12">
                                <Input
                                    type="select"
                                    name="active"
                                    value={newFair.active}
                                    onChange={handleInputChange}
                                >
                                    <option value={1}>Aktif</option>
                                    <option value={0}>Pasif</option>
                                </Input>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={handleSave}>
                            {editingId ? "Güncelle" : "Ekle"}
                        </Button>
                        <Button color="secondary" onClick={toggleModal}>İptal</Button>
                    </ModalFooter>
                </Modal>
            </Container>
        </div>
    );
};

export default Fairs;
