import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, Row, Col, Card, Button,Badge } from "reactstrap";
import { useDispatch } from "react-redux";

import logoSm from "../assets/images/logo-sm.png";
import logoDark from "../assets/images/logo-dark.png";
import logoLight from "../assets/images/logo-light.png";

import ProfileDropdown from '../Components/Common/ProfileDropdown';
import LightDark from '../Components/Common/LightDark';
import LanguageDropdown from '../Components/Common/LanguageDropdown';
import { LanguageContext } from '../Components/Contexts/LanguageContext';
import MainContext from '../Components/Contexts/MainContext'; // MainContext
import FairContext from '../Components/Contexts/Management/FairContext'; // FairContext

const Header = ({ onChangeLayoutMode, layoutModeType, headerClass }) => {
    const dispatch = useDispatch();
    const { activeFairId } = useContext(MainContext); // Active Fair ID
    const { getFairs, activateFair } = useContext(FairContext); // Fair işlemleri
    const { selectedLang, setSelectedLang } = useContext(LanguageContext);
    const [activeFairLogo, setActiveFairLogo] = useState(null); // Aktif fuarın logosu
    const [modalOpen, setModalOpen] = useState(false); // Modal state
    const [fairs, setFairs] = useState([]); // İlk 5 fuar

    const user = JSON.parse(localStorage.getItem("user"));
    const userId = user?.id; // Kullanıcı ID

    // Fuarları Fetch Et
    useEffect(() => {
        const fetchFairsAndSetLogo = async () => {
            try {
                const data = await getFairs(1, 200, "", null); // Limit 200 ile tüm fuarları çek
                const fair = data.data.find(fair => fair.id === activeFairId); // ActiveFairId ile eşleşen fuarı bul
                if (fair) {
                    setActiveFairLogo(fair.logo); // Logoyu kaydet
                }
                setFairs(data.data.slice(0, 5)); // İlk 5 fuarı al
            } catch (error) {
                console.error("Error fetching fairs:", error);
            }
        };

        if (activeFairId) {
            fetchFairsAndSetLogo();
        }
    }, [activeFairId, getFairs]);

    // Modal Aç/Kapat
    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };

    const toogleMenuBtn = () => {
        const hamburgerIcon = document.querySelector(".hamburger-icon");
        if (hamburgerIcon) {
            hamburgerIcon.classList.toggle("open");
        } else {
            console.warn("Hamburger icon bulunamadı. DOM yapısını kontrol edin.");
        }

        const windowSize = document.documentElement.clientWidth;
        dispatch(changeSidebarVisibility("show"));

        if (document.documentElement.getAttribute("data-layout") === "horizontal") {
            document.body.classList.toggle("menu");
        }

        if (
            sidebarVisibilitytype === "show" &&
            (document.documentElement.getAttribute("data-layout") === "vertical" ||
                document.documentElement.getAttribute("data-layout") === "semibox")
        ) {
            if (windowSize < 1025 && windowSize > 767) {
                document.body.classList.remove("vertical-sidebar-enable");
                document.documentElement.setAttribute(
                    "data-sidebar-size",
                    document.documentElement.getAttribute("data-sidebar-size") === "sm" ? "" : "sm"
                );
            } else if (windowSize > 1025) {
                document.body.classList.remove("vertical-sidebar-enable");
                document.documentElement.setAttribute(
                    "data-sidebar-size",
                    document.documentElement.getAttribute("data-sidebar-size") === "lg" ? "sm" : "lg"
                );
            } else if (windowSize <= 767) {
                document.body.classList.add("vertical-sidebar-enable");
                document.documentElement.setAttribute("data-sidebar-size", "lg");
            }
        }

        if (document.documentElement.getAttribute("data-layout") === "twocolumn") {
            document.body.classList.toggle("twocolumn-panel");
        }
    };


    // Fuar Aktif Etme
    const handleActivateFair = async (fairId) => {
        try {
            await activateFair(userId, fairId); // Backend'de fuarı aktif yap
            const updatedUser = { ...user, fuarId: fairId }; // Yeni fuarId ile user objesini güncelle
            localStorage.setItem("user", JSON.stringify(updatedUser)); // localStorage'ı güncelle
            window.location.reload(); // Sayfayı bir kez yenile
        } catch (error) {
            console.error("Error activating fair:", error);
        }
    };

    const formatFairDate = (startDate, endDate) => {
        const formatter = new Intl.DateTimeFormat(selectedLang === "tr" ? "tr-TR" : "en-US", {
            day: "numeric",
            month: "long",
            year: "numeric",
        });
        return `${formatter.format(new Date(startDate))} - ${formatter.format(new Date(endDate))}`;
    };
    

    return (
        <React.Fragment>
            <header id="page-topbar" className={headerClass}>
                <div className="layout-width">
                    <div className="navbar-header">
                        <div className="d-flex align-items-center">
                            {/* Sol tarafta aktif fuar logosu */}
                            <button
                                onClick={toogleMenuBtn}
                                type="button"
                                className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
                                id="topnav-hamburger-icon">
                                <span className="hamburger-icon">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </span>
                            </button>
                            <div className="d-flex justify-content-end align-items-center">
                                {activeFairLogo ? (
                                    <img
                                        src={`https://fuardenetimi.sakagrup.com/repo/fuarlogos/${activeFairLogo}`}
                                        alt="Aktif Fuar Logosu"
                                        style={{
                                            borderRadius: "5px",
                                            maxHeight: "50px",
                                            maxWidth: "100px",
                                            marginRight: "20px",
                                            cursor: "pointer", // Tıklanabilirlik
                                        }}
                                        onClick={toggleModal} // Logo tıklandığında modal açılır
                                    />
                                ) : null}
                            </div>

                            <div className="navbar-brand-box horizontal-logo">
                                <Link to="/" className="logo logo-dark">
                                    <span className="logo-sm">
                                        <img src={logoSm} alt="" height="30" />
                                    </span>
                                    <span className="logo-lg">
                                        <img src={logoDark} alt="" height="17" />
                                    </span>
                                </Link>

                                <Link to="/" className="logo logo-light">
                                    <span className="logo-sm">
                                        <img src={logoSm} alt="" height="22" />
                                    </span>
                                    <span className="logo-lg">
                                        <img src={logoLight} alt="" height="17" />
                                    </span>
                                </Link>
                            </div>
                        </div>

                        <div className="d-flex align-items-center">
                            <LanguageDropdown selectedLang={selectedLang} setSelectedLang={setSelectedLang} />

                            <LightDark
                                layoutMode={layoutModeType}
                                onChangeLayoutMode={onChangeLayoutMode}
                            />

                            <ProfileDropdown />
                        </div>
                    </div>
                </div>
            </header>

            {/* Modal */}
            <Modal isOpen={modalOpen} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>
                    {selectedLang === "tr" ? "Fuar Seçimi" : "Select Fair"}
                </ModalHeader>
                <ModalBody>
                    {fairs.map((fair) => (
                        <Card
                            key={fair.id}
                            className="mb-3 p-3 ribbon-box position-relative"
                            style={{
                                borderRadius: "10px",
                                border: fair.id === activeFairId ? "2px solid #007bff" : "1px solid #ddd",
                                cursor: "pointer",
                            }}
                            onClick={() => handleActivateFair(fair.id)} // Fuar seçim fonksiyonu
                        >
                            <Row className="align-items-center">
                                {/* Logo */}
                                <Col md="2" className="text-center">
                                    <img
                                        src={`https://fuardenetimi.sakagrup.com/repo/fuarlogos/${fair.logo}`}
                                        alt={fair.nameTR || fair.nameEN}
                                        style={{
                                            maxHeight: "80px",
                                            maxWidth: "100%",
                                            borderRadius: "5px",
                                        }}
                                    />
                                </Col>

                                {/* Fuar Bilgileri */}
                                <Col md="7">
                                    <h5 style={{ fontWeight: "bold" }}>
                                        {selectedLang === "tr" ? fair.nameTR : fair.nameEN}
                                    </h5>
                                    <p className="text-muted" style={{ marginBottom: "5px" }}>
                                        {formatFairDate(fair.start_date, fair.end_date)}
                                    </p>
                                    <p>{fair.areaName}</p>
                                </Col>

                                {/* Aktif Et */}
                                <Col md="3" className="text-end">
                                    {fair.id === activeFairId ? (
                                        <Badge color="success">{selectedLang === "tr" ? "Aktif" : "Active"}</Badge>
                                    ) : (
                                        <Button color="primary">
                                            {selectedLang === "tr" ? "Aktif Et" : "Activate"}
                                        </Button>
                                    )}
                                </Col>
                            </Row>
                        </Card>
                    ))}
                </ModalBody>
            </Modal>

        </React.Fragment>
    );
};

export default Header;
