import React, { useEffect, useContext } from "react";
import { Container, Row, Col, Card, CardBody, Input, FormGroup, Label } from "reactstrap";
import BetterStandsContext from "../../../../../Components/Contexts/Management/BetterStandsContext";

const Docs = () => {
    const {
        fetchUncategorizedPhotos,
        fetchAllUncategorizedPhotos,
        filteredPhotos,
    } = useContext(BetterStandsContext);

    const userId = localStorage.getItem("userId");
    const groupInfo = JSON.parse(localStorage.getItem("groupInfo"));


    useEffect(() => {
        if (groupInfo && (groupInfo.id === 3 || groupInfo.id === 2)) {
            fetchAllUncategorizedPhotos();
        } else if (userId) {
            fetchUncategorizedPhotos(userId);
        }
    }, []);

    return (
        <div className="page-content">
            <Container fluid>
                <Row>
                    {filteredPhotos.length > 0 ? (
                        filteredPhotos.map((photo) => (
                            <Col key={photo.stand_report_file_id} md={3} className="mb-3">
                                <Card className="shadow">
                                    <Row>
                                        <Col md={3}>
                                            <img
                                                style={{ maxHeight: "400px", width: "100%", objectFit: "cover" }}
                                                src={`https://e.sakagrup.com${photo.stand_report_file_link}`}
                                                alt={photo.stand_report_file_name}
                                                className="img-fluid"
                                            />
                                        </Col>
                                        <Col md={9}>
                                            <CardBody>
                                                <p><strong>Görsel Adı:</strong> {photo.stand_report_file_name}</p>
                                                <p><strong>Fuar Zamanı:</strong> {photo.fuar_zamani === "fuar_oncesi" ? "Fuar Öncesi" : "Fuar Sonrası"}</p>
                                                <p><strong>Kullanıcı:</strong> {photo.user_name} {photo.user_surname}</p>
                                            </CardBody>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        ))
                    ) : (
                        <Col>
                            <p>Eşlenmemiş görsel bulunamadı.</p>
                        </Col>
                    )}
                </Row>
            </Container>
        </div>
    );
};

export default Docs;
