import React, { useEffect } from "react";
import { Container } from "reactstrap";

const Dashboard = () => {

  document.title = "Ana Sayfa | FDS";

  return (
    <div className="page-content">
      <Container fluid>

      </Container>
    </div>
  );
};

export default Dashboard;
