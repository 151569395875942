import React, { useContext, useEffect, useState, useCallback } from 'react';
import { Container, Table, Button, Badge, Row, Col, Input, Modal, ModalHeader, ModalBody, ModalFooter, Pagination, PaginationItem, PaginationLink, Card, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import BreadCrumb from "../../../../../Components/Common/BreadCrumb";
import EmailLogContext from "../../../../../Components/Contexts/Management/EmailLogContext";
import EmailTemplateContext from "../../../../../Components/Contexts/Management/EmailTemplateContext";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CreatableSelect from 'react-select/creatable';

import {
    ClassicEditor,
    AccessibilityHelp,
    Alignment,
    Autoformat,
    AutoImage,
    AutoLink,
    Autosave,
    Base64UploadAdapter,
    BlockQuote,
    Bold,
    Code,
    CodeBlock,
    Essentials,
    FindAndReplace,
    FontBackgroundColor,
    FontColor,
    FontFamily,
    FontSize,
    FullPage,
    GeneralHtmlSupport,
    Heading,
    Highlight,
    HorizontalLine,
    HtmlComment,
    HtmlEmbed,
    ImageBlock,
    ImageCaption,
    ImageInline,
    ImageInsert,
    ImageInsertViaUrl,
    ImageResize,
    ImageStyle,
    ImageTextAlternative,
    ImageToolbar,
    ImageUpload,
    Indent,
    IndentBlock,
    Italic,
    Link,
    LinkImage,
    List,
    ListProperties,
    Markdown,
    MediaEmbed,
    Mention,
    PageBreak,
    Paragraph,
    PasteFromMarkdownExperimental,
    PasteFromOffice,
    RemoveFormat,
    SelectAll,
    ShowBlocks,
    SourceEditing,
    SpecialCharacters,
    SpecialCharactersArrows,
    SpecialCharactersCurrency,
    SpecialCharactersEssentials,
    SpecialCharactersLatin,
    SpecialCharactersMathematical,
    SpecialCharactersText,
    Strikethrough,
    Style,
    Subscript,
    Superscript,
    TextPartLanguage,
    TextTransformation,
    Title,
    TodoList,
    Underline,
    Undo
} from 'ckeditor5';

const Logs = () => {
    document.title = "Mail Kayıtları | FDS";

    const { emailLogs, fetchEmailLogs, getEmailLogById, searchUsers, sendEmail, systemEmails, getSystemEmails, loading } = useContext(EmailLogContext);
    const { getEmailTemplates, saveOrUpdateTemplate } = useContext(EmailTemplateContext);
    const [emailTemplates, setEmailTemplates] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [selectedTemplate, setSelectedTemplate] = useState();
    const [search, setSearch] = useState("");
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedLog, setSelectedLog] = useState(null);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [sendModalOpen, setSendModalOpen] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const userId = localStorage.getItem('userId');
    const [userOptions, setUserOptions] = useState([]);
    const [newEmail, setNewEmail] = useState({
        templateId: "",
        recipients: "",
        ccRecipients: [], // Boş dizi olarak ayarlandı
        sender: "",
        subject: "",
        content: "",
        templateName: ""
    });


    const editorConfig = {
        toolbar: {
            items: [
                'undo',
                'redo',
                '|',
                'sourceEditing',
                'showBlocks',
                'findAndReplace',
                'textPartLanguage',
                '|',
                'heading',
                'style',
                '|',
                'fontSize',
                'fontFamily',
                'fontColor',
                'fontBackgroundColor',
                '|',
                'bold',
                'italic',
                'underline',
                'strikethrough',
                'subscript',
                'superscript',
                'code',
                'removeFormat',
                '|',
                'specialCharacters',
                'horizontalLine',
                'pageBreak',
                'link',
                'insertImage',
                'mediaEmbed',
                'highlight',
                'blockQuote',
                'codeBlock',
                'htmlEmbed',
                '|',
                'alignment',
                '|',
                'bulletedList',
                'numberedList',
                'todoList',
                'outdent',
                'indent'
            ],
            shouldNotGroupWhenFull: true
        },
        plugins: [
            AccessibilityHelp,
            Alignment,
            Autoformat,
            AutoImage,
            AutoLink,
            Autosave,
            Base64UploadAdapter,
            BlockQuote,
            Bold,
            Code,
            CodeBlock,
            Essentials,
            FindAndReplace,
            FontBackgroundColor,
            FontColor,
            FontFamily,
            FontSize,
            FullPage,
            GeneralHtmlSupport,
            Heading,
            Highlight,
            HorizontalLine,
            HtmlComment,
            HtmlEmbed,
            ImageBlock,
            ImageCaption,
            ImageInline,
            ImageInsert,
            ImageInsertViaUrl,
            ImageResize,
            ImageStyle,
            ImageTextAlternative,
            ImageToolbar,
            ImageUpload,
            Indent,
            IndentBlock,
            Italic,
            Link,
            LinkImage,
            List,
            ListProperties,
            Markdown,
            MediaEmbed,
            Mention,
            PageBreak,
            Paragraph,
            PasteFromMarkdownExperimental,
            PasteFromOffice,
            RemoveFormat,
            SelectAll,
            ShowBlocks,
            SourceEditing,
            SpecialCharacters,
            SpecialCharactersArrows,
            SpecialCharactersCurrency,
            SpecialCharactersEssentials,
            SpecialCharactersLatin,
            SpecialCharactersMathematical,
            SpecialCharactersText,
            Strikethrough,
            Style,
            Subscript,
            Superscript,
            TextPartLanguage,
            TextTransformation,
            Title,
            TodoList,
            Underline,
            Undo
        ],
        fontFamily: {
            supportAllValues: true
        },
        fontSize: {
            options: ['default', 8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48, 72],
            supportAllValues: true
        },
        heading: {
            options: [
                {
                    model: 'paragraph',
                    title: 'Paragraph',
                    class: 'ck-heading_paragraph'
                },
                {
                    model: 'heading1',
                    view: 'h1',
                    title: 'Heading 1',
                    class: 'ck-heading_heading1'
                },
                {
                    model: 'heading2',
                    view: 'h2',
                    title: 'Heading 2',
                    class: 'ck-heading_heading2'
                },
                {
                    model: 'heading3',
                    view: 'h3',
                    title: 'Heading 3',
                    class: 'ck-heading_heading3'
                },
                {
                    model: 'heading4',
                    view: 'h4',
                    title: 'Heading 4',
                    class: 'ck-heading_heading4'
                },
                {
                    model: 'heading5',
                    view: 'h5',
                    title: 'Heading 5',
                    class: 'ck-heading_heading5'
                },
                {
                    model: 'heading6',
                    view: 'h6',
                    title: 'Heading 6',
                    class: 'ck-heading_heading6'
                }
            ]
        },
        htmlSupport: {
            allow: [
                {
                    name: /^.*$/,
                    styles: true,
                    attributes: true,
                    classes: true
                }
            ]
        },
        image: {
            toolbar: [
                'toggleImageCaption',
                'imageTextAlternative',
                '|',
                'imageStyle:inline',
                'imageStyle:wrapText',
                'imageStyle:breakText',
                '|',
                'resizeImage'
            ]
        },
        language: 'tr',
        link: {
            addTargetToExternalLinks: true,
            defaultProtocol: 'https://',
            decorators: {
                toggleDownloadable: {
                    mode: 'manual',
                    label: 'Downloadable',
                    attributes: {
                        download: 'file'
                    }
                }
            }
        },
        list: {
            properties: {
                styles: true,
                startIndex: true,
                reversed: true
            }
        },
        mention: {
            feeds: [
                {
                    marker: '@',
                    feed: [
                    ]
                }
            ]
        },
        placeholder: 'Type or paste your content here!',
        style: {
            definitions: [
                {
                    name: 'Article category',
                    element: 'h3',
                    classes: ['category']
                },
                {
                    name: 'Title',
                    element: 'h2',
                    classes: ['document-title']
                },
                {
                    name: 'Subtitle',
                    element: 'h3',
                    classes: ['document-subtitle']
                },
                {
                    name: 'Info box',
                    element: 'p',
                    classes: ['info-box']
                },
                {
                    name: 'Side quote',
                    element: 'blockquote',
                    classes: ['side-quote']
                },
                {
                    name: 'Marker',
                    element: 'span',
                    classes: ['marker']
                },
                {
                    name: 'Spoiler',
                    element: 'span',
                    classes: ['spoiler']
                },
                {
                    name: 'Code (dark)',
                    element: 'pre',
                    classes: ['fancy-code', 'fancy-code-dark']
                },
                {
                    name: 'Code (bright)',
                    element: 'pre',
                    classes: ['fancy-code', 'fancy-code-bright']
                }
            ]
        },
    };

    const toggleSendModal = () => {
        setSendModalOpen(!sendModalOpen);

        if (!sendModalOpen) {
            fetchTemplates();
            setNewEmail({ ...newEmail, templateId: "", content: "" });
        }
    };

    useEffect(() => {
        if (sendModalOpen && newEmail.templateId) {
            setNewEmail({ ...newEmail, templateId: newEmail.templateId });
        }
    }, [sendModalOpen]);

    const fetchTemplates = async () => {
        try {
            const data = await getEmailTemplates(1, 200);
            if (data && data.data) {
                console.log("Fetched Email Templates:", data.data);
                setEmailTemplates(data.data);
            } else {
                console.log("No data found for templates");
            }
        } catch (error) {
            console.error("Error fetching templates:", error);
        }
    };

    useEffect(() => {
        getSystemEmails();
    }, [getSystemEmails]);

    const handleTemplateChange = (e) => {
        const selectedTemplateId = parseInt(e.target.value, 10);
        const selectedTemplate = emailTemplates.find(
            (template) => template.email_template_id === selectedTemplateId
        );
    
        setNewEmail((prevState) => ({
            ...prevState,
            templateId: selectedTemplateId,
            konu: selectedTemplate ? selectedTemplate.email_template_title : "",
            content: selectedTemplate ? selectedTemplate.email_template_content : ""
        }));
    
        setSelectedTemplate(selectedTemplateId); // Seçilen şablonun ID'sini kaydet
    
        // Log ekleyelim
        console.log("Şablon Seçildi:", {
            selectedTemplateId,
            title: selectedTemplate ? selectedTemplate.email_template_title : "",
            content: selectedTemplate ? selectedTemplate.email_template_content : ""
        });
    };
    

    // Şablon değiştiğinde konu otomatik olarak güncellenir
    useEffect(() => {
        if (newEmail.templateId) {
            const selectedTemplate = emailTemplates.find(
                (template) => template.email_template_id === newEmail.templateId
            );
            setNewEmail((prevState) => ({
                ...prevState,
                konu: selectedTemplate ? selectedTemplate.email_template_title : ""
            }));
        }
    }, [newEmail.templateId, emailTemplates]); // Hem templateId hem de emailTemplates değişikliklerini izler


    useEffect(() => {
        fetchEmailLogs(page, limit, search);
    }, [page, limit, search]);

    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

    const handleDetail = async (id) => {
        const log = await getEmailLogById(id);
        setSelectedLog(log);
        setModalOpen(true);
    };

    const handleSendEmail = async () => {
        if (!newEmail.konu || !newEmail.content || (selectedUsers.length === 0 && newEmail.ccRecipients.length === 0)) {
            return;
        }

        try {
            const userId = localStorage.getItem('userId');
            const senderId = systemEmails.find(email => email.system_email_value === newEmail.sender)?.system_email_id;
            
            await sendEmail({
                senderId, // Eğer userId yoksa bu değeri kullanacak
                userId,   // userId mevcutsa bu değeri kullanacak
                recipients: selectedUsers.map(user => user.value),
                ccRecipients: newEmail.ccRecipients,
                subject: newEmail.konu,
                content: newEmail.content
            });
            
            fetchEmailLogs(page, limit, search)
            setSendModalOpen(false); // Modalı kapat
        } catch (error) {
            console.error("E-posta gönderilirken hata oluştu:", error);
        }
    };

    const renderPagination = () => {
        const totalPages = emailLogs.totalPages || 1;
        const paginationItems = [];
        const maxPagesToShow = 5;

        for (let i = 1; i <= totalPages; i++) {
            if (totalPages > maxPagesToShow) {
                if (i === 1 || i === totalPages || (i >= page - 1 && i <= page + 1)) {
                    paginationItems.push(
                        <PaginationItem key={i} active={i === page}>
                            <PaginationLink onClick={() => setPage(i)}>{i}</PaginationLink>
                        </PaginationItem>
                    );
                } else if (i === 2 || i === totalPages - 1) {
                    paginationItems.push(
                        <PaginationItem key={i} disabled>
                            <PaginationLink>...</PaginationLink>
                        </PaginationItem>
                    );
                }
            } else {
                paginationItems.push(
                    <PaginationItem key={i} active={i === page}>
                        <PaginationLink onClick={() => setPage(i)}>{i}</PaginationLink>
                    </PaginationItem>
                );
            }
        }
        return paginationItems;
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { day: 'numeric', month: 'long', year: 'numeric' };
        const timeOptions = { hour: '2-digit', minute: '2-digit' };
        return `${date.toLocaleDateString('tr-TR', options)} - ${date.toLocaleTimeString('tr-TR', timeOptions)}`;
    };

    const handleUserSearch = async (inputValue = "") => {
        if (inputValue.trim() === "") return; // Boş arama yapmayı önle
        const users = await searchUsers(inputValue);
        const options = users.map(user => ({
            label: `${user.user_name} ${user.user_surname} (${user.user_email})`,
            value: user.user_id
        }));
        setUserOptions(options);
    };

    const handleSaveTemplate = async () => {
        try {
            const templateTitle = newEmail.konu; // Modal'daki konu inputundan al
            const templateContent = newEmail.content; // Modal'daki içerik editoründen al
            const templateId = selectedTemplate; // Eğer bir şablon seçiliyse onun ID'sini al
    
            if (!templateTitle || !templateContent) {
                toast.warn("Kaydetmek için geçerli bir başlık ve içerik girilmelidir.", { position: "top-right", autoClose: 5000 });
                return;
            }
    
            const response = await saveOrUpdateTemplate(templateId, templateTitle, templateContent);
            if (response.templateId || response.message) {
                toast.success(templateId ? "Tema başarıyla güncellendi." : "Yeni tema başarıyla kaydedildi.", { position: "top-right", autoClose: 5000 });
                setSelectedTemplate(response.templateId); // Eğer yeni oluşturulmuşsa ID'yi güncelle
            } else {
                throw new Error("Yanıt verisi eksik.");
            }
        } catch (error) {
            console.error('Tema kaydedilirken bir hata oluştu:', error);
            toast.error('Tema kaydedilirken hata oluştu.', { position: "top-right", autoClose: 5000 });
        }
    };

    return (
        <div className="page-content">
            <Container fluid>
                <BreadCrumb title="Kayıtlar" pageTitle="Emails" />
                <Card className="p-3">
                    <Row>
                        <Col md="1">
                            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                                <DropdownToggle caret>
                                    Gösterim: {limit}
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={() => setLimit(10)}>10</DropdownItem>
                                    <DropdownItem onClick={() => setLimit(25)}>25</DropdownItem>
                                    <DropdownItem onClick={() => setLimit(50)}>50</DropdownItem>
                                    <DropdownItem onClick={() => setLimit(100)}>100</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </Col>
                        <Col md="3">
                            <Input
                                type="text"
                                placeholder="Arama..."
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </Col>
                        <Col md="8" className="text-end">
                            <Button color="secondary" onClick={toggleSendModal}>Mail Gönder</Button>
                        </Col>
                    </Row>
                </Card>
                <Card className="pt-3 pe-3 ps-3">
                    {loading ? (
                        <div>Yükleniyor...</div>
                    ) : (
                        <Table responsive bordered striped>
                            <thead>
                                <tr style={{ height: '40px' }}>
                                    <th>Başlık</th>
                                    <th>Konu</th>
                                    <th>Gönderen</th>
                                    <th>Alıcı</th>
                                    <th>Gönderim Tarihi</th>
                                    <th>Durum</th>
                                    <th>İşlem</th>
                                </tr>
                            </thead>
                            <tbody>
                                {emailLogs.data?.map(log => (
                                    <tr style={{ height: '65px' }} key={log.mail_log_id}>
                                        <td>{log.mail_log_title}</td>
                                        <td>{log.mail_log_subject}</td>
                                        <td>
                                            {log.mail_log_from_email}
                                            <br />
                                            <a href="#" className="text-primary">
                                                {log.sender_name} {log.sender_surname}
                                            </a>
                                        </td>
                                        <td>
                                            {log.mail_log_to_email}
                                            <br />
                                            <a href="#" className="text-primary">
                                                {log.receiver_name} {log.receiver_surname}
                                            </a>
                                        </td>
                                        <td>{formatDate(log.mail_log_insertdate)}</td>
                                        <td>
                                            <Badge color={log.is_sent ? "secondary" : "warning"}>
                                                {log.is_sent ? "Gönderildi" : "Başarısız"}
                                            </Badge>
                                        </td>
                                        <td>
                                            <Button color="warning" onClick={() => handleDetail(log.mail_log_id)}>Detay</Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    )}
                </Card>
                <Pagination className="pagination justify-content-center mt-3">
                    {renderPagination()}
                </Pagination>

                <Modal isOpen={modalOpen} toggle={() => setModalOpen(false)} size="lg">
                    <ModalHeader toggle={() => setModalOpen(false)}>Mail Detayı</ModalHeader>
                    <ModalBody>
                        {selectedLog ? (
                            <div dangerouslySetInnerHTML={{ __html: selectedLog.mail_log_message }} />
                        ) : (
                            <p>Yükleniyor...</p>
                        )}
                    </ModalBody>
                </Modal>

                <Modal isOpen={sendModalOpen} toggle={toggleSendModal} size="lg">
                    <ModalHeader toggle={toggleSendModal}>Mail Gönder</ModalHeader>
                    <ModalBody>
                        <Row className="mb-3">
                            <Col md="12">
                                <label>Email Temaları</label>
                                <Input
                                    type="select"
                                    value={newEmail.email_template_title}
                                    onChange={handleTemplateChange}
                                >
                                    <option value="">Şablon Seçin</option>
                                    {emailTemplates.map((template) => (
                                        <option key={`template-${template.email_template_id}`} value={template.email_template_id}>
                                            {template.email_template_title || "Başlık Yok"}
                                        </option>
                                    ))}
                                </Input>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md="12">
                                <label>Alıcılar (Sistem Kullanıcıları)</label>
                                <Select
                                    isMulti
                                    options={userOptions}
                                    onInputChange={(inputValue) => {
                                        console.log("Input Change Değeri:", inputValue); // Bu satırı ekleyerek değer güncelleniyor mu kontrol edin
                                        handleUserSearch(inputValue);
                                    }}
                                    onChange={(selected) => setSelectedUsers(selected)}
                                    placeholder="Kullanıcı arayın..."
                                    className="data-choices form-control"
                                    isClearable
                                    styles={{
                                        color: 'white',
                                        multiValueLabel: (base) => ({
                                            ...base,
                                            color: 'white', // Seçili etiketlerin metnini beyaz yap
                                        })
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md="12">
                                <label>Alıcılar (Sistemde Kaydı Olmayanlar)</label>
                                <CreatableSelect
                                    isMulti
                                    value={newEmail.ccRecipients.map((email) => ({ label: email, value: email }))}
                                    onChange={(selectedOptions) => {
                                        const emails = selectedOptions ? selectedOptions.map((option) => option.value) : [];
                                        setNewEmail({ ...newEmail, ccRecipients: emails });
                                    }}
                                    placeholder="E-posta ekleyin ve enter’a basın"
                                    formatCreateLabel={(inputValue) => `"${inputValue}" eklensin`}
                                    noOptionsMessage={() => "Yazıp enter ile ekleyin"}
                                    className="data-choices form-control"
                                    styles={{
                                        color: 'white',
                                        multiValueLabel: (base) => ({
                                            ...base,
                                            color: 'white', // Seçili etiketlerin metnini beyaz yap
                                        })
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md="12">
                                <label>Kimden</label>
                                <Input
                                    type="select"
                                    value={newEmail.sender}
                                    onChange={(e) => setNewEmail({ ...newEmail, sender: e.target.value })}
                                >
                                    <option value="">Kimden Seçin</option>
                                    {systemEmails.map((email) => (
                                        <option key={email.system_email_id} value={email.system_email_value}>
                                            {email.system_email_title} [{email.system_email_value}]
                                        </option>
                                    ))}
                                </Input>

                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md="12">
                                <label>Konu</label>
                                <Input
                                    type="text"
                                    placeholder="Konu"
                                    value={newEmail.konu} // "konu" olarak bağlandı
                                    key={newEmail.konu} // Her güncellemede render olması sağlanıyor
                                    onChange={(e) => setNewEmail({ ...newEmail, konu: e.target.value })}
                                />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md="12">
                                <label>Mail İçeriği</label>
                                <CKEditor
                                    editor={ClassicEditor}
                                    config={editorConfig}
                                    data={newEmail.content}
                                    onChange={(event, editor) => setNewEmail({ ...newEmail, content: editor.getData() })}
                                />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md="12" className="text-end">
                                <Button color="primary" size="sm" onClick={handleSaveTemplate}>Temayı Kaydet</Button>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={handleSendEmail}>Gönder</Button>
                        <Button color="secondary" onClick={toggleSendModal}>İptal</Button>
                    </ModalFooter>
                </Modal>
                <ToastContainer/>
            </Container>
        </div>
    );
};

export default Logs;
