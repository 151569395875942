import React, { createContext, useState, useCallback } from 'react';
import axios from 'axios';

const MailSettingsContext = createContext();

export const MailSettingsProvider = ({ children }) => {
    const [mailSettings, setMailSettings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [userOptions, setUserOptions] = useState([]);
    
    const apiurl = "https://e.sakagrup.com";
    const apiport = "";
    const api = {
        mailSettings: `${apiurl}:${apiport}/api/mail-settings`
    };

    const fetchMailSettings = useCallback(async (page = 1, limit = 10, search = '') => {
        setLoading(true);
        try {
            const response = await axios.get(api.mailSettings, {
                params: { page, limit, search }
            });
            setMailSettings(response.data);
        } catch (error) {
            console.error('Error fetching mail settings:', error);
        } finally {
            setLoading(false);
        }
    }, []);

    const addMailSetting = async (newMailSetting) => {
        try {
            const response = await axios.post(api.mailSettings, newMailSetting);
            
            // Önce mevcut tabloyu güncellemek için mail settings verilerini yeniden çek
            fetchMailSettings();
    
            // Alternatif olarak eklenen öğeyi tabloya doğrudan ekleyebilirsiniz
            setMailSettings((prevSettings) => 
                Array.isArray(prevSettings)
                    ? [...prevSettings, response.data]
                    : [response.data]
            );
        } catch (error) {
            console.error('Error adding mail setting:', error);
        }
    };
    

    const updateMailSetting = async (id, updatedMailSetting) => {
        try {
            console.log("Updating mail setting with data:", updatedMailSetting); // Güncellenen veriyi kontrol edin
            await axios.put(`${api.mailSettings}/${id}`, updatedMailSetting);
            fetchMailSettings();
        } catch (error) {
            console.error('Error updating mail setting:', error);
        }
    };


    const deleteMailSetting = async (id) => {
        try {
            await axios.delete(`${api.mailSettings}/${id}`);
            setMailSettings((prevSettings) => {
                if (Array.isArray(prevSettings.data)) {
                    return {
                        ...prevSettings,
                        data: prevSettings.data.filter((setting) => setting.system_email_id !== id),
                    };
                } else {
                    console.error("prevSettings is not an array:", prevSettings);
                    return prevSettings; // hata durumunda orijinal değeri koru
                }
            });
        } catch (error) {
            console.error('Error deleting mail setting:', error);
        }
    };

    const searchMailUsers = useCallback(async (searchTerm = "") => {
        if (!searchTerm || typeof searchTerm !== "string") return []; // Geçersiz girişler için boş array döndür

        try {
            const response = await axios.get(`${apiurl}:${apiport}/api/users/search`, { params: { search: searchTerm } });
            return response.data || []; // Eğer data null veya undefined ise boş array döndür
        } catch (error) {
            console.error("Kullanıcı ararken hata oluştu:", error);
            return []; // Hata durumunda boş array döndür
        }
    }, []);


    return (
        <MailSettingsContext.Provider value={{
            mailSettings,
            fetchMailSettings,
            addMailSetting,
            updateMailSetting,
            deleteMailSetting,
            searchMailUsers,
            loading
        }}>
            {children}
        </MailSettingsContext.Provider>
    );
};

export default MailSettingsContext;
