import React, { createContext, useCallback } from 'react';
import axios from 'axios';

const FileTypeContext = createContext();

export const FileTypeProvider = ({ children }) => {
    const apiurl = "https://e.sakagrup.com";
    const apiport = "";

    const api = {
        fileTypes: `${apiurl}:${apiport}/api/filetypes`,
    };

    const getFileTypes = useCallback(async (page = 1, limit = 10, search = '') => {
        try {
            const response = await axios.get(api.fileTypes, {
                params: {
                    page,
                    limit,
                    search
                }
            });
            return response.data;
        } catch (error) {
            console.error('Error fetching file types:', error);
            throw error;
        }
    }, [api.fileTypes]);

    const addFileType = useCallback(async (newFileType) => {
        try {
            const response = await axios.post(api.fileTypes, newFileType);
            return response.data;
        } catch (error) {
            console.error('Error adding file type:', error);
            throw error;
        }
    }, [api.fileTypes]);

    const updateFileType = useCallback(async (id, updatedFileType) => {
        try {
            const response = await axios.put(`${api.fileTypes}/${id}`, updatedFileType);
            return response.data;
        } catch (error) {
            console.error('Error updating file type:', error);
            throw error;
        }
    }, [api.fileTypes]);

    const deleteFileType = useCallback(async (id) => {
        try {
            await axios.delete(`${api.fileTypes}/${id}`);
        } catch (error) {
            console.error('Error deleting file type:', error);
            throw error;
        }
    }, [api.fileTypes]);

    return (
        <FileTypeContext.Provider value={{ 
            getFileTypes, addFileType, updateFileType, deleteFileType 
        }}>
            {children}
        </FileTypeContext.Provider>
    );
};

export default FileTypeContext;
