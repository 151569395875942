import React from 'react';
import { MainProvider } from './MainContext';
import { FileTypeProvider } from './Management/FileTypeContext';
import { JobProvider } from './Management/JobContext';
import { EmailTemplateProvider } from './Management/EmailTemplateContext';
import { EmailLogProvider } from './Management/EmailLogContext';
import { LanguageProvider } from './LanguageContext';
import { MailSettingsProvider } from './Management/MailSettingsContext';
import { UsersProvider } from './Management/UsersContext';
import { BetterStandsProvider } from './Management/BetterStandsContext';
import { FairProvider } from './Management/FairContext';

const AppProvider = ({ children }) => (
    <MainProvider>
        <FileTypeProvider>
            <JobProvider>
                <EmailTemplateProvider>
                    <EmailLogProvider>
                        <MailSettingsProvider>
                            <UsersProvider>
                                <BetterStandsProvider>
                                    <FairProvider>
                                        <LanguageProvider>
                                            {children}
                                        </LanguageProvider>
                                    </FairProvider>
                                </BetterStandsProvider>
                            </UsersProvider>
                        </MailSettingsProvider>
                    </EmailLogProvider>
                </EmailTemplateProvider>
            </JobProvider>
        </FileTypeProvider>
    </MainProvider>
);

export default AppProvider;
