import React, { createContext, useCallback } from 'react';
import axios from 'axios';

const EmailTemplateContext = createContext();

export const EmailTemplateProvider = ({ children }) => {
    const apiurl = "https://e.sakagrup.com";
    const apiport = "";

    const api = {
        emailTemplates: `${apiurl}:${apiport}/api/emailtemplates`,
    };

    const getEmailTemplates = useCallback(async (page = 1, limit = 10, search = '') => {
        try {
            const response = await axios.get(api.emailTemplates, {
                params: {
                    page,
                    limit,
                    search
                }
            });
            return response.data;
        } catch (error) {
            console.error('Error fetching email templates:', error);
            throw error;
        }
    }, [api.emailTemplates]);

    const addEmailTemplate = useCallback(async (newEmailTemplate) => {
        try {
            const response = await axios.post(api.emailTemplates, newEmailTemplate);
            return response.data;
        } catch (error) {
            console.error('Error adding email template:', error);
            throw error;
        }
    }, [api.emailTemplates]);

    const updateEmailTemplate = useCallback(async (id, updatedEmailTemplate) => {
        try {
            const response = await axios.put(`${api.emailTemplates}/${id}`, updatedEmailTemplate);
            return response.data;
        } catch (error) {
            console.error('Error updating email template:', error);
            throw error;
        }
    }, [api.emailTemplates]);

    const deleteEmailTemplate = useCallback(async (id) => {
        try {
            await axios.delete(`${api.emailTemplates}/${id}`);
        } catch (error) {
            console.error('Error deleting email template:', error);
            throw error;
        }
    }, [api.emailTemplates]);

    const saveOrUpdateTemplate = useCallback(async (templateId, title, content) => {
        const templateData = { title, content };
        
        if (templateId) {
            // Güncelleme işlemi
            return updateEmailTemplate(templateId, templateData);
        } else {
            // Yeni ekleme işlemi
            return addEmailTemplate(templateData);
        }
    }, [addEmailTemplate, updateEmailTemplate]);

    return (
        <EmailTemplateContext.Provider value={{ 
            getEmailTemplates, addEmailTemplate, updateEmailTemplate, deleteEmailTemplate,saveOrUpdateTemplate 
        }}>
            {children}
        </EmailTemplateContext.Provider>
    );
};

export default EmailTemplateContext;
