import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Navdata = () => {
  const history = useNavigate();

  const [isDashboard, setIsDashboard] = useState(false);
  const [isModuller, setIsModuller] = useState(false);
  const [isBetterStands, setIsBetterStands] = useState(false);
  const [isConfigurations, setIsConfigurations] = useState(false);
  const [isEmails, setIsEmails] = useState(false);

  const [iscurrentState, setIscurrentState] = useState("Dashboard");

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "Dashboard") {
      document.body.classList.add("twocolumn-panel");
      setIsDashboard(false);
    }
    if (iscurrentState !== "Moduller") {
      setIsModuller(false);
    }
  }, [
    history,
    iscurrentState,
    isDashboard,
    isModuller,
  ]);

  const menuItems = [
    {
      id: "Dashboard",
      label: "Ana Sayfa",
      icon: "bx bxs-home",
      link: "/Dashboard",
      click: function (e) {
        e.preventDefault();
        setIscurrentState("Dashboard");
      },
    },
    {
      id: "Fairs",
      label: "Fuarlar",
      icon: "bx bxs-map-pin",
      link: "/Fairs",
      click: function (e) {
        e.preventDefault();
        setIscurrentState("Fairs");
      },
    },
    {
      id: "Users",
      label: "Kullanıcılar",
      icon: "bx bxs-user",
      link: "/Users",
      click: function (e) {
        e.preventDefault();
        setIscurrentState("Users");
      },
    },
    {
      id: "Moduller",
      label: "Modüller",
      icon: "bx bx-chip",
      link: "/#",
      click: function (e) {
        e.preventDefault();
        setIsModuller(!isModuller);
        setIscurrentState("Moduller");
        updateIconSidebar(e);
      },
      stateVariables: isModuller,
      subItems: [
        {
          id: "Configurations",
          label: "FDS Yapılandırması",
          link: "/#",
          isChildItem: true,
          click: function (e) {
            e.preventDefault();
            setIsConfigurations(!isConfigurations);
          },
          parentId: "Configurations",
          stateVariables: isConfigurations,
          childItems: [
            {
              id: 1,
              label: "Dosya Türleri",
              link: "/FileTypes",
              parentId: "Moduller",
            },
            {
              id: 2,
              label: "Meslekler",
              link: "/Jobs",
              parentId: "Moduller",
            },
          ],
        },
        {
          id: "Emails",
          label: "Emails",
          link: "/#",
          isChildItem: true,
          click: function (e) {
            e.preventDefault();
            setIsEmails(!isEmails);
          },
          parentId: "Emails",
          stateVariables: isEmails,
          childItems: [
            {
              id: 1,
              label: "Temalar",
              link: "/EmailTemplates",
              parentId: "Moduller",
            },
            {
              id: 2,
              label: "Kayıtlar",
              link: "/EmailLogs",
              parentId: "Moduller",
            },
            {
              id: 3,
              label: "Ayarlar",
              link: "/EmailConfigs",
              parentId: "Moduller",
            },
          ],
        },
        {
          id: "BetterStands",
          label: "BetterStands",
          link: "/#",
          isChildItem: true,
          click: function (e) {
            e.preventDefault();
            setIsBetterStands(!isBetterStands);
          },
          parentId: "Moduller",
          stateVariables: isBetterStands,
          childItems: [
            {
              id: 1,
              label: "Saha",
              link: "/BTSArea",
              parentId: "Moduller",
            },
            {
              id: 2,
              label: "Dosyalar",
              link: "/BTSDocs",
              parentId: "Moduller",
            },
            {
              id: 2,
              label: "Raporlar",
              link: "/BTSReports",
              parentId: "Moduller",
            },
          ],
        },
      ],
    },
  ];
  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
