import React, { useContext, useEffect, useState } from 'react';
import { Container, Table, Button, Row, Col, Input, Modal, ModalHeader, ModalBody, ModalFooter, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Pagination, PaginationItem, PaginationLink, Card } from 'reactstrap';
import BreadCrumb from "../../../../../Components/Common/BreadCrumb";
import EmailTemplateContext from "../../../../../Components/Contexts/Management/EmailTemplateContext";

import 'ckeditor5/ckeditor5.css';

import { CKEditor } from '@ckeditor/ckeditor5-react';

import {
	ClassicEditor,
	AccessibilityHelp,
	Alignment,
	Autoformat,
	AutoImage,
	AutoLink,
	Autosave,
	Base64UploadAdapter,
	BlockQuote,
	Bold,
	Code,
	CodeBlock,
	Essentials,
	FindAndReplace,
	FontBackgroundColor,
	FontColor,
	FontFamily,
	FontSize,
	FullPage,
	GeneralHtmlSupport,
	Heading,
	Highlight,
	HorizontalLine,
	HtmlComment,
	HtmlEmbed,
	ImageBlock,
	ImageCaption,
	ImageInline,
	ImageInsert,
	ImageInsertViaUrl,
	ImageResize,
	ImageStyle,
	ImageTextAlternative,
	ImageToolbar,
	ImageUpload,
	Indent,
	IndentBlock,
	Italic,
	Link,
	LinkImage,
	List,
	ListProperties,
	Markdown,
	MediaEmbed,
	Mention,
	PageBreak,
	Paragraph,
	PasteFromMarkdownExperimental,
	PasteFromOffice,
	RemoveFormat,
	SelectAll,
	ShowBlocks,
	SourceEditing,
	SpecialCharacters,
	SpecialCharactersArrows,
	SpecialCharactersCurrency,
	SpecialCharactersEssentials,
	SpecialCharactersLatin,
	SpecialCharactersMathematical,
	SpecialCharactersText,
	Strikethrough,
	Style,
	Subscript,
	Superscript,
	TextPartLanguage,
	TextTransformation,
	Title,
	TodoList,
	Underline,
	Undo
} from 'ckeditor5';

const Templates = () => {
	document.title = "Email Temaları | FDS";

	const { getEmailTemplates, addEmailTemplate, updateEmailTemplate, deleteEmailTemplate } = useContext(EmailTemplateContext);
	const [emailTemplates, setEmailTemplates] = useState([]);
	const [loading, setLoading] = useState(true);
	const [editingId, setEditingId] = useState(null);
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(10);
	const [totalPages, setTotalPages] = useState(1);
	const [search, setSearch] = useState("");
	const [modalOpen, setModalOpen] = useState(false);
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const [deleteId, setDeleteId] = useState(null);
	const [newEmailTemplate, setNewEmailTemplate] = useState({ title: '', content: '' });
	const [dropdownOpen, setDropdownOpen] = useState(false);

	const editorConfig = {
		toolbar: {
			items: [
				'undo',
				'redo',
				'|',
				'sourceEditing',
				'showBlocks',
				'findAndReplace',
				'textPartLanguage',
				'|',
				'heading',
				'style',
				'|',
				'fontSize',
				'fontFamily',
				'fontColor',
				'fontBackgroundColor',
				'|',
				'bold',
				'italic',
				'underline',
				'strikethrough',
				'subscript',
				'superscript',
				'code',
				'removeFormat',
				'|',
				'specialCharacters',
				'horizontalLine',
				'pageBreak',
				'link',
				'insertImage',
				'mediaEmbed',
				'highlight',
				'blockQuote',
				'codeBlock',
				'htmlEmbed',
				'|',
				'alignment',
				'|',
				'bulletedList',
				'numberedList',
				'todoList',
				'outdent',
				'indent'
			],
			shouldNotGroupWhenFull: true
		},
		plugins: [
			AccessibilityHelp,
			Alignment,
			Autoformat,
			AutoImage,
			AutoLink,
			Autosave,
			Base64UploadAdapter,
			BlockQuote,
			Bold,
			Code,
			CodeBlock,
			Essentials,
			FindAndReplace,
			FontBackgroundColor,
			FontColor,
			FontFamily,
			FontSize,
			FullPage,
			GeneralHtmlSupport,
			Heading,
			Highlight,
			HorizontalLine,
			HtmlComment,
			HtmlEmbed,
			ImageBlock,
			ImageCaption,
			ImageInline,
			ImageInsert,
			ImageInsertViaUrl,
			ImageResize,
			ImageStyle,
			ImageTextAlternative,
			ImageToolbar,
			ImageUpload,
			Indent,
			IndentBlock,
			Italic,
			Link,
			LinkImage,
			List,
			ListProperties,
			Markdown,
			MediaEmbed,
			Mention,
			PageBreak,
			Paragraph,
			PasteFromMarkdownExperimental,
			PasteFromOffice,
			RemoveFormat,
			SelectAll,
			ShowBlocks,
			SourceEditing,
			SpecialCharacters,
			SpecialCharactersArrows,
			SpecialCharactersCurrency,
			SpecialCharactersEssentials,
			SpecialCharactersLatin,
			SpecialCharactersMathematical,
			SpecialCharactersText,
			Strikethrough,
			Style,
			Subscript,
			Superscript,
			TextPartLanguage,
			TextTransformation,
			Title,
			TodoList,
			Underline,
			Undo
		],
		fontFamily: {
			supportAllValues: true
		},
		fontSize: {
			options: ['default', 8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48, 72],
			supportAllValues: true
		},
		heading: {
			options: [
				{
					model: 'paragraph',
					title: 'Paragraph',
					class: 'ck-heading_paragraph'
				},
				{
					model: 'heading1',
					view: 'h1',
					title: 'Heading 1',
					class: 'ck-heading_heading1'
				},
				{
					model: 'heading2',
					view: 'h2',
					title: 'Heading 2',
					class: 'ck-heading_heading2'
				},
				{
					model: 'heading3',
					view: 'h3',
					title: 'Heading 3',
					class: 'ck-heading_heading3'
				},
				{
					model: 'heading4',
					view: 'h4',
					title: 'Heading 4',
					class: 'ck-heading_heading4'
				},
				{
					model: 'heading5',
					view: 'h5',
					title: 'Heading 5',
					class: 'ck-heading_heading5'
				},
				{
					model: 'heading6',
					view: 'h6',
					title: 'Heading 6',
					class: 'ck-heading_heading6'
				}
			]
		},
		htmlSupport: {
			allow: [
				{
					name: /^.*$/,
					styles: true,
					attributes: true,
					classes: true
				}
			]
		},
		image: {
			toolbar: [
				'toggleImageCaption',
				'imageTextAlternative',
				'|',
				'imageStyle:inline',
				'imageStyle:wrapText',
				'imageStyle:breakText',
				'|',
				'resizeImage'
			]
		},
		language: 'tr',
		link: {
			addTargetToExternalLinks: true,
			defaultProtocol: 'https://',
			decorators: {
				toggleDownloadable: {
					mode: 'manual',
					label: 'Downloadable',
					attributes: {
						download: 'file'
					}
				}
			}
		},
		list: {
			properties: {
				styles: true,
				startIndex: true,
				reversed: true
			}
		},
		mention: {
			feeds: [
				{
					marker: '@',
					feed: [
					]
				}
			]
		},
		placeholder: 'Type or paste your content here!',
		style: {
			definitions: [
				{
					name: 'Article category',
					element: 'h3',
					classes: ['category']
				},
				{
					name: 'Title',
					element: 'h2',
					classes: ['document-title']
				},
				{
					name: 'Subtitle',
					element: 'h3',
					classes: ['document-subtitle']
				},
				{
					name: 'Info box',
					element: 'p',
					classes: ['info-box']
				},
				{
					name: 'Side quote',
					element: 'blockquote',
					classes: ['side-quote']
				},
				{
					name: 'Marker',
					element: 'span',
					classes: ['marker']
				},
				{
					name: 'Spoiler',
					element: 'span',
					classes: ['spoiler']
				},
				{
					name: 'Code (dark)',
					element: 'pre',
					classes: ['fancy-code', 'fancy-code-dark']
				},
				{
					name: 'Code (bright)',
					element: 'pre',
					classes: ['fancy-code', 'fancy-code-bright']
				}
			]
		},
	};

	const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

	const fetchTemplates = async () => {
		try {
			setLoading(true);
			const data = await getEmailTemplates(page, limit, search);
			setEmailTemplates(data.data);
			setTotalPages(data.totalPages);
		} catch (error) {
			console.error("Error fetching templates:", error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchTemplates();
	}, [page, limit, search]);

	const toggleModal = () => {
		if (modalOpen) {
			setEditingId(null);
			setNewEmailTemplate({ title: '', content: '' });
		}
		setModalOpen(!modalOpen);
	};

	const toggleDeleteModal = (id) => {
		setDeleteId(id);
		setDeleteModalOpen(!deleteModalOpen);
	};

	const handleSave = async () => {
		try {
			if (editingId) {
				await updateEmailTemplate(editingId, newEmailTemplate);
			} else {
				await addEmailTemplate(newEmailTemplate);
			}
			toggleModal();
			fetchTemplates();
		} catch (error) {
			console.error("Error saving template:", error);
		}
	};

	const confirmDelete = async () => {
		try {
			await deleteEmailTemplate(deleteId);
			setDeleteModalOpen(false);
			fetchTemplates();
		} catch (error) {
			console.error("Error deleting template:", error);
		}
	};

	const handleEdit = (template) => {
		setEditingId(template.email_template_id);
		setNewEmailTemplate({
			title: template.email_template_title,
			content: template.email_template_content
		});
		toggleModal();
	};

	const renderPagination = () => {
		const paginationItems = [];
		const maxPagesToShow = 5;

		for (let i = 1; i <= totalPages; i++) {
			if (totalPages > maxPagesToShow) {
				if (i === 1 || i === totalPages || (i >= page - 1 && i <= page + 1)) {
					paginationItems.push(
						<PaginationItem key={i} active={i === page}>
							<PaginationLink onClick={() => setPage(i)}>{i}</PaginationLink>
						</PaginationItem>
					);
				} else if (i === 2 || i === totalPages - 1) {
					paginationItems.push(
						<PaginationItem key={i} disabled>
							<PaginationLink>...</PaginationLink>
						</PaginationItem>
					);
				}
			} else {
				paginationItems.push(
					<PaginationItem key={i} active={i === page}>
						<PaginationLink onClick={() => setPage(i)}>{i}</PaginationLink>
					</PaginationItem>
				);
			}
		}
		return paginationItems;
	};

	return (
		<div className="page-content">
			<Container fluid>
				<BreadCrumb title="Temalar" pageTitle="Emails" />
				<Card className="p-3">
					<Row>
						<Col md="1">
							<Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
								<DropdownToggle caret>
									Gösterim: {limit}
								</DropdownToggle>
								<DropdownMenu>
									<DropdownItem onClick={() => setLimit(10)}>10</DropdownItem>
									<DropdownItem onClick={() => setLimit(25)}>25</DropdownItem>
									<DropdownItem onClick={() => setLimit(50)}>50</DropdownItem>
									<DropdownItem onClick={() => setLimit(100)}>100</DropdownItem>
								</DropdownMenu>
							</Dropdown>
						</Col>
						<Col md="3">
							<Input
								type="text"
								placeholder="Arama..."
								value={search}
								onChange={(e) => setSearch(e.target.value)}
							/>
						</Col>
						<Col md="8" className="text-end">
							<Button color="secondary" onClick={toggleModal}>Yeni Ekle</Button>
						</Col>
					</Row>
				</Card>
				{loading ? (
					<div>Yükleniyor...</div>
				) : (
					<>
						<Card className="pt-3 pe-3 ps-3">
							<Table responsive bordered striped>
								<thead>
									<tr style={{height: '40px'}}>
										<th>Başlık</th>
										<th>İşlemler</th>
									</tr>
								</thead>
								<tbody>
									{emailTemplates.map((template) => (
										<tr style={{height: '65px'}} key={template.email_template_id}>
											<td>{template.email_template_title}</td>
											<td>
												<Button
													color="warning"
													className="me-2"
													onClick={() => handleEdit(template)}
												>
													Düzenle
												</Button>
												<Button color="primary" onClick={() => toggleDeleteModal(template.email_template_id)}>
													Sil
												</Button>
											</td>
										</tr>
									))}
								</tbody>
							</Table>
						</Card>
						<Pagination className="pagination justify-content-center">
							{renderPagination()}
						</Pagination>
					</>
				)}

				<Modal isOpen={modalOpen} toggle={toggleModal} size="xl"> {/* Modal boyutunu artırdık */}
					<ModalHeader toggle={toggleModal}>{editingId ? "Email Şablonunu Düzenle" : "Yeni Email Şablonu Ekle"}</ModalHeader>
					<ModalBody>
						<Row className="mb-3">
							<Col md="12">
								<Input
									type="text"
									name="title"
									placeholder="Başlık"
									value={newEmailTemplate.title}
									onChange={(e) => setNewEmailTemplate(prev => ({ ...prev, title: e.target.value }))}
								/>
							</Col>
						</Row>
						<Row className="mb-3">
							<Col md="12">
								{modalOpen && (
									<CKEditor
										editor={ClassicEditor}
										data={newEmailTemplate.content || ''}
										onChange={(event, editor) => {
											const data = editor.getData();
											setNewEmailTemplate((prev) => ({ ...prev, content: data }));
										}}
										config={editorConfig}
									/>
								)}
							</Col>
						</Row>
					</ModalBody>
					<ModalFooter>
						<Button color="primary" onClick={handleSave}>
							{editingId ? "Güncelle" : "Ekle"}
						</Button>
						<Button color="secondary" onClick={toggleModal}>İptal</Button>
					</ModalFooter>
				</Modal>
				<Modal isOpen={deleteModalOpen} toggle={toggleDeleteModal}>
					<ModalHeader toggle={toggleDeleteModal}>Onay</ModalHeader>
					<ModalBody>Bu email şablonunu silmek istediğinizden emin misiniz?</ModalBody>
					<ModalFooter>
						<Button color="primary" onClick={confirmDelete}>Sil</Button>
						<Button color="secondary" onClick={toggleDeleteModal}>İptal</Button>
					</ModalFooter>
				</Modal>
			</Container>
		</div>
	);
};

export default Templates;
