import React, { createContext, useCallback, useState } from 'react';
import axios from 'axios';

const EmailLogContext = createContext();

export const EmailLogProvider = ({ children }) => {
    const [systemEmails, setSystemEmails] = useState([]);
    const [emailLogs, setEmailLogs] = useState([]);
    const [loading, setLoading] = useState(true);

    const apiurl = "https://e.sakagrup.com";
    const apiport = "";
    const api = {
        emailLogs: `${apiurl}:${apiport}/api/email-logs`,
        systemEmails: `${apiurl}:${apiport}/api/system-emails`,
        sendEmail: `${apiurl}:${apiport}/api/send-email`
    };

    const fetchEmailLogs = useCallback(async (page = 1, limit = 10, search = '') => {
        setLoading(true);
        try {
            const response = await axios.get(api.emailLogs, { params: { page, limit, search } });
            console.log("Fetched Email Logs:", response.data); 
            setEmailLogs(response.data);
        } catch (error) {
            console.error('Error fetching email logs:', error);
        } finally {
            setLoading(false);
        }
    }, []);
    

    const getEmailLogById = useCallback(async (id) => {
        try {
            const response = await axios.get(`${api.emailLogs}/${id}`);
            return response.data;
        } catch (error) {
            console.error('Error fetching email log detail:', error);
            throw error;
        }
    }, [api.emailLogs]);

    const searchUsers = useCallback(async (searchTerm = "") => { // Default olarak boş bir string veriyoruz
        try {
            const response = await axios.get(`${apiurl}:${apiport}/api/users/search`, { params: { search: searchTerm } });
            return response.data;
        } catch (error) {
            console.error('Error searching users:', error);
            return [];
        }
    }, []);

    const sendEmail = async ({ senderId, userId, recipients, ccRecipients, subject, content }) => {
        console.log("Gönderilen e-posta verileri:", { senderId, userId, recipients, ccRecipients, subject, content });
        
        try {
            const response = await axios.post(api.sendEmail, {
                senderId, // Sistem mail ID
                userId,   // Kullanıcı ID (varsa localStorage'dan alınır)
                recipients,
                ccRecipients,
                subject,
                content
            });
    
            if (response.status === 200) {
                return true;
            }
        } catch (error) {
            console.error("E-posta gönderilirken hata oluştu:", error);
        }
        return false;
    };

    const getSystemEmails = useCallback(async () => {
        try {
            const systemEmailsURL = `${apiurl}:${apiport}/api/system-emails`;  // API URL'ini doğrudan burada tanımladık
            console.log("Fetching system emails from:", systemEmailsURL);  // URL kontrolü için log
            const response = await axios.get(systemEmailsURL);
            console.log("Fetched System Emails:", response.data);  // Gelen yanıtı kontrol etmek için log
            setSystemEmails(response.data);
        } catch (error) {
            console.error('Error fetching system emails:', error);
        }
    }, [apiurl, apiport]);
    

    return (
        <EmailLogContext.Provider value={{
            emailLogs,
            fetchEmailLogs,
            searchUsers,
            getEmailLogById,
            systemEmails,
            getSystemEmails,
            sendEmail,
            loading
        }}>
            {children}
        </EmailLogContext.Provider>
    );
};

export default EmailLogContext;
