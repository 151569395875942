import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import MainContext from '../../Components/Contexts/MainContext';

const getInitials = (firstName, lastName) => {
    if (!firstName || !lastName) return "A";
    return `${firstName[0]}${lastName[0]}`;
};

const ProfileDropdown = () => {
    const [Name, setName] = useState(null);
    const [userName, setUserName] = useState(null);
    const [initials, setInitials] = useState(null);
    const [userGroupName, setUserGroupName] = useState(null);
    const navigate = useNavigate();

    const { getUserDataContext } = useContext(MainContext);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const { userInfo, groupInfo } = await getUserDataContext();
                setUserName(`${userInfo.userName} ${userInfo.userSurname}`);
                setName(userInfo.userName);
                setInitials(getInitials(userInfo.userName, userInfo.userSurname));
                setUserGroupName(groupInfo.userGroupName);
            } catch (error) {
                console.error('Error fetching user info:', error);
            }
        };

        fetchUserData();
    }, [getUserDataContext]);

    const [isProfileDropdown, setIsProfileDropdown] = useState(false);
    const toggleProfileDropdown = () => {
        setIsProfileDropdown(!isProfileDropdown);
    };

    const handleLogout = () => {
        localStorage.removeItem('userId');
        localStorage.removeItem('token');
        localStorage.removeItem('groupInfo');
        localStorage.removeItem('user');
        navigate('/login');
    };

    return (
        <React.Fragment>
            <Dropdown isOpen={isProfileDropdown} toggle={toggleProfileDropdown} className="ms-sm-3 header-item topbar-user">
                <DropdownToggle tag="button" type="button" className="btn">
                    <span className="d-flex align-items-center">
                        <div className="rounded-circle header-profile-user d-flex align-items-center justify-content-center bg-primary text-white" style={{ width: 40, height: 40, fontSize: 20 }}>
                            {initials}
                        </div>
                        <span className="text-start ms-xl-2">
                            <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{userName}</span>
                            <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">{userGroupName}</span>
                        </span>
                    </span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                    <h6 className="dropdown-header">Hoşgeldin {Name}</h6>
                    <DropdownItem className='p-0'>
                        <Link to="/profile" className="dropdown-item">
                            <i className="bx bx-id-card text-muted fs-16 align-middle me-1"></i>
                            <span className="align-middle">Yaka Kartı</span>
                        </Link>
                    </DropdownItem>
                    <DropdownItem className='p-0'>
                        <Link to="/profile" className="dropdown-item">
                            <i className="bx bx-user text-muted fs-16 align-middle me-1"></i>
                            <span className="align-middle">Profil</span>
                        </Link>
                    </DropdownItem>
                    <div className="dropdown-divider"></div>
                    <DropdownItem className='p-0' onClick={handleLogout}>
                        <Link to="/pages-profile-settings" className="dropdown-item">
                            <i className="bx bx-exit text-muted fs-16 align-middle me-1"></i>
                            <span className="align-middle">Çıkış Yap</span>
                        </Link>
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

export default ProfileDropdown;
