import React, { createContext, useCallback, useState } from 'react';
import axios from 'axios';

const UsersContext = createContext();

export const UsersProvider = ({ children }) => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);

    const apiurl = "https://e.sakagrup.com";
    const apiport = "";

    const fetchUsers = useCallback(async (page = 1, limit = 10, search = '') => {
        setLoading(true);
        try {
            const response = await axios.get(`${apiurl}:${apiport}/api/users`, { params: { page, limit, search } });
            setUsers(response.data);
        } catch (error) {
            console.error('Error fetching users:', error);
        } finally {
            setLoading(false);
        }
    }, []);

    // Yeni fonksiyon: Aktif fuardaki kullanıcıları getir
    const fetchActiveFairUsers = async (userId) => {
        try {
            const response = await axios.get(`${apiurl}:${apiport}/api/active-fair-users/${userId}`);
            return response.data;
        } catch (error) {
            console.error('Error fetching active fair users:', error);
            return [];
        }
    };

    return (
        <UsersContext.Provider value={{ users, fetchUsers, fetchActiveFairUsers, loading }}>
            {children}
        </UsersContext.Provider>
    );
};

export default UsersContext;