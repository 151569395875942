import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { layoutModeTypes, leftSidebarTypes } from "../../Components/constants/layout";
import { changeLayoutModeAction, changeSidebarThemeAction } from '../../slices/layouts/reducer';

const LightDark = ({ layoutMode }) => {
    const dispatch = useDispatch();

    const mode = layoutMode === layoutModeTypes.DARKMODE ? layoutModeTypes.LIGHTMODE : layoutModeTypes.DARKMODE;
    const sidebarTheme = mode === layoutModeTypes.LIGHTMODE ? leftSidebarTypes.LIGHT : leftSidebarTypes.DARK;

    const onChangeLayoutMode = () => {
        dispatch(changeLayoutModeAction(mode));
        dispatch(changeSidebarThemeAction(sidebarTheme));
        localStorage.setItem('themeMode', mode);
    };

    return (
        <div className="ms-1 header-item d-none d-sm-flex">
            <button
                onClick={onChangeLayoutMode}
                type="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle light-dark-mode">
                <i className='bx bx-moon fs-22'></i>
            </button>
        </div>
    );
};

export default LightDark;
