import React, { useContext, useEffect, useState } from "react";
import { Container, Table, Button, Row, Col, Input, Modal, ModalHeader, ModalBody, ModalFooter, Pagination, PaginationItem, PaginationLink, Card, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import BreadCrumb from "../../../../../Components/Common/BreadCrumb";
import MailSettingsContext from "../../../../../Components/Contexts/Management/MailSettingsContext";
import Select from 'react-select';

const Configs = () => {
    document.title = "Ayarlar | FDS";

    const { mailSettings, fetchMailSettings, addMailSetting, updateMailSetting, deleteMailSetting, searchMailUsers, loading } = useContext(MailSettingsContext);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [search, setSearch] = useState("");
    const [modalOpen, setModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false); // Silme onay modali için state
    const [deleteId, setDeleteId] = useState(null);
    const [selectedMail, setSelectedMail] = useState(null);
    const [userOptions, setUserOptions] = useState([]);
    const [newMail, setNewMail] = useState({
        system_email_title: "",
        system_email_value: "",
        system_email_password: "",
        user_id: 0,
        type_id: 1
    });

    useEffect(() => {
        fetchMailSettings(page, limit, search);
    }, [page, limit, search, fetchMailSettings]);

    const toggleModal = () => setModalOpen(!modalOpen);

    const toggleEditModal = (mail) => {
        setSelectedMail(mail);

        if (mail && mail.user_id && mail.user_name && mail.user_surname) {
            setSelectedUser({ label: `${mail.user_name} ${mail.user_surname}`, value: mail.user_id });
        } else {
            setSelectedUser(null);
        }

        setEditModalOpen(!editModalOpen);
    };

    const toggleDeleteModal = (id) => {
        setDeleteId(id);
        setDeleteModalOpen(!deleteModalOpen); // Silme onay modalini aç/kapat
    };

    const handleDeleteMail = async () => {
        if (deleteId) {
            await deleteMailSetting(deleteId); // Onay alındığında silme işlemi yapılır
            setDeleteModalOpen(false);
        }
    };

    const handleAddMail = async () => {
        // Seçili kullanıcı varsa user_id'yi ayarla; yoksa 0 olarak kalsın
        const mailToAdd = {
            ...newMail,
            user_id: selectedUser ? selectedUser.value : 0
        };
        fetchMailSettings(page, limit, search);

        await addMailSetting(mailToAdd);
        toggleModal();
        setNewMail({
            system_email_title: "",
            system_email_value: "",
            system_email_password: "",
            user_id: 0,
            type_id: 1
        });
        setSelectedUser(null); // Seçili kullanıcıyı temizle
    };

    const renderPagination = () => {
        const totalPages = mailSettings.totalPages || 1;
        const paginationItems = [];

        for (let i = 1; i <= totalPages; i++) {
            paginationItems.push(
                <PaginationItem key={i} active={i === page}>
                    <PaginationLink onClick={() => setPage(i)}>{i}</PaginationLink>
                </PaginationItem>
            );
        }
        return paginationItems;
    };

    const handleEditMail = async () => {
        try {
            if (selectedMail) {
                // Güncellenmiş kullanıcı verilerini selectedMail'e ekliyoruz
                const updatedMail = {
                    ...selectedMail,
                    user_id: selectedUser ? selectedUser.value : 0 // Seçili kullanıcı yoksa 0 atıyoruz
                };

                await updateMailSetting(selectedMail.system_email_id, updatedMail);
                toggleEditModal(null); // Modalı kapat
                fetchMailSettings(); // Değişiklikleri görmek için listeyi yeniden yükleyin
            }
        } catch (error) {
            console.error("Error updating mail setting:", error);
        }
    };

    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

    const handleUserSearch = async (inputValue = "") => {
        if (!inputValue || typeof inputValue !== "string") return; // Geçersiz veya boş değerleri atla

        try {
            const users = await searchMailUsers(inputValue);
            if (Array.isArray(users)) {
                const options = users.map(user => ({
                    label: `${user.user_name} ${user.user_surname}`,
                    value: user.user_id
                }));
                setUserOptions(options);
            } else {
                console.error("Beklenmeyen arama sonucu:", users);
            }
        } catch (error) {
            console.error("Kullanıcı ararken hata oluştu:", error);
        }
    };

    return (
        <div className="page-content">
            <Container fluid>
                <BreadCrumb title="Ayarlar" pageTitle="Emails" />
                <Card className="p-3">
                    <Row>
                        <Col md="1">
                            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                                <DropdownToggle caret>
                                    Gösterim: {limit}
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={() => setLimit(10)}>10</DropdownItem>
                                    <DropdownItem onClick={() => setLimit(25)}>25</DropdownItem>
                                    <DropdownItem onClick={() => setLimit(50)}>50</DropdownItem>
                                    <DropdownItem onClick={() => setLimit(100)}>100</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </Col>
                        <Col md="3">
                            <Input type="text" placeholder="Arama..." value={search} onChange={(e) => setSearch(e.target.value)} />
                        </Col>
                        <Col md="8" className="text-end">
                            <Button color="secondary" onClick={toggleModal}>Mail Ekle</Button>
                        </Col>
                    </Row>
                </Card>
                <Card className="pe-3 ps-3 pt-3">
                    {loading ? (
                        <div>Yükleniyor...</div>
                    ) : (
                        <Table responsive bordered striped>
                            <thead>
                                <tr style={{ height: '40px' }}>
                                    <th>Başlık</th>
                                    <th>Email</th>
                                    <th>Şifre</th>
                                    <th>Atanmış Kullanıcı</th>
                                    <th>Mail Host</th>
                                    <th>İşlem</th>
                                </tr>
                            </thead>
                            <tbody>
                                {mailSettings.data?.map(mail => (
                                    <tr style={{ height: '65px' }} key={mail.system_email_id}>
                                        <td>{mail.system_email_title}</td>
                                        <td>{mail.system_email_value}</td>
                                        <td>{mail.system_email_password}</td>
                                        <td>
                                            {mail.user_id === 0 ? (
                                                "[Genel]"
                                            ) : (
                                                <a href={`/user/${mail.user_id}`}>
                                                    {mail.user_name} {mail.user_surname}
                                                </a>
                                            )}
                                        </td>
                                        <td>{mail.type_id === 1 ? "Yandex" : "Diğer"}</td>
                                        <td>
                                            <Button
                                                color="warning"
                                                className="me-2"
                                                onClick={() => toggleEditModal(mail)}
                                            >
                                                Düzenle
                                            </Button>
                                            <Button color="primary" onClick={() => toggleDeleteModal(mail.system_email_id)}>
                                                Sil
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    )}
                </Card>
                <Pagination className="pagination justify-content-center mt-3">
                    {renderPagination()}
                </Pagination>

                <Modal isOpen={modalOpen} toggle={toggleModal}>
                    <ModalHeader toggle={toggleModal}>Yeni Mail Ekle</ModalHeader>
                    <ModalBody>
                        <Input
                            className="mb-3"
                            type="text"
                            placeholder="Başlık"
                            value={newMail.system_email_title}
                            onChange={(e) => setNewMail({ ...newMail, system_email_title: e.target.value })}
                        />
                        <Input
                            className="mb-3"
                            type="email"
                            placeholder="Email Adresi"
                            value={newMail.system_email_value}
                            onChange={(e) => setNewMail({ ...newMail, system_email_value: e.target.value })}
                        />
                        <Input
                            className="mb-3"
                            type="password"
                            placeholder="Şifre"
                            value={newMail.system_email_password}
                            onChange={(e) => setNewMail({ ...newMail, system_email_password: e.target.value })}
                        />
                        <Select
                            options={userOptions}
                            value={selectedUser}
                            onInputChange={(inputValue) => {
                                console.log("Input Change Değeri:", inputValue); // Değeri kontrol etmek için
                                handleUserSearch(inputValue);
                            }}
                            onChange={(selected) => setSelectedUser(selected)} // Tek seçim için setSelectedUser kullanın
                            placeholder="Kullanıcı arayın..."
                            className="data-choices form-control mb-3"
                            isClearable
                            styles={{
                                color: 'white',
                                multiValueLabel: (base) => ({
                                    ...base,
                                    color: 'white', // Seçili etiketlerin metnini beyaz yap
                                })
                            }}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={handleAddMail}>Ekle</Button>
                        <Button color="secondary" onClick={toggleModal}>İptal</Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={editModalOpen} toggle={() => toggleEditModal(null)}>
                    <ModalHeader toggle={() => toggleEditModal(null)}>Mail Düzenle</ModalHeader>
                    <ModalBody>
                        <Input
                            className="mb-3"
                            type="text"
                            value={selectedMail?.system_email_title || ""}
                            onChange={(e) => setSelectedMail({ ...selectedMail, system_email_title: e.target.value })}
                        />
                        <Input
                            className="mb-3"
                            type="email"
                            value={selectedMail?.system_email_value || ""}
                            onChange={(e) => setSelectedMail({ ...selectedMail, system_email_value: e.target.value })}
                        />
                        <Input
                            className="mb-3"
                            type="password"
                            placeholder="Şifre"
                            value={selectedMail?.system_email_password || ""}
                            onChange={(e) => setSelectedMail({ ...selectedMail, system_email_password: e.target.value })}
                        />
                        {/* Kullanıcı seçimi alanı */}
                        <Select
                            options={userOptions}
                            value={selectedUser}
                            onInputChange={(inputValue) => {
                                console.log("Input Change Değeri:", inputValue); // Değeri kontrol etmek için
                                handleUserSearch(inputValue);
                            }}
                            onChange={(selected) => setSelectedUser(selected)} // Tek seçim için setSelectedUser kullanın
                            placeholder="Kullanıcı arayın..."
                            className="data-choices form-control mb-3"
                            isClearable
                            styles={{
                                color: 'white',
                                multiValueLabel: (base) => ({
                                    ...base,
                                    color: 'white', // Seçili etiketlerin metnini beyaz yap
                                })
                            }}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={handleEditMail}>Kaydet</Button>
                        <Button color="secondary" onClick={() => toggleEditModal(null)}>İptal</Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={deleteModalOpen} toggle={() => toggleDeleteModal(null)}>
                    <ModalHeader toggle={() => toggleDeleteModal(null)}>Onay</ModalHeader>
                    <ModalBody>Bu mail kaydını silmek istediğinizden emin misiniz?</ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={handleDeleteMail}>Sil</Button>
                        <Button color="secondary" onClick={() => toggleDeleteModal(null)}>İptal</Button>
                    </ModalFooter>
                </Modal>
            </Container>
        </div>
    );
};

export default Configs;
