import React, { useContext, useEffect, useState } from 'react';
import { Container, Table, Button, CardBody, Row, Col, Input, Modal, ModalHeader, ModalBody, Pagination, PaginationItem, PaginationLink, Card, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import UsersContext from "../../../Components/Contexts/Management/UsersContext";
import EmailTemplateContext from "../../../Components/Contexts/Management/EmailTemplateContext";
import EmailLogContext from "../../../Components/Contexts/Management/EmailLogContext";
import MailSettingsContext from "../../../Components/Contexts/Management/MailSettingsContext";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CKEditor } from '@ckeditor/ckeditor5-react';

import {
    ClassicEditor,
    AccessibilityHelp,
    Alignment,
    Autoformat,
    AutoImage,
    AutoLink,
    Autosave,
    Base64UploadAdapter,
    BlockQuote,
    Bold,
    Code,
    CodeBlock,
    Essentials,
    FindAndReplace,
    FontBackgroundColor,
    FontColor,
    FontFamily,
    FontSize,
    FullPage,
    GeneralHtmlSupport,
    Heading,
    Highlight,
    HorizontalLine,
    HtmlComment,
    HtmlEmbed,
    ImageBlock,
    ImageCaption,
    ImageInline,
    ImageInsert,
    ImageInsertViaUrl,
    ImageResize,
    ImageStyle,
    ImageTextAlternative,
    ImageToolbar,
    ImageUpload,
    Indent,
    IndentBlock,
    Italic,
    Link,
    LinkImage,
    List,
    ListProperties,
    Markdown,
    MediaEmbed,
    Mention,
    PageBreak,
    Paragraph,
    PasteFromMarkdownExperimental,
    PasteFromOffice,
    RemoveFormat,
    SelectAll,
    ShowBlocks,
    SourceEditing,
    SpecialCharacters,
    SpecialCharactersArrows,
    SpecialCharactersCurrency,
    SpecialCharactersEssentials,
    SpecialCharactersLatin,
    SpecialCharactersMathematical,
    SpecialCharactersText,
    Strikethrough,
    Style,
    Subscript,
    Superscript,
    TextPartLanguage,
    TextTransformation,
    Title,
    TodoList,
    Underline,
    Undo
} from 'ckeditor5';

const Users = () => {
    document.title = "Kullanıcılar | FDS";

    const { users, fetchUsers, loading, fetchActiveFairUsers } = useContext(UsersContext);
    const { mailSettings = [], fetchMailSettings } = useContext(MailSettingsContext);
    const { sendEmail, getSystemEmails, systemEmails } = useContext(EmailLogContext);
    const { getEmailTemplates, saveOrUpdateTemplate } = useContext(EmailTemplateContext);
    const [emailTemplates, setEmailTemplates] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [search, setSearch] = useState("");
    const [sendModalOpen, setSendModalOpen] = useState(false);
    const [selectedMailType, setSelectedMailType] = useState("");
    const [activeFairUsers, setActiveFairUsers] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [viewMode, setViewMode] = useState("grid"); // Başlangıç modu: grid
    const [selectedSender, setSelectedSender] = useState();
    const [newEmail, setNewEmail] = useState({
        templateId: "",
        recipients: "",
        ccRecipients: [],
        sender: "",
        subject: "",
        content: ""
    });

    const editorConfig = {
        toolbar: {
            items: [
                'undo',
                'redo',
                '|',
                'sourceEditing',
                'showBlocks',
                'findAndReplace',
                'textPartLanguage',
                '|',
                'heading',
                'style',
                '|',
                'fontSize',
                'fontFamily',
                'fontColor',
                'fontBackgroundColor',
                '|',
                'bold',
                'italic',
                'underline',
                'strikethrough',
                'subscript',
                'superscript',
                'code',
                'removeFormat',
                '|',
                'specialCharacters',
                'horizontalLine',
                'pageBreak',
                'link',
                'insertImage',
                'mediaEmbed',
                'highlight',
                'blockQuote',
                'codeBlock',
                'htmlEmbed',
                '|',
                'alignment',
                '|',
                'bulletedList',
                'numberedList',
                'todoList',
                'outdent',
                'indent'
            ],
            shouldNotGroupWhenFull: true
        },
        plugins: [
            AccessibilityHelp,
            Alignment,
            Autoformat,
            AutoImage,
            AutoLink,
            Autosave,
            Base64UploadAdapter,
            BlockQuote,
            Bold,
            Code,
            CodeBlock,
            Essentials,
            FindAndReplace,
            FontBackgroundColor,
            FontColor,
            FontFamily,
            FontSize,
            FullPage,
            GeneralHtmlSupport,
            Heading,
            Highlight,
            HorizontalLine,
            HtmlComment,
            HtmlEmbed,
            ImageBlock,
            ImageCaption,
            ImageInline,
            ImageInsert,
            ImageInsertViaUrl,
            ImageResize,
            ImageStyle,
            ImageTextAlternative,
            ImageToolbar,
            ImageUpload,
            Indent,
            IndentBlock,
            Italic,
            Link,
            LinkImage,
            List,
            ListProperties,
            Markdown,
            MediaEmbed,
            Mention,
            PageBreak,
            Paragraph,
            PasteFromMarkdownExperimental,
            PasteFromOffice,
            RemoveFormat,
            SelectAll,
            ShowBlocks,
            SourceEditing,
            SpecialCharacters,
            SpecialCharactersArrows,
            SpecialCharactersCurrency,
            SpecialCharactersEssentials,
            SpecialCharactersLatin,
            SpecialCharactersMathematical,
            SpecialCharactersText,
            Strikethrough,
            Style,
            Subscript,
            Superscript,
            TextPartLanguage,
            TextTransformation,
            Title,
            TodoList,
            Underline,
            Undo
        ],
        fontFamily: {
            supportAllValues: true
        },
        fontSize: {
            options: ['default', 8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48, 72],
            supportAllValues: true
        },
        heading: {
            options: [
                {
                    model: 'paragraph',
                    title: 'Paragraph',
                    class: 'ck-heading_paragraph'
                },
                {
                    model: 'heading1',
                    view: 'h1',
                    title: 'Heading 1',
                    class: 'ck-heading_heading1'
                },
                {
                    model: 'heading2',
                    view: 'h2',
                    title: 'Heading 2',
                    class: 'ck-heading_heading2'
                },
                {
                    model: 'heading3',
                    view: 'h3',
                    title: 'Heading 3',
                    class: 'ck-heading_heading3'
                },
                {
                    model: 'heading4',
                    view: 'h4',
                    title: 'Heading 4',
                    class: 'ck-heading_heading4'
                },
                {
                    model: 'heading5',
                    view: 'h5',
                    title: 'Heading 5',
                    class: 'ck-heading_heading5'
                },
                {
                    model: 'heading6',
                    view: 'h6',
                    title: 'Heading 6',
                    class: 'ck-heading_heading6'
                }
            ]
        },
        htmlSupport: {
            allow: [
                {
                    name: /^.*$/,
                    styles: true,
                    attributes: true,
                    classes: true
                }
            ]
        },
        image: {
            toolbar: [
                'toggleImageCaption',
                'imageTextAlternative',
                '|',
                'imageStyle:inline',
                'imageStyle:wrapText',
                'imageStyle:breakText',
                '|',
                'resizeImage'
            ]
        },
        language: 'tr',
        link: {
            addTargetToExternalLinks: true,
            defaultProtocol: 'https://',
            decorators: {
                toggleDownloadable: {
                    mode: 'manual',
                    label: 'Downloadable',
                    attributes: {
                        download: 'file'
                    }
                }
            }
        },
        list: {
            properties: {
                styles: true,
                startIndex: true,
                reversed: true
            }
        },
        mention: {
            feeds: [
                {
                    marker: '@',
                    feed: [
                    ]
                }
            ]
        },
        placeholder: 'Type or paste your content here!',
        style: {
            definitions: [
                {
                    name: 'Article category',
                    element: 'h3',
                    classes: ['category']
                },
                {
                    name: 'Title',
                    element: 'h2',
                    classes: ['document-title']
                },
                {
                    name: 'Subtitle',
                    element: 'h3',
                    classes: ['document-subtitle']
                },
                {
                    name: 'Info box',
                    element: 'p',
                    classes: ['info-box']
                },
                {
                    name: 'Side quote',
                    element: 'blockquote',
                    classes: ['side-quote']
                },
                {
                    name: 'Marker',
                    element: 'span',
                    classes: ['marker']
                },
                {
                    name: 'Spoiler',
                    element: 'span',
                    classes: ['spoiler']
                },
                {
                    name: 'Code (dark)',
                    element: 'pre',
                    classes: ['fancy-code', 'fancy-code-dark']
                },
                {
                    name: 'Code (bright)',
                    element: 'pre',
                    classes: ['fancy-code', 'fancy-code-bright']
                }
            ]
        },
    };

    const toggleSendModal = () => {
        setSendModalOpen(!sendModalOpen);

        if (!sendModalOpen) {
            fetchTemplates();
            setNewEmail({ ...newEmail, templateId: "", content: "" });
        }
    };
    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

    useEffect(() => {
        const loadSystemEmails = async () => {
            try {
                const emails = await getSystemEmails(); // Sistem e-postalarını al
                setMailSettings(emails); // State'e ayarla
            } catch (error) {
                console.error('Sistem e-postaları yüklenirken hata oluştu:', error);
            }
        };

        loadSystemEmails();
        setSelectedSender(); // Varsayılan olarak 1 ID'li sistem mailini seçili yap
    }, [getSystemEmails]);

    useEffect(() => {
        fetchMailSettings(); // mailSettings'i yükler ve günceller
        setSelectedSender(); // Varsayılan olarak 1 ID'li sistem mailini seçili yap
    }, [fetchMailSettings]);

    useEffect(() => {
        if (selectedMailType === "Aktivasyon Maili") {
            getEmailTemplates(1, 300).then((templates) => {
                setEmailTemplates(templates.data);
            });
        }
    }, [selectedMailType, getEmailTemplates]);

    const handleSaveTemplate = async () => {
        try {
            const templateTitle = newEmail.konu; // Modal'daki konu inputundan al
            const templateContent = newEmail.content; // Modal'daki içerik editoründen al
            const templateId = selectedTemplate; // Eğer bir şablon seçiliyse onun ID'sini al

            if (!templateTitle || !templateContent) {
                toast.warn("Kaydetmek için geçerli bir başlık ve içerik girilmelidir.", { position: "top-right", autoClose: 5000 });
                return;
            }

            const response = await saveOrUpdateTemplate(templateId, templateTitle, templateContent);
            if (response.templateId || response.message) {
                toast.success(templateId ? "Tema başarıyla güncellendi." : "Yeni tema başarıyla kaydedildi.", { position: "top-right", autoClose: 5000 });
                setSelectedTemplate(response.templateId); // Eğer yeni oluşturulmuşsa ID'yi güncelle
            } else {
                throw new Error("Yanıt verisi eksik.");
            }
        } catch (error) {
            console.error('Tema kaydedilirken bir hata oluştu:', error);
            toast.error('Tema kaydedilirken hata oluştu.', { position: "top-right", autoClose: 5000 });
        }
    };


    const fetchTemplates = async () => {
        try {
            const data = await getEmailTemplates(1, 200);
            if (data && data.data) {
                console.log("Fetched Email Templates:", data.data);
                setEmailTemplates(data.data);
            } else {
                console.log("No data found for templates");
            }
        } catch (error) {
            console.error("Error fetching templates:", error);
        }
    };

    useEffect(() => {
        console.log("Rendering with mailSettings:", mailSettings);
    }, [mailSettings]);

    useEffect(() => {
        if (newEmail.templateId) {
            const selectedTemplate = emailTemplates.find(
                (template) => template.email_template_id === newEmail.templateId
            );
            setNewEmail((prevState) => ({
                ...prevState,
                konu: selectedTemplate ? selectedTemplate.email_template_title : ""
            }));
        }
    }, [newEmail.templateId, emailTemplates]); // Hem templateId hem de emailTemplates değişikliklerini izler

    const handleSendBulkMail = async () => {
        if (!selectedSender) {
            toast.warn('Lütfen bir gönderici seçin.', { position: "top-right", autoClose: 5000 });
            return;
        }

        if (activeFairUsers.length === 0) {
            toast.warn('Gönderilecek kullanıcı bulunamadı.', { position: "top-right", autoClose: 5000 });
            return;
        }
        if (!newEmail.konu || !newEmail.content) {
            toast.warn('Konu veya içerik boş olamaz.', { position: "top-right", autoClose: 5000 });
            return;
        }

        try {
            for (const user of activeFairUsers) {
                await sendEmail({
                    senderId: selectedSender, // Burada `selectedSender` kullanıyoruz
                    userId: localStorage.getItem('userId'),
                    recipients: [user.user_id], // Kullanıcının ID'sini kullanarak mail gönderiyoruz
                    ccRecipients: [],
                    subject: newEmail.konu,
                    content: newEmail.content.replace("{full_name}", user.full_name)
                });
                console.log(`Mail gönderildi: ${user.full_name}`);
            }
            toast.success('Toplu mailler başarıyla gönderildi.', { position: "top-right", autoClose: 5000 });
            setSendModalOpen(false); // Modalı kapat
        } catch (error) {
            console.error('Mail gönderilirken hata oluştu:', error);
            toast.error('Mail gönderilirken hata oluştu.', { position: "top-right", autoClose: 5000 });
        }
    };

    const handleSendActivationMails = async () => {
        if (activeFairUsers.length > 0 && selectedTemplate) {
            const selectedTemplateData = emailTemplates.find(template => template.email_template_id === parseInt(selectedTemplate));

            if (!selectedTemplateData) {
                toast.error("Seçili şablon bulunamadı. Lütfen geçerli bir şablon seçin.", { position: "top-right", autoClose: 5000 });
                return;
            }

            try {
                for (const user of activeFairUsers) {
                    await sendEmail({
                        senderId: selectedSender, // Seçili gönderici ID'si
                        userId: localStorage.getItem('userId'),
                        recipients: [user.user_id], // User ID'yi gönderiyoruz
                        ccRecipients: [],
                        subject: selectedTemplateData.email_template_title, // Şablonun konusu
                        content: selectedTemplateData.email_template_content.replace("{full_name}", user.full_name) // İçerikte kullanıcının adı dinamik olarak değiştiriliyor
                    });
                    console.log(`Mail gönderildi: ${user.full_name}`);
                }
                toast.success('Aktivasyon mailleri başarıyla gönderildi.', { position: "top-right", autoClose: 5000 });
                setSendModalOpen(false); // Modalı kapat
            } catch (error) {
                console.error('Mail gönderilirken hata oluştu:', error);
                toast.error('Mail gönderilirken hata oluştu.', { position: "top-right", autoClose: 5000 });
            }
        } else {
            toast.warn('Gönderilecek kullanıcı bulunamadı veya şablon seçilmedi.', { position: "top-right", autoClose: 5000 });
        }
    };

    const handleMailTypeChange = async (e) => {
        const mailType = e.target.value;
        setSelectedMailType(mailType);

        if (mailType === "Aktivasyon Maili" || mailType === "Toplu Mail" || mailType === "Filitrelenmiş Mail") {
            const userId = localStorage.getItem('userId');
            const usersList = await fetchActiveFairUsers(userId); // Aynı fuar ID'sine sahip kullanıcıları çek
            if (usersList.length > 0) {
                setActiveFairUsers(usersList);
            } else {
                toast.warn('Gönderilecek kullanıcı bulunamadı.', { position: "top-right", autoClose: 5000 });
            }
        }
    };

    const handleSendFilteredMails = async () => {
        if (!newEmail.sender) {
            toast.warn('Lütfen bir gönderici seçin.', { position: "top-right", autoClose: 5000 });
            return;
        }
        if (users.data.length === 0) {
            toast.warn('Gönderilecek kullanıcı bulunamadı.', { position: "top-right", autoClose: 5000 });
            return;
        }
        if (!newEmail.konu || !newEmail.content) {
            toast.warn('Konu veya içerik boş olamaz.', { position: "top-right", autoClose: 5000 });
            return;
        }

        // Gönderim işlemleri burada devam eder
        try {
            for (const user of users.data) { // Tablodaki kullanıcıları kullanıyoruz
                await sendEmail({
                    senderId: selectedSender,
                    userId: localStorage.getItem('userId'),
                    recipients: [user.user_id], // Kullanıcının ID'sini kullanarak mail gönderiyoruz
                    ccRecipients: [],
                    subject: newEmail.konu,
                    content: newEmail.content.replace("{full_name}", user.full_name)
                });
                console.log(`Mail gönderildi: ${user.full_name}`);
            }
            toast.success('Filtrelenmiş mailler başarıyla gönderildi.', { position: "top-right", autoClose: 5000 });
            setSendModalOpen(false); // Modalı kapat
        } catch (error) {
            console.error('Mail gönderilirken hata oluştu:', error);
            toast.error('Mail gönderilirken hata oluştu.', { position: "top-right", autoClose: 5000 });
        }
    };



    useEffect(() => {
        fetchUsers(page, limit, search);
    }, [page, limit, search]);

    const renderPagination = () => {
        const totalPages = users.totalPages || 1;
        const paginationItems = [];
        const maxPagesToShow = 5;

        for (let i = 1; i <= totalPages; i++) {
            if (totalPages > maxPagesToShow) {
                if (i === 1 || i === totalPages || (i >= page - 1 && i <= page + 1)) {
                    paginationItems.push(
                        <PaginationItem key={i} active={i === page}>
                            <PaginationLink onClick={() => setPage(i)}>{i}</PaginationLink>
                        </PaginationItem>
                    );
                } else if (i === 2 || i === totalPages - 1) {
                    paginationItems.push(
                        <PaginationItem key={i} disabled>
                            <PaginationLink>...</PaginationLink>
                        </PaginationItem>
                    );
                }
            } else {
                paginationItems.push(
                    <PaginationItem key={i} active={i === page}>
                        <PaginationLink onClick={() => setPage(i)}>{i}</PaginationLink>
                    </PaginationItem>
                );
            }
        }
        return paginationItems;
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { day: 'numeric', month: 'long', year: 'numeric' };
        const timeOptions = { hour: '2-digit', minute: '2-digit' };
        return `${date.toLocaleDateString('tr-TR', options)} - ${date.toLocaleTimeString('tr-TR', timeOptions)}`;
    };

    const handleTemplateChange = (e) => {
        const selectedTemplateId = parseInt(e.target.value, 10);
        const selectedTemplate = emailTemplates.find(
            (template) => template.email_template_id === selectedTemplateId
        );

        setNewEmail((prevState) => ({
            ...prevState,
            templateId: selectedTemplateId,
            konu: selectedTemplate ? selectedTemplate.email_template_title : "",
            content: selectedTemplate ? selectedTemplate.email_template_content : ""
        }));

        setSelectedTemplate(selectedTemplateId); // Seçilen şablonun ID'sini kaydet

        // Log ekleyelim
        console.log("Şablon Seçildi:", {
            selectedTemplateId,
            title: selectedTemplate ? selectedTemplate.email_template_title : "",
            content: selectedTemplate ? selectedTemplate.email_template_content : ""
        });
    };


    return (
        <div className="page-content">
            <Container fluid>
                <BreadCrumb title="Kullanıcılar" />
                <Card className="p-3">
                    <Row className="d-flex justify-content-between">
                        <Col md="4" className="d-flex">
                            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                                <DropdownToggle caret>
                                    Gösterim: {limit}
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={() => setLimit(1)}>1</DropdownItem>
                                    <DropdownItem onClick={() => setLimit(10)}>10</DropdownItem>
                                    <DropdownItem onClick={() => setLimit(25)}>25</DropdownItem>
                                    <DropdownItem onClick={() => setLimit(50)}>50</DropdownItem>
                                    <DropdownItem onClick={() => setLimit(100)}>100</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                            <Input
                                type="text"
                                placeholder="Arama..."
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                className="ms-3"
                            />
                        </Col>
                        <Col md="auto" className="d-flex justify-content-end">
                            <Button
                                color="primary"
                                onClick={() => setViewMode(viewMode === "grid" ? "list" : "grid")}
                                className="me-2"
                            >
                                <i className={`bx ${viewMode === "grid" ? "bx-list-ul" : "bx-grid-alt"} me-2`}></i>
                                {viewMode === "grid" ? "Liste Modu" : "Grid Modu"}
                            </Button>
                            <Button color="info" className="me-2" onClick={toggleSendModal}>
                                <i className='bx bx-mail-send fs-16'></i>
                            </Button>
                            <Button color="secondary">Kullanıcı Ekle</Button>
                        </Col>
                    </Row>
                </Card>
                <Card className="pt-3 pe-3 ps-3">
                    {loading ? (
                        <div>Yükleniyor...</div>
                    ) : (
<>
    {viewMode === "grid" ? (
        <Row>
            {users.data?.map((user) => (
                <Col md="3" sm="6" className="mb-4" key={user.user_id}>
                    <Card
                        className="ribbon-box border ribbon-fill shadow-sm"
                        style={{
                            borderRadius: "8px",
                            position: "relative",
                            transition: "transform 0.2s ease-in-out",
                        }}
                        onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.05)")}
                        onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
                    >
                        {/* Ribbon */}
                        <div
                            className={`ribbon ${user.user_active ? "ribbon-success" : "ribbon-danger"}`}
                        >
                            <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                                {user.user_active ? "Aktif" : "Pasif"}
                            </span>
                        </div>

                        {/* Kullanıcı Avatar */}
                        <CardBody className="text-center">
                            <div
                                style={{
                                    width: "80px",
                                    height: "80px",
                                    borderRadius: "50%",
                                    backgroundColor: user.user_active ? "#28a745" : "#dc3545",
                                    color: "white",
                                    fontSize: "24px",
                                    fontWeight: "bold",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    margin: "0 auto 15px",
                                }}
                            >
                                {user.full_name
                                    ?.split(" ")
                                    .map((name) => name[0])
                                    .join("")
                                    .toUpperCase()}
                            </div>

                            {/* Kullanıcı Bilgileri */}
                            <h5 className="mb-2">{user.full_name}</h5>
                            <p className="mb-1 text-muted">
                                <strong>Email:</strong> {user.user_email}
                            </p>
                            <p className="mb-1 text-muted">
                                <strong>Telefon:</strong> {user.user_phone}
                            </p>
                            <p className="mb-1 text-muted">
                                <strong>Ünvan:</strong> {user.user_title}
                            </p>
                            <p className="mb-1 text-muted">
                                <strong>Firma:</strong> {user.firma_name}
                            </p>
                            <Button
                                color="primary"
                                size="sm"
                                className="mt-3"
                                onClick={() => console.log("Kullanıcı düzenleme")}
                            >
                                Düzenle
                            </Button>
                        </CardBody>
                    </Card>
                </Col>
            ))}
        </Row>
    ) : (
        <Row className="ps-3 pe-3">
            {users.data?.map((user) => (
                <Card
                    key={user.user_id}
                    className="mb-3 p-3 d-flex flex-row align-items-center ribbon-box border ribbon-fill shadow-sm"
                    style={{
                        borderRadius: "8px",
                        position: "relative",
                        transition: "transform 0.2s ease-in-out",
                    }}
                    onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.02)")}
                    onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
                >
                    {/* Ribbon */}
                    <div
                        className={`ribbon ${user.user_active ? "ribbon-success" : "ribbon-danger"}`}
                    >
                        <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                            {user.user_active ? "Aktif" : "Pasif"}
                        </span>
                    </div>

                    {/* Kullanıcı Avatar */}
                    <div
                        style={{
                            width: "50px",
                            height: "50px",
                            borderRadius: "50%",
                            backgroundColor: user.user_active ? "#28a745" : "#dc3545",
                            color: "white",
                            fontSize: "18px",
                            fontWeight: "bold",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginRight: "15px",
                        }}
                    >
                        {user.full_name
                            ?.split(" ")
                            .map((name) => name[0])
                            .join("")
                            .toUpperCase()}
                    </div>

                    {/* Kullanıcı Bilgileri */}
                    <div className="flex-grow-1">
                        <p className="mb-1 text-muted">
                            <strong>Adı Soyadı:</strong> {user.full_name}
                        </p>
                        <p className="mb-1 text-muted">
                            <strong>Email:</strong> {user.user_email}
                        </p>
                        <p className="mb-1 text-muted">
                            <strong>Telefon:</strong> {user.user_phone}
                        </p>
                        <p className="mb-1 text-muted">
                            <strong>Ünvan:</strong> {user.user_title}
                        </p>
                        <p className="mb-1 text-muted">
                            <strong>Firma:</strong> {user.firma_name}
                        </p>
                    </div>

                    <Button
                        color="primary"
                        size="sm"
                        onClick={() => console.log("Kullanıcı düzenleme")}
                    >
                        Düzenle
                    </Button>
                </Card>
            ))}
        </Row>
    )}
</>

                    )}
                </Card>

                <Pagination className="pagination justify-content-center mt-3">
                    {renderPagination()}
                </Pagination>

                <Modal isOpen={sendModalOpen} toggle={toggleSendModal} size="xl">
                    <ModalHeader toggle={toggleSendModal}>Mail Gönder</ModalHeader>
                    <ModalBody style={{ maxHeight: '400px', overflowY: 'auto' }}>
                        <Row className="mb-3">
                            <Col md="12">
                                <label>Mail Türü</label>
                                <Input type="select" onChange={handleMailTypeChange} value={selectedMailType}>
                                    <option value="">Seçiniz</option>
                                    <option value="Aktivasyon Maili">Aktivasyon Maili</option>
                                    <option value="Toplu Mail">Toplu Mail</option>
                                    <option value="Filitrelenmiş Mail">Filitrelenmiş Mail</option>
                                </Input>
                            </Col>
                        </Row>
                        {selectedMailType === "Filitrelenmiş Mail" && (
                            <>
                                <Row className="mb-3">
                                    <Col md="12">
                                        <label>Filtrelenmiş Mail Alıcıları (Tablodaki Kullanıcılar)</label>
                                        <Input
                                            type="text"
                                            value={users.data.map(user => user.full_name).join(', ')}
                                            disabled
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col md="12">
                                        <label>Email Temaları</label>
                                        <Input
                                            type="select"
                                            value={newEmail.email_template_title}
                                            onChange={handleTemplateChange}
                                        >
                                            <option value="">Şablon Seçin</option>
                                            {emailTemplates.map((template) => (
                                                <option key={`template-${template.email_template_id}`} value={template.email_template_id}>
                                                    {template.email_template_title || "Başlık Yok"}
                                                </option>
                                            ))}
                                        </Input>
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col md="12">
                                        <label>Kimden</label>
                                        <Input
                                            type="select"
                                            value={newEmail.sender}
                                            onChange={(e) => setNewEmail({ ...newEmail, sender: e.target.value })}
                                        >
                                            <option value="">Kimden Seçin</option>
                                            {systemEmails.map((email) => (
                                                <option key={email.system_email_id} value={email.system_email_value}>
                                                    {email.system_email_title} [{email.system_email_value}]
                                                </option>
                                            ))}
                                        </Input>
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col md="12">
                                        <label>Konu</label>
                                        <Input
                                            type="text"
                                            placeholder="Konu"
                                            value={newEmail.konu}
                                            onChange={(e) => setNewEmail({ ...newEmail, konu: e.target.value })}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col md="12">
                                        <label>Mail İçeriği</label>
                                        <CKEditor
                                            editor={ClassicEditor}
                                            config={editorConfig}
                                            data={newEmail.content}
                                            onChange={(event, editor) => setNewEmail({ ...newEmail, content: editor.getData() })}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col md="12" className="text-end">
                                        <Button color="primary" size="sm" onClick={handleSaveTemplate}>Temayı Kaydet</Button>
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col sm="12" md="12">
                                        <Button color="primary" onClick={handleSendFilteredMails} style={{ width: '100%' }}>
                                            Filtrelenmiş Mail Gönder
                                        </Button>
                                    </Col>
                                </Row>
                            </>
                        )}

                        {selectedMailType === "Toplu Mail" && (
                            <>
                                <Row className="mb-3">
                                    <Col md="12">
                                        <label>Toplu Mail Alıcıları (Seçili Kullanıcılar)</label>
                                        <Input
                                            type="text"
                                            value={activeFairUsers.map(user => user.full_name).join(', ')}
                                            disabled
                                        />                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col md="12">
                                        <label>Email Temaları</label>
                                        <Input
                                            type="select"
                                            value={newEmail.email_template_title}
                                            onChange={handleTemplateChange}
                                        >
                                            <option value="">Şablon Seçin</option>
                                            {emailTemplates.map((template) => (
                                                <option key={`template-${template.email_template_id}`} value={template.email_template_id}>
                                                    {template.email_template_title || "Başlık Yok"}
                                                </option>
                                            ))}
                                        </Input>
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col md="12">
                                        <label>Kimden</label>
                                        <Input
                                            type="select"
                                            value={newEmail.sender}
                                            onChange={(e) => setNewEmail({ ...newEmail, sender: e.target.value })}
                                        >
                                            <option value="">Kimden Seçin</option>
                                            {systemEmails.map((email) => (
                                                <option key={email.system_email_id} value={email.system_email_value}>
                                                    {email.system_email_title} [{email.system_email_value}]
                                                </option>
                                            ))}
                                        </Input>
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col md="12">
                                        <label>Konu</label>
                                        <Input
                                            type="text"
                                            placeholder="Konu"
                                            value={newEmail.konu} // 'konu' değil, 'subject' olarak bağlandı
                                            onChange={(e) => setNewEmail({ ...newEmail, konu: e.target.value })}
                                        />

                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col md="12">
                                        <label>Mail İçeriği</label>
                                        <CKEditor
                                            editor={ClassicEditor}
                                            config={editorConfig}
                                            data={newEmail.content}
                                            onChange={(event, editor) => setNewEmail({ ...newEmail, content: editor.getData() })}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col md="12" className="text-end">
                                        <Button color="primary" size="sm" onClick={handleSaveTemplate}>Temayı Kaydet</Button>
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col sm="12" md="12">
                                        <Button color="primary" onClick={handleSendBulkMail} style={{ width: '100%' }}>
                                            Toplu Mail Gönder
                                        </Button>
                                    </Col>
                                </Row>
                            </>
                        )}
                        {selectedMailType === "Aktivasyon Maili" && (
                            <>
                                <Row className="mb-3">
                                    <Col md="12">
                                        <label>Email Şablonu</label>
                                        <Input disabled type="select" value={selectedTemplate} onChange={(e) => setSelectedTemplate(e.target.value)}>
                                            {emailTemplates.length > 0 ? (
                                                emailTemplates.map(template => (
                                                    <option key={template.email_template_id} value={template.email_template_id}>
                                                        {template.email_template_title}
                                                    </option>
                                                ))
                                            ) : (
                                                <option value="">Şablon bulunamadı</option>
                                            )}
                                        </Input>
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col md="12">
                                        <label>Gönderen</label>
                                        <Input disabled type="select" value={selectedSender} onChange={(e) => setSelectedSender(e.target.value)}>
                                            <option value="">Gönderen Seçin</option>
                                            {Array.isArray(mailSettings.data) && mailSettings.data.length > 0 ? (
                                                mailSettings.data.map((setting) => (
                                                    <option key={setting.system_email_id} value={setting.system_email_id}>
                                                        {setting.system_email_title} [{setting.system_email_value}]
                                                    </option>
                                                ))
                                            ) : (
                                                <option value="">Gönderen bilgisi bulunamadı</option>
                                            )}
                                        </Input>
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col sm="12" md="12">
                                        <Button color="primary" onClick={handleSendActivationMails} style={{ width: '100%' }}>
                                            Aktivasyon Maili Gönder
                                        </Button>
                                    </Col>
                                </Row>
                            </>
                        )}
                    </ModalBody>
                </Modal>
                <ToastContainer />
            </Container>
        </div>
    );
};

export default Users;
