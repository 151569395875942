import React, { useEffect, useCallback, useState, useContext } from 'react';
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Collapse } from 'reactstrap';
import navdata from "../LayoutMenuData";
import withRouter from "../../Components/Common/withRouter";
import { useSelector } from "react-redux";
import { createSelector } from 'reselect';
import MainContext from '../../Components/Contexts/MainContext'; // MainContext'i import ediyoruz

const VerticalLayout = (props) => {
  const navData = navdata().props.children;
  const [userGroup, setUserGroup] = useState(null);
  const [loadingGroup, setLoadingGroup] = useState(true);

  // MainContext'ten getUserGroupContext fonksiyonunu çekiyoruz
  const { getUserGroupContext, api } = useContext(MainContext);

  useEffect(() => {
    const fetchUserGroup = async () => {
      try {
        const userId = localStorage.getItem('userId');
        const groupData = await getUserGroupContext(userId);
        if (groupData && (groupData.id || (groupData.userGroupEntity && groupData.userGroupEntity.id))) {
          const groupId = groupData.id || groupData.userGroupEntity.id;
          setUserGroup(groupId);
        } else {
          console.log("User Group ID not found in groupData");
        }
      } catch (error) {
        console.error('API Error:', error);
      } finally {
        setLoadingGroup(false);
      }
    };

    fetchUserGroup();
  }, [getUserGroupContext]);

  const filterNavDataByConfig = (navData, userGroup) => {
    const groupConfig = api.groups[userGroup] || api.groups.default; // Burada config yerine api.groups kullanıyoruz
    if (groupConfig.visibleItems === '*') return navData;
    return navData.filter(item => groupConfig.visibleItems.includes(item.id));
  };


  const filteredNavData = filterNavDataByConfig(navData, userGroup);

  const selectLayoutState = (state) => state.Layout;
  const selectLayoutProperties = createSelector(
    selectLayoutState,
    (layout) => ({
      leftsidbarSizeType: layout.leftsidbarSizeType,
      sidebarVisibilitytype: layout.sidebarVisibilitytype,
      layoutType: layout.layoutType
    })
  );
  const {
    leftsidbarSizeType, sidebarVisibilitytype, layoutType
  } = useSelector(selectLayoutProperties);

  const resizeSidebarMenu = useCallback(() => {
    var windowSize = document.documentElement.clientWidth;
    if (windowSize >= 1025) {
      if (document.documentElement.getAttribute("data-layout") === "vertical") {
        document.documentElement.setAttribute("data-sidebar-size", leftsidbarSizeType);
      }
      if (document.documentElement.getAttribute("data-layout") === "semibox") {
        document.documentElement.setAttribute("data-sidebar-size", leftsidbarSizeType);
      }
      if ((sidebarVisibilitytype === "show" || layoutType === "vertical" || layoutType === "twocolumn") && document.querySelector(".hamburger-icon")) {
        var hamburgerIcon = document.querySelector(".hamburger-icon");
        if (hamburgerIcon !== null) {
          hamburgerIcon.classList.remove("open");
        }
      } else {
        var hamburgerIcon = document.querySelector(".hamburger-icon");
        if (hamburgerIcon !== null) {
          hamburgerIcon.classList.add("open");
        }
      }

    } else if (windowSize < 1025 && windowSize > 767) {
      document.body.classList.remove("twocolumn-panel");
      if (document.documentElement.getAttribute("data-layout") === "vertical") {
        document.documentElement.setAttribute("data-sidebar-size", "sm");
      }
      if (document.documentElement.getAttribute("data-layout") === "semibox") {
        document.documentElement.setAttribute("data-sidebar-size", "sm");
      }
      if (document.querySelector(".hamburger-icon")) {
        document.querySelector(".hamburger-icon").classList.add("open");
      }
    } else if (windowSize <= 767) {
      document.body.classList.remove("vertical-sidebar-enable");
      if (document.documentElement.getAttribute("data-layout") !== "horizontal") {
        document.documentElement.setAttribute("data-sidebar-size", "lg");
      }
      if (document.querySelector(".hamburger-icon")) {
        document.querySelector(".hamburger-icon").classList.add("open");
      }
    }
  }, [leftsidbarSizeType, sidebarVisibilitytype, layoutType]);

  useEffect(() => {
    window.addEventListener("resize", resizeSidebarMenu, true);
  }, [resizeSidebarMenu]);

  const path = props.router.location.pathname;

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    const initMenu = () => {
      const pathName = process.env.PUBLIC_URL + path;
      const ul = document.getElementById("navbar-nav");
      const items = ul.getElementsByTagName("a");
      let itemsArray = [...items];
      removeActivation(itemsArray);
      let matchingMenuItem = itemsArray.find((x) => {
        return x.pathname === pathName;
      });
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    if (props.layoutType === "vertical") {
      initMenu();
    }
  }, [path, props.layoutType]);

  function activateParentDropdown(item) {
    item.classList.add("active");
    let parentCollapseDiv = item.closest(".collapse.menu-dropdown");

    if (parentCollapseDiv) {
      parentCollapseDiv.classList.add("show");
      parentCollapseDiv.parentElement.children[0].classList.add("active");
      parentCollapseDiv.parentElement.children[0].setAttribute("aria-expanded", "true");
      if (parentCollapseDiv.parentElement.closest(".collapse.menu-dropdown")) {
        parentCollapseDiv.parentElement.closest(".collapse").classList.add("show");
        if (parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling)
          parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling.classList.add("active");
        if (parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling.closest(".collapse")) {
          parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling.closest(".collapse").classList.add("show");
          parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling.closest(".collapse").previousElementSibling.classList.add("active");
        }
      }
      return false;
    }
    return false;
  }

  const removeActivation = (items) => {
    let actiItems = items.filter((x) => x.classList.contains("active"));

    actiItems.forEach((item) => {
      if (item.classList.contains("menu-link")) {
        if (!item.classList.contains("active")) {
          item.setAttribute("aria-expanded", false);
        }
        if (item.nextElementSibling) {
          item.nextElementSibling.classList.remove("show");
        }
      }
      if (item.classList.contains("nav-link")) {
        if (item.nextElementSibling) {
          item.nextElementSibling.classList.remove("show");
        }
        item.setAttribute("aria-expanded", false);
      }
      item.classList.remove("active");
    });
  };

  if (loadingGroup) {
    return <div>Loading...</div>;
  }

  return (
    <React.Fragment>
      {(filteredNavData || []).map((item, key) => {
        return (
          <React.Fragment key={key}>
            {item['isHeader'] ? (
              <li className="menu-title"><span data-key="t-menu">{(item.label)} </span></li>
            ) : (
              item.subItems ? (
                <li className="nav-item">
                  <Link
                    onClick={item.click}
                    className="nav-link menu-link"
                    to={item.link ? item.link : "/#"}
                    data-bs-toggle="collapse"
                  >
                    <i className={item.icon}></i>
                    <span data-key="t-apps">{(item.label)}</span>
                    {item.badgeName && (
                      <span className={"badge badge-pill bg-" + item.badgeColor} data-key="t-new">{item.badgeName}</span>
                    )}
                  </Link>
                  <Collapse
                    className="menu-dropdown"
                    isOpen={item.stateVariables}
                    id="sidebarApps">
                    <ul className="nav nav-sm flex-column test">
                      {item.subItems && (item.subItems || []).map((subItem, key) => (
                        <React.Fragment key={key}>
                          {!subItem.isChildItem ? (
                            <li className="nav-item">
                              <Link
                                to={subItem.link ? subItem.link : "/#"}
                                className="nav-link"
                              >
                                {(subItem.label)}
                                {subItem.badgeName && (
                                  <span className={"badge badge-pill bg-" + subItem.badgeColor} data-key="t-new">{subItem.badgeName}</span>
                                )}
                              </Link>
                            </li>
                          ) : (
                            <li className="nav-item">
                              <Link
                                onClick={subItem.click}
                                className="nav-link"
                                to="/#"
                                data-bs-toggle="collapse"
                              >
                                {(subItem.label)}
                                {subItem.badgeName && (
                                  <span className={"badge badge-pill bg-" + subItem.badgeColor} data-key="t-new">{subItem.badgeName}</span>
                                )}
                              </Link>
                              <Collapse className="menu-dropdown" isOpen={subItem.stateVariables} id="sidebarEcommerce">
                                <ul className="nav nav-sm flex-column">
                                  {subItem.childItems && (subItem.childItems || []).map((childItem, key) => (
                                    <React.Fragment key={key}>
                                      {!childItem.childItems ? (
                                        <li className="nav-item">
                                          <Link
                                            to={childItem.link ? childItem.link : "/#"}
                                            className="nav-link">
                                            {(childItem.label)}
                                          </Link>
                                        </li>
                                      ) : (
                                        <li className="nav-item">
                                          <Link to="/#" className="nav-link" onClick={childItem.click} data-bs-toggle="collapse">
                                            {(childItem.label)}
                                          </Link>
                                          <Collapse className="menu-dropdown" isOpen={childItem.stateVariables} id="sidebaremailTemplates">
                                            <ul className="nav nav-sm flex-column">
                                              {childItem.childItems.map((subChildItem, key) => (
                                                <li className="nav-item" key={key}>
                                                  <Link to={subChildItem.link} className="nav-link" data-key="t-basic-action">{(subChildItem.label)} </Link>
                                                </li>
                                              ))}
                                            </ul>
                                          </Collapse>
                                        </li>
                                      )}
                                    </React.Fragment>
                                  ))}
                                </ul>
                              </Collapse>
                            </li>
                          )}
                        </React.Fragment>
                      ))}
                    </ul>
                  </Collapse>
                </li>
              ) : (
                <li className="nav-item">
                  <Link
                    className="nav-link menu-link"
                    to={item.link ? item.link : "/#"}>
                    <i className={item.icon}></i> <span>{(item.label)}</span>
                    {item.badgeName && (
                      <span className={"badge badge-pill bg-" + item.badgeColor} data-key="t-new">{item.badgeName}</span>
                    )}
                  </Link>
                </li>
              )
            )}
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

VerticalLayout.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter((VerticalLayout));
