import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Alert, Spinner, Form } from 'reactstrap';
import MainContext from '../../../Components/Contexts/MainContext';
import logoLight from "../../../assets/images/logo-light.png";

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [passwordShow, setPasswordShow] = useState(false);
    const navigate = useNavigate();

    const { handleLoginContext } = useContext(MainContext);

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        try {
            await handleLoginContext(username, password);
            navigate('/Dashboard');
        } catch (err) {
            setError(err.message || 'An error occurred during login');
        } finally {
            setLoading(false);
        }
    };

    document.title = "Giriş Yap | BST";

    return (
        <React.Fragment>
            <div className="auth-page-wrapper pt-5">
                <div className="auth-one-bg-position auth-one-bg" id="auth-particles">
                    <div className="bg-overlay"></div>
                    <div className="shape">
                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 1440 120">
                            <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"></path>
                        </svg>
                    </div>

                    <div className="auth-page-content mt-lg-5">                
                        <Container>
                            <Row>
                                <Col lg={12}>
                                    <div className="text-center mt-sm-5 mb-4 text-white-50">
                                        <div>
                                            <Link to="/" className="d-inline-block auth-logo">
                                                <img src={logoLight} alt="" height="100" />
                                            </Link>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <Row className="justify-content-center">
                                <Col md={8} lg={6} xl={5}>
                                    <Card className="mt-4 card-bg-fill">
                                        <CardBody className="p-4">
                                            <div className="text-center mt-2">
                                                <h5 className="text-body">Tekrar Hoşgeldiniz</h5>
                                                <p className="text-muted">Fuar Denetim Sistemine devam etmek için oturum açın.</p>
                                            </div>
                                            {error && <Alert color="danger">{error}</Alert>}
                                            <div className="p-2">
                                                <Form onSubmit={handleLogin}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="username" className="form-label">Kullanıcı Adı</Label>
                                                        <Input
                                                            name="username"
                                                            className="form-control"
                                                            placeholder="Kullanıcı Adı"
                                                            type="text"
                                                            value={username}
                                                            onChange={(e) => setUsername(e.target.value)}
                                                            invalid={!!error}
                                                        />
                                                    </div>

                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="password-input">Şifre</Label>
                                                        <div className="position-relative auth-pass-inputgroup mb-3">
                                                            <Input
                                                                name="password"
                                                                value={password}
                                                                type={passwordShow ? "text" : "password"}
                                                                className="form-control pe-5"
                                                                placeholder="Şifre"
                                                                onChange={(e) => setPassword(e.target.value)}
                                                                invalid={!!error}
                                                            />
                                                            <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" id="password-addon" onClick={() => setPasswordShow(!passwordShow)}><i className="ri-eye-fill align-middle"></i></button>
                                                        </div>
                                                    </div>

                                                    <div className="form-check">
                                                        <Input className="form-check-input" type="checkbox" value="" id="auth-remember-check" />
                                                        <Label className="form-check-label" htmlFor="auth-remember-check">Beni Hatırla</Label>
                                                    </div>

                                                    <div className="mt-4">
                                                        <Button color="primary" disabled={loading} className="btn btn-primary w-100" type="submit">
                                                            {loading ? <Spinner size="sm" className='me-2'> Yükleniyor... </Spinner> : 'Giriş Yap'}
                                                        </Button>
                                                    </div>
                                                </Form>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Login;
