import React, { useContext, useEffect, useState } from "react";
import { Container, Row, Col, Card, Table, Input, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button, Pagination, PaginationItem, PaginationLink, Modal, ModalBody, ModalHeader, } from "reactstrap";
import { LanguageContext } from "../../../../../Components/Contexts/LanguageContext";
import BetterStandsContext from "../../../../../Components/Contexts/Management/BetterStandsContext";
import EvetImg from "../../../../../assets/images/Evet.png";
import HayirImg from "../../../../../assets/images/Hayır.png";
import YokImg from "../../../../../assets/images/Yok.png";
import BelirtilmediImg from "../../../../../assets/images/Belirtilmedi.png";
import GörselImg from "../../../../../assets/images/Görsel.png";
import BreadCrumb from "../../../../../Components/Common/BreadCrumb";
import ReactApexChart from "react-apexcharts";
import * as XLSX from "xlsx";

const Reports = () => {
    document.title = "BTS Raporlar | FDS";
    const { selectedLang } = useContext(LanguageContext);
    const { handleRemovePhotoo, stands, fuarZamani, fetchActiveStands, salons, fetchReport, loading, totalPages, fetchCategoryPhotos, saveReport, fetchSalons, updatePhotoCategory, activeFairId, uploadPhoto, downloadPhotos, fetchActiveFairId } = useContext(BetterStandsContext);

    const userId = localStorage.getItem("userId");
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [search, setSearch] = useState("");
    const [rotation, setRotation] = useState(0);
    const [salonFilter, setSalonFilter] = useState(null);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [salonDropdownOpen, setSalonDropdownOpen] = useState(false);
    const [standsWithPhotoCounts, setStandsWithPhotoCounts] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: "stand_name", direction: "asc" });
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedStand, setSelectedStand] = useState(null);
    const [selectedPhoto, setSelectedPhoto] = useState(null); // Önizleme yapılacak fotoğraf
    const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false); // Büyük modal durumu

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedCategoryPhotos, setSelectedCategoryPhotos] = useState([]); // Seçili kategori görselleri
    const [unmatchedPhotos, setUnmatchedPhotos] = useState([]); // Eşlenmemiş görseller
    const [isStatisticsModalOpen, setIsStatisticsModalOpen] = useState(false); // Modal durumu
    const [statisticsData, setStatisticsData] = useState({
        gold: 0,
        silver: 0,
        bronze: 0,
        disposable: 0,
        unknown: 0,
    });
    const [fairAndTearDownStatistics, setFairAndTearDownStatistics] = useState({
        fairStatus: { gray: 0, red: 0, yellow: 0, green: 0, blue: 0 },
        tearDownStatus: { gray: 0, red: 0, yellow: 0, green: 0, blue: 0 },
    });

    const sortedStands = [...standsWithPhotoCounts].sort((a, b) => {
        const order = sortConfig.direction === "asc" ? 1 : -1;
        if (a[sortConfig.key] < b[sortConfig.key]) return -1 * order;
        if (a[sortConfig.key] > b[sortConfig.key]) return 1 * order;
        return 0;
    });

    const handleSort = (key) => {
        setSortConfig((prevState) => ({
            key,
            direction: prevState.key === key && prevState.direction === "asc" ? "desc" : "asc",
        }));
    };


    const toggleSalonDropdown = () => setSalonDropdownOpen(!salonDropdownOpen);

    const categories = [
        { id: 11, key: "ana_fotograf", tr: "Ana Fotoğraf", en: "Main Photo" },
        { id: 1, key: "stant_yapisi_ve_duvarlar", tr: "Stand Yapısı ve Duvarlar", en: "Stand Structure and Walls" },
        { id: 2, key: "platform", tr: "Platform", en: "Platform" },
        { id: 3, key: "mobilya_ve_techizat", tr: "Mobilya ve Teçhizat", en: "Furniture and Equipment" },
        { id: 4, key: "aydinlatma", tr: "Aydınlatma", en: "Lighting" },
        { id: 5, key: "alinlik_ve_bas_ustu", tr: "Alınlık ve Baş Üstü", en: "Fascia and Overhead Signage" },
        { id: 6, key: "donatilmis_yapi", tr: "Donatılmış Yapı", en: "Equipped Structure" },
        { id: 7, key: "tavan", tr: "Tavan", en: "Ceiling" },
        { id: 8, key: "teshir_bilesenleri", tr: "Teşhir Bileşenleri", en: "Display facilities" },
        { id: 9, key: "zemin", tr: "Zemin", en: "Floor" },
        { id: 10, key: "grafikler_dekoratifler", tr: "Grafikler ve Dekoratifler", en: "Graphics and Decorations" },
    ];

    const apiurl = "https://e.sakagrup.com";
    const apiport = "";
    const baseApiUrl = `${apiurl}:${apiport}`;

    useEffect(() => {
        if (userId) {
            fetchActiveFairId(userId);
        }
    }, [userId, fetchActiveFairId]);

    useEffect(() => {
        if (userId && activeFairId) {
            fetchSalons(); // fetchSalons sadece activeFairId mevcutsa çağrılır
        }
    }, [userId, activeFairId, fetchSalons]);

    useEffect(() => {
        if (userId) {
            fetchActiveStands(page - 1, limit, salonFilter, search);
        }
    }, [userId, page, limit, salonFilter, fetchActiveStands, search]);

    useEffect(() => {
        if (stands.length > 0) {
            fetchStandsPhotoCounts();
        }
    }, [stands]);

    const getSelectedSalonName = () => {
        if (salonFilter === null) {
            return selectedLang === 'tr' ? "Tümü" : "All";
        }
        const selectedSalon = salons.find(salon => salon.salon_id === salonFilter);
        return selectedSalon ? selectedSalon.salon_name : '';
    };

    const determineStatus = (reportData) => {
        const primaryCategories = [
            "stant_yapisi_ve_duvarlar",
            "platform",
            "mobilya_ve_techizat",
            "aydinlatma"
        ];
        const secondaryCategories = [
            "alinlik_ve_bas_ustu",
            "tavan",
            "teshir_bilesenleri"
        ];
        const finalCategories = [
            "zemin",
            "grafikler_dekoratifler"
        ];

        // Primary kategorileri kontrol et
        const primaryNull = primaryCategories.some(category => reportData[category] === null);
        const primaryHasNo = primaryCategories.some(category => reportData[category] === "Hayır");
        const primaryAllYesOrNone = primaryCategories.every(
            category => reportData[category] === "Evet" || reportData[category] === "Yok"
        );

        if (primaryNull) return selectedLang === "tr" ? "Bilinmiyor" : "Unknown";
        if (primaryHasNo) return selectedLang === "tr" ? "Çöp" : "Disposable";
        if (!primaryAllYesOrNone) return selectedLang === "tr" ? "Bilinmiyor" : "Unknown";

        // Bronz statüsünü kontrol et
        const secondaryNull = secondaryCategories.some(category => reportData[category] === null);
        const secondaryAllYesOrNone = secondaryCategories.every(
            category => reportData[category] === "Evet" || reportData[category] === "Yok"
        );

        if (secondaryNull) return selectedLang === "tr" ? "Bronz" : "Bronze";
        if (!secondaryAllYesOrNone) return selectedLang === "tr" ? "Bronz" : "Bronze";

        // Gümüş statüsünü kontrol et
        const finalAllYesOrNone = finalCategories.every(
            category => reportData[category] === "Evet" || reportData[category] === "Yok"
        );

        if (finalAllYesOrNone) return selectedLang === "tr" ? "Altın" : "Gold";

        return selectedLang === "tr" ? "Gümüş" : "Silver";
    };

    const exportTableToExcel = () => {
        const translateValue = (value) => {
            if (value === "Evet") return selectedLang === "tr" ? "Evet" : "Yes";
            if (value === "Hayır") return selectedLang === "tr" ? "Hayır" : "No";
            if (value === "Yok") return selectedLang === "tr" ? "Yok" : "None";
            return selectedLang === "tr" ? "Bilinmiyor" : "Unknown"; // Varsayılan değer
        };

        const tableData = standsWithPhotoCounts.map((stand) => {
            const fairStatus = calculateBadgeStatus(stand.photoCounts, stand.reportData, "beforeFair");
            const tearDownStatus = calculateBadgeStatus(stand.photoCounts, stand.reportData, "afterFair");

            const statusMap = {
                gray: selectedLang === "tr" ? "Rapor Yok" : "No Report",
                red: selectedLang === "tr" ? "Eksik İşaretleme" : "Incomplete",
                green: selectedLang === "tr" ? "5 Kategori Tamam" : "5 Categories Done",
                blue: selectedLang === "tr" ? "Tüm Kategoriler Tamam" : "All Categories Done",
            };

            return {
                [selectedLang === "tr" ? "Statü" : "Status"]: determineStatus(stand.reportData),
                [selectedLang === "tr" ? "Ana Fotoğraf" : "Main Photo"]:
                    stand.photoCounts["ana_fotograf"]?.beforeFair > 0
                        ? selectedLang === "tr" ? "Var" : "Available"
                        : selectedLang === "tr" ? "Yok" : "Not Available",
                [selectedLang === "tr" ? "Numara" : "Number"]: stand.stand_name,
                [selectedLang === "tr" ? "Salon" : "Hall"]: stand.hall,
                [selectedLang === "tr" ? "İsim" : "Name"]: stand.name,
                [selectedLang === "tr" ? "Fuar" : "Fair"]: statusMap[fairStatus] || (selectedLang === "tr" ? "Bilinmiyor" : "Unknown"),
                [selectedLang === "tr" ? "Söküm" : "Breakdown"]: statusMap[tearDownStatus] || (selectedLang === "tr" ? "Bilinmiyor" : "Unknown"),
                [selectedLang === "tr" ? "Tür" : "Type"]:
                    stand.type === "Ahşap" ? (selectedLang === "tr" ? "Ahşap" : "Wooden") :
                    stand.type === "Modüler" ? (selectedLang === "tr" ? "Modüler" : "Modular") :
                    stand.type === "Paket" ? (selectedLang === "tr" ? "Paket" : "Package") :
                            (selectedLang === "tr" ? "Diğer" : "Other"),
                [selectedLang === "tr" ? "Stand Yapısı ve Duvarlar" : "Stand Structure and Walls"]: translateValue(stand.reportData.stant_yapisi_ve_duvarlar),
                [selectedLang === "tr" ? "Platform" : "Platform"]: translateValue(stand.reportData.platform),
                [selectedLang === "tr" ? "Mobilya ve Teçhizat" : "Furniture and Equipment"]: translateValue(stand.reportData.mobilya_ve_techizat),
                [selectedLang === "tr" ? "Aydınlatma" : "Lighting"]: translateValue(stand.reportData.aydinlatma),
                [selectedLang === "tr" ? "Donatılmış Yapı" : "Equipped Structure"]: selectedLang === "tr" ? "Yok" : "Not Available",
                [selectedLang === "tr" ? "Alınlık ve Baş Üstü" : "Fascia and Overhead Signage"]: translateValue(stand.reportData.alinlik_ve_bas_ustu),
                [selectedLang === "tr" ? "Tavan" : "Ceiling"]: translateValue(stand.reportData.tavan),
                [selectedLang === "tr" ? "Teşhir Bileşenleri" : "Display Facilities"]: translateValue(stand.reportData.teshir_bilesenleri),
                [selectedLang === "tr" ? "Zemin" : "Floor"]: translateValue(stand.reportData.zemin),
                [selectedLang === "tr" ? "Grafikler ve Dekoratifler" : "Graphics and Decorations"]: translateValue(stand.reportData.grafikler_dekoratifler),
            };
        });

        const worksheet = XLSX.utils.json_to_sheet(tableData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Reports");

        XLSX.writeFile(workbook, `Stands_Report_${selectedLang === "tr" ? "TR" : "EN"}.xlsx`);
    };


    const fetchStandsPhotoCounts = async () => {
        try {
            const updatedStands = await Promise.all(
                stands.map(async (stand) => {
                    const photos = await fetchCategoryPhotos(stand.stand_id);

                    // Fotoğrafları kategorilere ayır ve say
                    const categoryPhotoCounts = categories.reduce((acc, category) => {
                        acc[category.key] = {
                            beforeFair: photos.filter(
                                (photo) =>
                                    photo.stand_report_file_category === String(category.id) &&
                                    photo.fuar_zamani === "fuar_oncesi"
                            ).length,
                            afterFair: photos.filter(
                                (photo) =>
                                    photo.stand_report_file_category === String(category.id) &&
                                    photo.fuar_zamani === "fuar_sonrasi"
                            ).length,
                        };
                        return acc;
                    }, {});

                    // Backend'den rapor verilerini çek
                    const reportData = await fetchReport(stand.stand_id);

                    return {
                        ...stand,
                        photoCounts: categoryPhotoCounts || {}, // Varsayılan boş nesne
                        reportData: reportData || {}, // Varsayılan boş rapor verisi
                    };
                })
            );

            setStandsWithPhotoCounts(updatedStands); // Güncel stant verilerini state'e ata
            console.log("Stantlar ve rapor verileri yüklendi:", updatedStands);
        } catch (error) {
            console.error("Stant görsel ve rapor bilgileri alınırken hata oluştu:", error);
        }
    };



    const toggleModal = () => setIsModalOpen(!isModalOpen);

    const handleIconClick = async (stand, categoryKey) => {
        setSelectedStand(stand);
        setSelectedCategory(categoryKey);

        try {
            // Görselleri yükle
            const categoryPhotos = await fetchCategoryPhotos(stand.stand_id);

            if (categoryKey === "ana_fotograf") {
                // Ana fotoğraf kategorisine ait görseller
                const filteredCategoryPhotos = categoryPhotos.filter(
                    (photo) => photo.stand_report_file_category === String(categories.find((cat) => cat.key === "ana_fotograf")?.id)
                );
                setSelectedCategoryPhotos(filteredCategoryPhotos);
            } else {
                // Diğer kategorilere ait görseller
                const filteredCategoryPhotos = categoryPhotos.filter(
                    (photo) => photo.stand_report_file_category === String(categories.find((cat) => cat.key === categoryKey)?.id)
                );
                setSelectedCategoryPhotos(filteredCategoryPhotos);
            }

            // Eşlenmemiş görselleri yükle
            const unmatched = await fetchCategoryPhotos(stand.stand_id);
            const unmatchedPhotos = unmatched.filter((photo) => !photo.stand_report_file_category);
            setUnmatchedPhotos(unmatchedPhotos);

            toggleModal(); // Modalı aç
        } catch (error) {
            console.error("Görseller yüklenirken hata oluştu:", error);
        }
    };



    const handleSaveReport = async (value) => {
        if (!selectedStand || !selectedCategory) return;

        const updatedReport = {
            ...selectedStand.reportData,
            [selectedCategory]: value,
        };

        try {
            await saveReport({
                stand_id: selectedStand.stand_id,
                fuar_id: activeFairId,
                ...updatedReport,
            });

            setStandsWithPhotoCounts((prev) =>
                prev.map((stand) =>
                    stand.stand_id === selectedStand.stand_id
                        ? { ...stand, reportData: updatedReport }
                        : stand
                )
            );

            toggleModal();
        } catch (error) {
            console.error("Rapor kaydedilirken hata oluştu:", error);
        }
    };

    const handleRemovePhotoFromStand = async (photo) => {
        try {
            await handleRemovePhotoo(photo.stand_report_file_id); // Backend'den sil

            // UI'dan kaldır
            setUnmatchedPhotos((prev) =>
                prev.filter((p) => p.stand_report_file_id !== photo.stand_report_file_id)
            );

        } catch (error) {
            console.error("Görsel silinirken hata oluştu:", error);
            alert(selectedLang === "tr" ? "Bir hata oluştu." : "An error occurred.");
        }
    };

    const handleClosePreviewModal = () => {
        setSelectedPhoto(null); // Fotoğrafı sıfırla
        setIsPreviewModalOpen(false); // Modalı kapat
    };

    const refreshModalPhotos = async () => {
        const categoryPhotos = await fetchCategoryPhotos(selectedStand.stand_id);
        const filteredPhotos = categoryPhotos.filter(
            (photo) => photo.stand_report_file_category === String(categories.find((cat) => cat.key === selectedCategory)?.id)
        );
        setSelectedCategoryPhotos(filteredPhotos);
    };


    const handleFileUpload = async (event) => {
        const files = event.target.files;

        if (files.length === 0) return;

        try {
            const formData = new FormData();
            Array.from(files).forEach((file) => {
                formData.append("photos", file);
            });

            const category = categories.find((cat) => cat.key === selectedCategory);
            const categoryId = category ? category.id : null;

            formData.append("user_id", userId);
            formData.append("stand_id", selectedStand?.stand_id || null);
            formData.append("category_id", categoryId);
            formData.append("fuar_zamani", fuarZamani);

            const response = await uploadPhoto(formData); // Fotoğraflar yüklendi

            // Yeni yüklenen fotoğraflar
            const newPhotos = response.data.photos || [];

            // Modal içeriğini güncelle
            setSelectedCategoryPhotos((prev) => [...prev, ...newPhotos]);

            // Table içeriğini güncellemek için backend verilerini yenile
            await fetchStandsPhotoCounts();
            await refreshModalPhotos();

        } catch (error) {
            console.error("Fotoğraf yüklenirken hata oluştu:", error);
            alert(selectedLang === "tr" ? "Fotoğraf yüklenirken bir hata oluştu." : "An error occurred while uploading photos.");
        }
    };


    const handlePreviewPhoto = (photo) => {
        setSelectedPhoto(photo); // Seçili görseli state'e ekle
        setIsPreviewModalOpen(true); // Büyük modalı aç
    };

    const calculateBadgeStatus = (photoCounts, reportData, mode) => {
        const categories = Object.keys(photoCounts).filter(
            (category) =>
                category !== "ana_fotograf" && category !== "donatilmis_yapi" // Ana Fotoğraf ve Donatılmış Yapıyı işaretleme kontrolünden çıkar
        );

        // Rapor yoksa (tüm işaretlemeler null veya rapor boş)
        if (!reportData || Object.keys(reportData).length === 0) {
            return "gray";
        }

        // Eksik işaretleme kontrolü (donatılmış_yapı hariç diğer kategoriler için)
        const hasIncompleteMarks = categories.some(
            (category) =>
                reportData[category] !== "Evet" &&
                reportData[category] !== "Hayır" &&
                reportData[category] !== "Yok"
        );

        // Ana Fotoğrafta en az bir görsel bulunmalı (fuar zamanı fark etmeksizin)
        const hasMainPhoto =
            photoCounts["ana_fotograf"]?.beforeFair > 0 ||
            photoCounts["ana_fotograf"]?.afterFair > 0;

        if (hasIncompleteMarks || !hasMainPhoto) {
            return "red"; // Eksik işaretleme veya ana fotoğraf yoksa kırmızı
        }

        // Görsellerin kategori bazında sayılması (donatılmış_yapı hariç)
        const categoriesWithPhotos = categories.filter(
            (category) => photoCounts[category][mode] > 0
        ).length;

        const totalCategories = categories.length;

        if (categoriesWithPhotos === 0) {
            return "yellow"; // Hiç görsel yok
        }

        if (categoriesWithPhotos < totalCategories) {
            if (categoriesWithPhotos >= 5) {
                return "green"; // En az 5 kategoride görsel var
            }
            return "yellow"; // Bazı kategorilerde görsel eksik
        }

        return "blue"; // Tüm kategorilerde görsel var
    };

    const renderPagination = () => {
        const paginationItems = [];
        const maxPagesToShow = 5;

        for (let i = 1; i <= totalPages; i++) {
            if (totalPages > maxPagesToShow) {
                if (i === 1 || i === totalPages || (i >= page - 1 && i <= page + 1)) {
                    paginationItems.push(
                        <PaginationItem key={i} active={i === page}>
                            <PaginationLink onClick={() => setPage(i)}>{i}</PaginationLink>
                        </PaginationItem>
                    );
                } else if (i === 2 || i === totalPages - 1) {
                    paginationItems.push(
                        <PaginationItem key={i} disabled>
                            <PaginationLink>...</PaginationLink>
                        </PaginationItem>
                    );
                }
            } else {
                paginationItems.push(
                    <PaginationItem key={i} active={i === page}>
                        <PaginationLink onClick={() => setPage(i)}>{i}</PaginationLink>
                    </PaginationItem>
                );
            }
        }

        return (
            <Pagination className="pagination justify-content-center mt-3">
                {paginationItems}
            </Pagination>
        );
    };

    const renderStatusIcon = (status) => {
        const statusColors = {
            "Bilinmiyor": "#6c757d", // Gri (Unknown)
            "Unknown": "#6c757d",
            "Çöp": "#dc3545", // Kırmızı (Disposable)
            "Disposable": "#dc3545",
            "Bronz": "#cd7f32", // Bronz
            "Bronze": "#cd7f32",
            "Gümüş": "#c0c0c0", // Gümüş
            "Silver": "#c0c0c0",
            "Altın": "#ffd700", // Altın
            "Gold": "#ffd700",
        };

        const color = statusColors[status] || "#6c757d"; // Varsayılan gri
        return (
            <span
                style={{
                    backgroundColor: color,
                    color: "white",
                    borderRadius: "12px",
                    padding: "6px 10px",
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "12px",
                    fontWeight: "bold",
                    minWidth: "50px",
                    textAlign: "center",
                }}
            >
                <i className="bx bxs-medal" style={{ fontSize: "16px" }}></i>
            </span>
        );
    };


    const renderStoreIcon = (status) => {
        const badgeColors = {
            gray: "#6c757d",
            red: "#dc3545",
            yellow: "#ffc107",
            green: "#28a745",
            blue: "#007bff",
        };

        return (
            <span
                style={{
                    backgroundColor: badgeColors[status],
                    color: "white",
                    borderRadius: "12px",
                    padding: "6px 10px",
                    display: "inline-flex",
                    alignItems: "center",
                }}
            >
                <i className="bx bx-store"></i>
            </span>
        );
    };

    const handleLinkPhotoToCategory = async (photo) => {
        try {
            const categoryId = categories.find((cat) => cat.key === selectedCategory)?.id;

            await updatePhotoCategory(photo.stand_report_file_id, categoryId);

            setUnmatchedPhotos((prev) =>
                prev.filter((p) => p.stand_report_file_id !== photo.stand_report_file_id)
            );
            setSelectedCategoryPhotos((prev) => [...prev, { ...photo, stand_report_file_category: String(categoryId) }]);

            setStandsWithPhotoCounts((prevStands) =>
                prevStands.map((stand) => {
                    if (stand.stand_id === selectedStand.stand_id) {
                        const updatedPhotoCounts = {
                            ...stand.photoCounts,
                            [selectedCategory]: {
                                ...stand.photoCounts[selectedCategory],
                                beforeFair: stand.photoCounts[selectedCategory]?.beforeFair + 1,
                            },
                        };
                        return { ...stand, photoCounts: updatedPhotoCounts };
                    }
                    return stand;
                })
            );

        } catch (error) {
            console.error("Fotoğraf eşlenirken hata oluştu:", error);
            alert(selectedLang === "tr" ? "Bir hata oluştu." : "An error occurred.");
        }
    };


    const handleUnlinkPhoto = async (photo) => {
        try {
            // Fotoğrafı kategoriden çıkar
            await updatePhotoCategory(photo.stand_report_file_id, null); // Backend çağrısı

            // UI güncellemesi: Kategoriden çıkar ve eşlenmemiş görsellere ekle
            setSelectedCategoryPhotos((prev) =>
                prev.filter((p) => p.stand_report_file_id !== photo.stand_report_file_id)
            );
            setUnmatchedPhotos((prev) => [...prev, { ...photo, stand_report_file_category: null }]);
            await fetchStandsPhotoCounts();

        } catch (error) {
            console.error("Fotoğraf eşlenmeden çıkarılırken hata oluştu:", error);
            alert(selectedLang === "tr" ? "Bir hata oluştu." : "An error occurred.");
        }
    };

    useEffect(() => {
        if (stands.length > 0) {
            fetchStandsPhotoCounts();
        }
    }, [stands]);


    useEffect(() => {
        if (isStatisticsModalOpen) {
            const stats = calculateFairAndTearDownStatistics();
            setFairAndTearDownStatistics(stats);
        }
    }, [isStatisticsModalOpen, standsWithPhotoCounts]);

    const calculateStatusStatistics = () => {
        let gold = 0, silver = 0, bronze = 0, disposable = 0, unknown = 0;

        standsWithPhotoCounts.forEach((stand) => {
            const status = determineStatus(stand.reportData);
            if (status === "Altın" || status === "Gold") gold++;
            else if (status === "Gümüş" || status === "Silver") silver++;
            else if (status === "Bronz" || status === "Bronze") bronze++;
            else if (status === "Çöp" || status === "Disposable") disposable++;
            else unknown++;
        });

        return { gold, silver, bronze, disposable, unknown };
    };


    useEffect(() => {
        if (isStatisticsModalOpen) {
            const stats = calculateStatusStatistics();
            setStatisticsData(stats);
        }
    }, [isStatisticsModalOpen, standsWithPhotoCounts]);


    const calculateFairAndTearDownStatistics = () => {
        let fairStatus = { gray: 0, red: 0, yellow: 0, green: 0, blue: 0 };
        let tearDownStatus = { gray: 0, red: 0, yellow: 0, green: 0, blue: 0 };

        standsWithPhotoCounts.forEach((stand) => {
            const fairBadge = calculateBadgeStatus(stand.photoCounts, stand.reportData, "beforeFair");
            const tearDownBadge = calculateBadgeStatus(stand.photoCounts, stand.reportData, "afterFair");

            fairStatus[fairBadge]++;
            tearDownStatus[tearDownBadge]++;
        });

        return { fairStatus, tearDownStatus };
    };

    const handleDownloadPhotos = async () => {
        if (standsWithPhotoCounts.length === 0) {
            alert(selectedLang === "tr" ? "Tabloda hiçbir stant yok." : "No stands in the table.");
            return;
        }

        const standsToDownload = standsWithPhotoCounts.map((stand) => ({
            stand_id: stand.stand_id,
            stand_name: stand.stand_name,
        }));

        try {
            await downloadPhotos(standsToDownload);
        } catch (error) {
            console.error("Görseller indirilemedi:", error);
        }
    };


    return (
        <div className="page-content">
            <Container fluid>
                <BreadCrumb title="BetterStands" pageTitle="Raporlar" />
                <Card className="p-3 mb-3 mt-1">
                    <Row className="align-items-center g-3"> {/* g-3 boşlukları düzenler */}
                        {/* Arama Kutusu */}
                        <Col xs="12" md="3" lg="3">
                            <Input
                                type="text"
                                placeholder={selectedLang === 'tr' ? 'Ara...' : 'Search...'}
                                value={search}
                                onChange={(e) => setSearch(e.target.value)} // search state'i güncelleniyor
                            />
                        </Col>

                        {/* Gösterim Seçimi */}
                        <Col xs="6" md="3" lg="1">
                            <Dropdown
                                isOpen={dropdownOpen}
                                toggle={() => setDropdownOpen(!dropdownOpen)}
                            >
                                <DropdownToggle color="primary" caret>
                                    {selectedLang === "tr" ? `Gösterim: ${limit}` : `Records: ${limit}`}
                                </DropdownToggle>
                                <DropdownMenu>
                                    {[10, 25, 50, 100, 1000, 2000].map((num) => (
                                        <DropdownItem
                                            key={num}
                                            onClick={() => setLimit(num)}
                                        >
                                            {`${num} ${selectedLang === "tr" ? "Gösterim" : "Records"}`}
                                        </DropdownItem>
                                    ))}
                                </DropdownMenu>
                            </Dropdown>
                        </Col>

                        {/* Salon Filtreleme */}
                        <Col xs="6" md="3" lg="1">
                            <Dropdown
                                isOpen={salonDropdownOpen}
                                toggle={toggleSalonDropdown}
                            >
                                <DropdownToggle color="primary" caret>
                                    {selectedLang === 'tr' ? `Salon: ${getSelectedSalonName()}` : `Hall: ${getSelectedSalonName()}`}
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={() => setSalonFilter(null)}>
                                        {selectedLang === 'tr' ? "Tümü" : "All"}
                                    </DropdownItem>
                                    {salons && salons.map(salon => (
                                        <DropdownItem
                                            key={salon.salon_id}
                                            onClick={() => setSalonFilter(salon.salon_id)}
                                        >
                                            {salon.salon_name}
                                        </DropdownItem>
                                    ))}
                                </DropdownMenu>
                            </Dropdown>
                        </Col>

                        {/* İstatistikler Butonu */}
                        <Col xs="12" md="3" lg="2">
                            <Button
                                color="primary"
                                onClick={() => setIsStatisticsModalOpen(true)}
                                className="d-flex align-items-center w-100"
                            >
                                <i className="bx bxs-objects-vertical-bottom me-2" style={{ fontSize: "20px" }}></i>
                                {selectedLang === "tr" ? "İstatistikler" : "Statistics"}
                            </Button>
                        </Col>

                        {/* Excele Aktar Butonu */}
                        <Col xs="6" md="6" lg="2">
                            <Button
                                color="primary"
                                onClick={exportTableToExcel}
                                className="d-flex align-items-center w-100"
                            >
                                <i className="bx bxs-file-export me-2" style={{ fontSize: "20px" }}></i>
                                {selectedLang === "tr" ? "Excele Aktar" : "Export to Excel"}
                            </Button>
                        </Col>

                        {/* Görselleri İndir Butonu */}
                        <Col xs="6" md="6" lg="3">
                            <Button
                                color="primary"
                                onClick={handleDownloadPhotos}
                                className="d-flex align-items-center w-100"
                            >
                                <i className="bx bxs-cloud-download me-2" style={{ fontSize: "20px" }}></i>
                                {selectedLang === "tr" ? "Görselleri İndir" : "Download Photos"}
                            </Button>
                        </Col>
                    </Row>
                </Card>

                <Card className="pt-3 pe-3 ps-3"><Table striped bordered hover responsive>
                    <thead>
                        <tr>
                            <th>{selectedLang === "tr" ? "Statü" : "Status"}</th>
                            <th>{selectedLang === "tr" ? "Ana Fotoğraf" : "Main Photo"}</th>
                            <th onClick={() => handleSort("stand_name")} style={{ cursor: "pointer" }}>
                                {selectedLang === "tr" ? "Numara" : "Number"}{" "}
                                {sortConfig.key === "stand_name" && (sortConfig.direction === "asc" ? "▲" : "▼")}
                            </th>
                            <th onClick={() => handleSort("hall")} style={{ cursor: "pointer" }}>
                                {selectedLang === "tr" ? "Salon" : "Hall"}{" "}
                                {sortConfig.key === "hall" && (sortConfig.direction === "asc" ? "▲" : "▼")}
                            </th>
                            <th onClick={() => handleSort("name")} style={{ cursor: "pointer" }}>
                                {selectedLang === "tr" ? "İsim" : "Name"}{" "}
                                {sortConfig.key === "name" && (sortConfig.direction === "asc" ? "▲" : "▼")}
                            </th>
                            <th>{selectedLang === "tr" ? "Fuar" : "Fair"}</th>
                            <th>{selectedLang === "tr" ? "Söküm" : "Tear Down"}</th>
                            <th onClick={() => handleSort("type")} style={{ cursor: "pointer" }}>
                                {selectedLang === "tr" ? "Tür" : "Type"}{" "}
                                {sortConfig.key === "type" && (sortConfig.direction === "asc" ? "▲" : "▼")}
                            </th>
                            {categories
                                .filter((cat) => cat.key !== "ana_fotograf" && cat.key !== "donatilmis_yapi")
                                .map((cat) => (
                                    <th key={cat.id}>
                                        {selectedLang === "tr" ? cat.tr : cat.en}
                                    </th>
                                ))}
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            <tr>
                                <td colSpan="17" className="text-center">
                                    {selectedLang === "tr" ? "Yükleniyor..." : "Loading..."}
                                </td>
                            </tr>
                        ) : standsWithPhotoCounts.length > 0 ? (
                            sortedStands.map((stand, index) => {
                                const status = determineStatus(stand.reportData); // Statü belirleme
                                const beforeFairStatus = calculateBadgeStatus(
                                    stand.photoCounts,
                                    stand.reportData,
                                    "beforeFair"
                                );
                                const afterFairStatus = calculateBadgeStatus(
                                    stand.photoCounts,
                                    stand.reportData,
                                    "afterFair"
                                );

                                return (
                                    <tr key={index}>
                                        {/* Statü */}
                                        <td>{renderStatusIcon(status)}</td>

                                        {/* Ana Fotoğraf */}
                                        <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                                            <div style={{ position: "relative", display: "inline-block" }}>
                                                <img
                                                    src={GörselImg}
                                                    alt="Ana Fotoğraf"
                                                    onClick={() => handleIconClick(stand, "ana_fotograf")}
                                                    style={{
                                                        width: "40px",
                                                        height: "40px",
                                                        cursor: "pointer",
                                                    }}
                                                />
                                                {/* Fuar Öncesi Sayaç */}
                                                {stand.photoCounts["ana_fotograf"]?.beforeFair > 0 && (
                                                    <span
                                                        style={{
                                                            position: "absolute",
                                                            top: "0px",
                                                            left: "0px",
                                                            backgroundColor: "blue",
                                                            color: "white",
                                                            borderRadius: "50%",
                                                            padding: "2px 6px",
                                                            fontSize: "10px",
                                                            fontWeight: "bold",
                                                            transform: "translate(-50%, -50%)",
                                                        }}
                                                    >
                                                        {stand.photoCounts["ana_fotograf"]?.beforeFair || 0}
                                                    </span>
                                                )}
                                                {/* Fuar Sonrası Sayaç */}
                                                {stand.photoCounts["ana_fotograf"]?.afterFair > 0 && (
                                                    <span
                                                        style={{
                                                            position: "absolute",
                                                            top: "0px",
                                                            right: "0px",
                                                            backgroundColor: "red",
                                                            color: "white",
                                                            borderRadius: "50%",
                                                            padding: "2px 6px",
                                                            fontSize: "10px",
                                                            fontWeight: "bold",
                                                            transform: "translate(50%, -50%)",
                                                        }}
                                                    >
                                                        {stand.photoCounts["ana_fotograf"]?.afterFair || 0}
                                                    </span>
                                                )}
                                            </div>
                                        </td>

                                        {/* Stand Numarası */}
                                        <td>{stand.stand_name}</td>

                                        {/* Salon */}
                                        <td>{stand.hall}</td>

                                        {/* İsim */}
                                        <td>{stand.name}</td>

                                        {/* Fuar Statüsü */}
                                        <td>{renderStoreIcon(beforeFairStatus)}</td>

                                        {/* Söküm Statüsü */}
                                        <td>{renderStoreIcon(afterFairStatus)}</td>

                                        {/* Tür */}
                                        <td>
                                            {stand.type === "Ahşap"
                                                ? selectedLang === "tr"
                                                    ? "Ahşap"
                                                    : "Wooden"
                                                : stand.type === "Modüler"
                                                    ? selectedLang === "tr"
                                                        ? "Modüler"
                                                        : "Modular"
                                                    :
                                                     stand.type === "Paket"
                                                    ? selectedLang === "tr"
                                                        ? "Paket"
                                                        : "Package"
                                                    : selectedLang === "tr"
                                                        ? "Diğer"
                                                        : "Other"}
                                        </td>

                                        {/* Kategoriler */}
                                        {categories
                                            .filter((cat) => cat.key !== "ana_fotograf" && cat.key !== "donatilmis_yapi")
                                            .map((category) => (
                                                <td
                                                    key={category.key}
                                                    style={{
                                                        textAlign: "center",
                                                        verticalAlign: "middle",
                                                        position: "relative",
                                                        backgroundColor:
                                                            stand.photoCounts[category.key]?.beforeFair > 0 ||
                                                                stand.photoCounts[category.key]?.afterFair > 0
                                                                ? "transparent"
                                                                : "rgba(255,0,0,0.1)",
                                                    }}
                                                >
                                                    <div style={{ position: "relative", display: "inline-block" }}>
                                                        <img
                                                            src={
                                                                stand.reportData[category.key] === "Evet"
                                                                    ? EvetImg
                                                                    : stand.reportData[category.key] === "Hayır"
                                                                        ? HayirImg
                                                                        : stand.reportData[category.key] === "Yok"
                                                                            ? YokImg
                                                                            : BelirtilmediImg
                                                            }
                                                            alt={category.key}
                                                            style={{
                                                                width: "40px",
                                                                height: "40px",
                                                                cursor: "pointer",
                                                                opacity:
                                                                    stand.reportData[category.key] === "Evet" ||
                                                                        stand.reportData[category.key] === "Hayır" ||
                                                                        stand.reportData[category.key] === "Yok"
                                                                        ? 1
                                                                        : 0.5,
                                                            }}
                                                            onClick={() => handleIconClick(stand, category.key)}
                                                        />
                                                        {/* Fuar Öncesi Sayaç */}
                                                        {stand.photoCounts[category.key]?.beforeFair > 0 && (
                                                            <span
                                                                style={{
                                                                    position: "absolute",
                                                                    top: "0px",
                                                                    left: "0px",
                                                                    backgroundColor: "blue",
                                                                    color: "white",
                                                                    borderRadius: "50%",
                                                                    padding: "2px 6px",
                                                                    fontSize: "10px",
                                                                    fontWeight: "bold",
                                                                    transform: "translate(-50%, -50%)",
                                                                }}
                                                            >
                                                                {stand.photoCounts[category.key]?.beforeFair || 0}
                                                            </span>
                                                        )}
                                                        {/* Fuar Sonrası Sayaç */}
                                                        {stand.photoCounts[category.key]?.afterFair > 0 && (
                                                            <span
                                                                style={{
                                                                    position: "absolute",
                                                                    top: "0px",
                                                                    right: "0px",
                                                                    backgroundColor: "red",
                                                                    color: "white",
                                                                    borderRadius: "50%",
                                                                    padding: "2px 6px",
                                                                    fontSize: "10px",
                                                                    fontWeight: "bold",
                                                                    transform: "translate(50%, -50%)",
                                                                }}
                                                            >
                                                                {stand.photoCounts[category.key]?.afterFair || 0}
                                                            </span>
                                                        )}
                                                    </div>
                                                </td>
                                            ))}
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <td colSpan="17" className="text-center">
                                    {selectedLang === "tr" ? "Kayıt bulunamadı" : "No records found"}
                                </td>
                            </tr>
                        )}
                    </tbody>


                </Table></Card>


                {renderPagination()}
            </Container>

            {/* Modal */}
            <Modal isOpen={isModalOpen} toggle={toggleModal} size="lg" centered>
                <ModalHeader toggle={toggleModal}>
                    {selectedCategory
                        ? `${categories.find((cat) => cat.key === selectedCategory)?.[selectedLang === "tr" ? "tr" : "en"]} - `
                        : ""}
                    {selectedLang === "tr" ? "Kategori İşaretleme" : "Mark Category"}
                </ModalHeader>
                <ModalBody>
                    {selectedCategoryPhotos.length > 0 && (
                        <>
                            <h5>{selectedLang === "tr" ? "Kategoriye Ait Görseller" : "Category Photos"}</h5>
                            <div style={{ display: "flex", flexWrap: "wrap", gap: "10px", marginBottom: "20px" }}>
                                {selectedCategoryPhotos.map((photo, index) => (
                                    <div key={index} style={{ position: "relative" }}>
                                        <img
                                            src={`${baseApiUrl}${photo.stand_report_file_link}`}
                                            alt={`Photo ${index}`}
                                            style={{
                                                width: "100px",
                                                height: "100px",
                                                borderRadius: "5px",
                                                objectFit: "cover",
                                                boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                                                cursor: "pointer",
                                            }}
                                            onClick={() => handlePreviewPhoto(photo)} // Büyük modalda önizleme
                                        />
                                        <span
                                            onClick={() => handleUnlinkPhoto(photo)} // Görseli eşlemeden çıkar
                                            style={{
                                                position: "absolute",
                                                top: "5px",
                                                right: "5px",
                                                backgroundColor: "red",
                                                color: "white",
                                                borderRadius: "50%",
                                                width: "20px",
                                                height: "20px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                cursor: "pointer",
                                            }}
                                        >
                                            ×
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </>
                    )}

                    {unmatchedPhotos.length > 0 && (
                        <>
                            <h5>{selectedLang === "tr" ? "Eşlenmemiş Görseller" : "Unmatched Photos"}</h5>
                            <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                                {unmatchedPhotos.map((photo, index) => (
                                    <div key={index} style={{ position: "relative" }}>
                                        <img
                                            src={`${baseApiUrl}${photo.stand_report_file_link}`}
                                            alt={`Unmatched Photo ${index}`}
                                            style={{
                                                width: "100px",
                                                height: "100px",
                                                borderRadius: "5px",
                                                objectFit: "cover",
                                                boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                                                cursor: "pointer",
                                            }}
                                            onClick={() => handleLinkPhotoToCategory(photo)} // Görseli kategoriye eşle
                                        />
                                        <span
                                            onClick={() => handleRemovePhotoFromStand(photo)} // Görseli tamamen sil
                                            style={{
                                                position: "absolute",
                                                top: "5px",
                                                right: "5px",
                                                backgroundColor: "red",
                                                color: "white",
                                                borderRadius: "50%",
                                                width: "20px",
                                                height: "20px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                cursor: "pointer",
                                            }}
                                        >
                                            ×
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </>
                    )}

                    {/* İşaretleme Butonları */}
                    {selectedCategory !== "ana_fotograf" && (
                        <div style={{ marginTop: "20px", textAlign: "center" }}>
                            <img
                                src={EvetImg}
                                alt="Evet"
                                style={{
                                    width: "50px",
                                    height: "50px",
                                    cursor: "pointer",
                                    opacity: selectedStand?.reportData?.[selectedCategory] === "Evet" ? 1 : 0.5,
                                }}
                                onClick={() => handleSaveReport("Evet")}
                            />
                            <img
                                src={HayirImg}
                                alt="Hayır"
                                style={{
                                    width: "50px",
                                    height: "50px",
                                    cursor: "pointer",
                                    marginLeft: "10px",
                                    opacity: selectedStand?.reportData?.[selectedCategory] === "Hayır" ? 1 : 0.5,
                                }}
                                onClick={() => handleSaveReport("Hayır")}
                            />
                            <img
                                src={YokImg}
                                alt="Yok"
                                style={{
                                    width: "50px",
                                    height: "50px",
                                    cursor: "pointer",
                                    marginLeft: "10px",
                                    opacity: selectedStand?.reportData?.[selectedCategory] === "Yok" ? 1 : 0.5,
                                }}
                                onClick={() => handleSaveReport("Yok")}
                            />
                        </div>
                    )}

                    {/* Dosya Yükleme */}
                    <div style={{ marginTop: "20px", textAlign: "center" }}>
                        <input
                            class="form-control"
                            type="file"
                            name="photos" // Burada "photos" alan adıyla göndermeliyiz
                            multiple
                            accept="image/*"
                            onChange={handleFileUpload}
                            style={{
                                display: "block",
                                margin: "0 auto",
                            }}
                        />
                    </div>
                </ModalBody>
            </Modal>


            <Modal isOpen={isPreviewModalOpen} toggle={handleClosePreviewModal} size="lg" centered>
                <ModalHeader toggle={handleClosePreviewModal}>
                    {selectedLang === "tr" ? "Görsel Önizleme" : "Photo Preview"}
                </ModalHeader>
                <ModalBody className="text-center">
                    {selectedPhoto && (
                        <div style={{ position: "relative" }}>
                            {/* Görsel */}
                            <img
                                src={`${baseApiUrl}${selectedPhoto.stand_report_file_link}`}
                                alt="Preview"
                                style={{
                                    maxWidth: "100%",
                                    maxHeight: "80vh",
                                    borderRadius: "8px",
                                    transform: `rotate(${rotation}deg)`, // Görseli döndür
                                    transition: "transform 0.3s ease", // Döndürme animasyonu
                                }}
                            />

                            {/* Döndürme Butonları */}
                            <div style={{ marginTop: "20px", display: "flex", justifyContent: "center", gap: "20px" }}>
                                {/* Sola Döndür */}
                                <Button
                                    color="secondary"
                                    onClick={() => setRotation((prev) => prev - 90)} // 90 derece sola
                                    style={{
                                        borderRadius: "50%",
                                        width: "50px",
                                        height: "50px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <i className="bx bx-rotate-left" style={{ fontSize: "20px" }}></i>
                                </Button>

                                {/* Sağa Döndür */}
                                <Button
                                    color="secondary"
                                    onClick={() => setRotation((prev) => prev + 90)} // 90 derece sağa
                                    style={{
                                        borderRadius: "50%",
                                        width: "50px",
                                        height: "50px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <i className="bx bx-rotate-right" style={{ fontSize: "20px" }}></i>
                                </Button>
                            </div>
                        </div>
                    )}
                </ModalBody>
            </Modal>


            <Modal isOpen={isStatisticsModalOpen} toggle={() => setIsStatisticsModalOpen(false)} size="lg" centered>
                <ModalHeader toggle={() => setIsStatisticsModalOpen(false)}>
                    {selectedLang === "tr" ? "İstatistikler" : "Statistics"}
                </ModalHeader>
                <ModalBody>
                    {/* Statü Chart */}
                    <h5 style={{ display: "flex", justifyContent: "center" }}>{selectedLang === "tr" ? "Statü" : "Status"}</h5>
                    <div style={{ display: "flex", justifyContent: "center", marginBottom: "20px" }}>
                        <ReactApexChart
                            options={{
                                labels: [
                                    selectedLang === "tr" ? "Altın" : "Gold",
                                    selectedLang === "tr" ? "Gümüş" : "Silver",
                                    selectedLang === "tr" ? "Bronz" : "Bronze",
                                    selectedLang === "tr" ? "Çöp" : "Disposable",
                                    selectedLang === "tr" ? "Bilinmiyor" : "Unknown",
                                ],
                                colors: ["#ffd700", "#c0c0c0", "#cd7f32", "#dc3545", "#6c757d"],
                                legend: { position: "bottom" },
                            }}
                            series={[
                                statisticsData.gold,
                                statisticsData.silver,
                                statisticsData.bronze,
                                statisticsData.disposable,
                                statisticsData.unknown,
                            ]}
                            type="pie"
                            height={300}
                        />
                    </div>

                    {/* Fuar Sütunu Chart */}
                    <h5 style={{ display: "flex", justifyContent: "center" }}>{selectedLang === "tr" ? "Fuar Öncesi İstatistik" : "Pre Fair Statistics"}</h5>
                    <div style={{ display: "flex", justifyContent: "center", marginBottom: "20px" }}>

                        <ReactApexChart
                            options={{
                                labels: [
                                    selectedLang === "tr" ? "Rapor Yok" : "No Report",
                                    selectedLang === "tr" ? "Eksik İşaretleme" : "Incomplete",
                                    selectedLang === "tr" ? "Tamamlanmış" : "Complete",
                                    selectedLang === "tr" ? "5 Kategori Tamam" : "5 Categories Done",
                                    selectedLang === "tr" ? "Tüm Kategoriler Tamam" : "All Categories Done",
                                ],
                                colors: ["#6c757d", "#dc3545", "#ffc107", "#28a745", "#007bff"],
                                legend: { position: "bottom" },
                            }}
                            series={[
                                fairAndTearDownStatistics.fairStatus.gray,
                                fairAndTearDownStatistics.fairStatus.red,
                                fairAndTearDownStatistics.fairStatus.yellow,
                                fairAndTearDownStatistics.fairStatus.green,
                                fairAndTearDownStatistics.fairStatus.blue,
                            ]}
                            type="pie"
                            height={300}
                        />
                    </div>

                    {/* Söküm Sütunu Chart */}
                    <h5 style={{ display: "flex", justifyContent: "center" }}>{selectedLang === "tr" ? "Fuar Sonrası İstatistik" : "Post Fair Statistics"}</h5>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <ReactApexChart
                            options={{
                                labels: [
                                    selectedLang === "tr" ? "Rapor Yok" : "No Report",
                                    selectedLang === "tr" ? "Eksik İşaretleme" : "Incomplete",
                                    selectedLang === "tr" ? "Tamamlanmış" : "Complete",
                                    selectedLang === "tr" ? "5 Kategori Tamam" : "5 Categories Done",
                                    selectedLang === "tr" ? "Tüm Kategoriler Tamam" : "All Categories Done",
                                ],
                                colors: ["#6c757d", "#dc3545", "#ffc107", "#28a745", "#007bff"],
                                legend: { position: "bottom" },
                            }}
                            series={[
                                fairAndTearDownStatistics.tearDownStatus.gray,
                                fairAndTearDownStatistics.tearDownStatus.red,
                                fairAndTearDownStatistics.tearDownStatus.yellow,
                                fairAndTearDownStatistics.tearDownStatus.green,
                                fairAndTearDownStatistics.tearDownStatus.blue,
                            ]}
                            type="pie"
                            height={300}
                        />
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );
};

export default Reports;
