import React from 'react';
import './assets/scss/themes.scss';
import Routes from './Routes';
import AppProvider from './Components/Contexts/AppProvider';

function App() {
  return (
    <AppProvider>
        <React.Fragment>
          <Routes />
        </React.Fragment>
    </AppProvider>
  );
}

export default App;
