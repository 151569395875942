import React, { createContext, useState, useEffect } from 'react';

// LanguageContext'in oluşturulması
export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [selectedLang, setSelectedLang] = useState(localStorage.getItem('appLanguage') || 'tr');

  useEffect(() => {
    localStorage.setItem('appLanguage', selectedLang);
  }, [selectedLang]);

  return (
    <LanguageContext.Provider value={{ selectedLang, setSelectedLang }}>
      {children}
    </LanguageContext.Provider>
  );
};
